import React, { useEffect, useState, useRef, useContext } from 'react';
import { Form, Select, DatePicker, Radio, Button } from 'antd';
import moment from 'moment';
import http from '../../../Http';
import utils from '../../../utils';
import ReactECharts from 'echarts-for-react';

const energyOption = {
    tooltip: {
        trigger: 'axis'
    },
    xAxis: {
        type: 'category',
        data: [],
    },
    yAxis: {
        type: 'value',
    },
    color: 'rgb(98, 158, 14)',
    series: [
        {
            type: 'bar',
        }
    ]
}

function EnergyStatistics(props) {
    const {inverterSelectOptions, SN, handleSelectSN} = props
    const [invData, setInvData] = useState(null)
    const setTip = useContext(utils.tipContext)

    const [energyDate, setEnergyDate] = useState(moment().format('YYYY-MM-DD')) //时间
    const [size, setSize] = useState('month')  //月, 年, 总计
    const [active, setActive] = useState(0)

    const energyChart = useRef(null)

    //获取月, 年, 总体数据
    useEffect(() => {
        if(!SN) return;
        let active = true;
        let url = 'integrated-inverter/report/' + 
            (size=='month' ? 'monthly' : size=='year' ? 'yearly' : 'all-years') + 
            '/report';
        //取数据
        (async () => {
            let resp = await http.post(url, {
                SerialNumber: SN,
                Date: moment(energyDate).format('YYYY-MM')+'-01'
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });
            if (!active) {
                return;
            }
            if (resp === null || resp.code !== 200) {  //请求数据失败
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load inverter record error"});
                return;
            } else {  //请求数据成功
                setInvData(resp.data)
            }
        })();

        return () => {
            active = false;
        };

    }, [SN, size, energyDate]);

    //draw Chart
    useEffect(() => {
        if(!invData) {
            energyOption.xAxis.data = []
            energyOption.series[0].data = []
        } else {
            if(size=='month') {  //绘制月数据
                //坐标, 计算每个月有多少天
                const y = moment(energyDate).year()
                const m = moment(energyDate).month()
                let days = Array(((new Date(y, m+1)).getTime() - (new Date(y, m)).getTime()) / 3600/1000/24)
                days.fill(0).forEach((v, i) => {
                    days[i] = i+1;
                })
                energyOption.xAxis.data = days;
                //坐标
                const yData = Array(days.length).fill(0)
                invData.forEach(v => {
                    yData[moment(v['YMD']).date()-1] = 
                        active == 0 ? v['EDay'] :
                        active == 1 ? 0 :
                        active == 2 ? 0 :
                        active == 3 ? 0 :
                        active == 4 ? v['EDayBatChrg'] :
                        active == 5 ? v['EDayBatDischrg'] :
                        active == 6 ? v['EDayEPS'] :
                        active == 7 ? v['EDayToGrid'] :
                        active == 8 ? v['EDayToLoad'] : null
                })
                //Echart坐标数据
                energyOption.series[0] = 
                    active == 0 ? {...energyOption.series[0], name: 'E_pv1', data: yData} : 
                    active == 1 ? {...energyOption.series[0], name: 'E_pv2', data: yData} :
                    active == 2 ? {...energyOption.series[0], name: 'E_inv', data: yData} :
                    active == 3 ? {...energyOption.series[0], name: 'E_rec', data: yData} :
                    active == 4 ? {...energyOption.series[0], name: 'E_charge', data: yData} :
                    active == 5 ? {...energyOption.series[0], name: 'E_discharge', data: yData} :
                    active == 6 ? {...energyOption.series[0], name: 'E_eps', data: yData} :
                    active == 7 ? {...energyOption.series[0], name: 'EnergyToGrid', data: yData} :
                    active == 8 ? {...energyOption.series[0], name: 'EnergyToUser', data: yData} : null
            } else if(size == 'year') {  //绘制年数据
                //坐标
                energyOption.xAxis.data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                const yData = Array(12).fill(0)
                invData.forEach(v => {
                    yData[moment(v['YM']).month()] = 
                        active == 0 ? v['EMon'] :
                        active == 1 ? 0 :
                        active == 2 ? 0 :
                        active == 3 ? 0 :
                        active == 4 ? v['EMonBatChrg'] :
                        active == 5 ? v['EMonBatDischrg'] :
                        active == 6 ? v['EMonEPS'] :
                        active == 7 ? v['EMonToGrid'] :
                        active == 8 ? v['EMonToLoad'] : null
                })
                //Echart坐标数据
                energyOption.series[0] = 
                    active == 0 ? {...energyOption.series[0], name: 'E_pv1', data: yData} : 
                    active == 1 ? {...energyOption.series[0], name: 'E_pv2', data: yData} :
                    active == 2 ? {...energyOption.series[0], name: 'E_inv', data: yData} :
                    active == 3 ? {...energyOption.series[0], name: 'E_rec', data: yData} :
                    active == 4 ? {...energyOption.series[0], name: 'E_charge', data: yData} :
                    active == 5 ? {...energyOption.series[0], name: 'E_discharge', data: yData} :
                    active == 6 ? {...energyOption.series[0], name: 'E_eps', data: yData} :
                    active == 7 ? {...energyOption.series[0], name: 'EnergyToGrid', data: yData} :
                    active == 8 ? {...energyOption.series[0], name: 'EnergyToUser', data: yData} : null
            } else { //绘制总体数据
                //坐标
                const curYear = new Date().getFullYear()
                let days = Array(10).fill(0)
                days.fill(0).forEach((v, i) => {
                    days[i] = curYear-9+i
                })
                energyOption.xAxis.data = days;
                const yData = Array(10)
                invData.forEach(v => {
                    yData[moment(v['Y']).year()-curYear+9] = 
                        active == 0 ? v['EYear'] :
                        active == 1 ? 0 :
                        active == 2 ? 0 :
                        active == 3 ? 0 :
                        active == 4 ? v['EYearBatChrg'] :
                        active == 5 ? v['EYearBatDischrg'] :
                        active == 6 ? v['EYearEPS'] :
                        active == 7 ? v['EYearToGRID'] :
                        active == 8 ? v['EYearToLoad'] : null
                })
                //Echart坐标数据
                energyOption.series[0] = 
                    active == 0 ? {...energyOption.series[0], name: 'E_pv1', data: yData} : 
                    active == 1 ? {...energyOption.series[0], name: 'E_pv2', data: yData} :
                    active == 2 ? {...energyOption.series[0], name: 'E_inv', data: yData} :
                    active == 3 ? {...energyOption.series[0], name: 'E_rec', data: yData} :
                    active == 4 ? {...energyOption.series[0], name: 'E_charge', data: yData} :
                    active == 5 ? {...energyOption.series[0], name: 'E_discharge', data: yData} :
                    active == 6 ? {...energyOption.series[0], name: 'E_eps', data: yData} :
                    active == 7 ? {...energyOption.series[0], name: 'EnergyToGrid', data: yData} :
                    active == 8 ? {...energyOption.series[0], name: 'EnergyToUser', data: yData} : null
            }
        }

        //渲染数据到页面
        const instance = energyChart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(energyOption, true);
        } 
    }, [invData, active])

    return (
        <div className="energy-statistics">
            <div className="energy-statistics-header">
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => handleSelectSN(value)}
                    value={SN}
                    style={{ width: '200px', marginRight: '20px' }}
                >{inverterSelectOptions}</Select>
                <Radio.Group value={size} optionType='button' buttonStyle='solid' onChange={e => setSize(e.target.value)} style={{marginRight: '10px'}}>
                    <Radio.Button value="month">Monthly</Radio.Button>
                    <Radio.Button value="year">Yearly</Radio.Button>
                    <Radio.Button value="total">Total</Radio.Button>
                </Radio.Group>
                {size!='total' ? 
                    <DatePicker
                        picker={size=="month" ? 'month' : 'year'}
                        onChange={(_, ds) => setEnergyDate(ds)}
                        defaultValue={moment()}
                        value={moment(energyDate)}
                        allowClear={false}
                    /> : null}
            </div>
            <div className="energy-statistics-main">
                <div className="side">
                    <div className="title">Energy</div>
                    <div className="item">
                        <ul>
                        <li className={active==0 ? 'active' : null} onClick={() => setActive(0)}>E_pv1(kWh)</li>
                        <li className={active==1 ? 'active' : null} onClick={() => setActive(1)}>E_pv2(kWh)</li>
                        <li className={active==2 ? 'active' : null} onClick={() => setActive(2)}>E_inv(kWh)</li>
                        <li className={active==3 ? 'active' : null} onClick={() => setActive(3)}>E_rec(kWh)</li>
                        <li className={active==4 ? 'active' : null} onClick={() => setActive(4)}>E_charge(kWh)</li>
                        <li className={active==5 ? 'active' : null} onClick={() => setActive(5)}>E_discharge(kWh)</li>
                        <li className={active==6 ? 'active' : null} onClick={() => setActive(6)}>E_eps(kWh)</li>
                        <li className={active==7 ? 'active' : null} onClick={() => setActive(7)}>EnergyToGrid(kWh)</li>
                        <li className={active==8 ? 'active' : null} onClick={() => setActive(8)}>EnergyToUser(kWh)</li>
                        </ul>
                    </div>
                </div>
                <div className="box">
                    <div className="title">
                        {active==0 ? 'E_pv1(kWh)' :
                         active==1 ? 'E_pv2(kWh)' :
                         active==2 ? 'E_inv(kWh)' :
                         active==3 ? 'E_rec(kWh)' :
                         active==4 ? 'E_charge(kWh)' :
                         active==5 ? 'E_discharge(kWh)' :
                         active==6 ? 'E_eps(kWh)' :
                         active==7 ? 'EnergyToGrid(kWh)' :
                         active==8 ? 'EnergyToUser(kWh)' : null
                        } 
                        - 
                        {size=='month' ? '('+(SN||'')+', '+moment(energyDate).format('YYYY-MM')+')' : 
                         size=='year' ? '('+(SN||'')+', '+moment(energyDate).format('YYYY')+')' : 
                         '('+(SN||'')+')'}
                    </div>
                    <div className="chart">
                        <ReactECharts ref={energyChart} option={energyOption} style={{height: 500}} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnergyStatistics;