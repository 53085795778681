import http from '../Http';
import utils from '../utils';
import { useEffect, useState, useRef, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, Checkbox } from 'antd';


const useStyles = makeStyles(() => ({
    Checkbox: {
       "& .ant-checkbox-inner": {
           backgroundColor: "#666",
           border: "#666"
       }
    }
}));

function PermissionManagement (props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);

    const refTableContainer = useRef();

    const classes = useStyles();


    useEffect(() => {
        let active = true;
        console.log("PermissionManagement Effect");

        (async () => {
            setLoading(true);
            
            
            let resp = await http.post("role/management/permission/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[PermissionManagement]", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setRowTotal(0)
                setRows([]);
        
                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("PermissionManagement Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }
        
        let obj = document.createElement('object');
        el.objResizer = obj; 
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (! el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const calcTableBodyHeight = () => {
        if (! refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return  h>=54 ? h - 54 : h /2;
    }


    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            padding: 8,
        }}>
            <div style={{
                flex:"1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{position: "absolute", height: '100%', width: "100%", overflow: "hidden"}}>
                    <Table 
                        columns={[{
                            title: "",
                            width: 70,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo*pageSize+idx+1);
                            }
                        },{
                            title: "ID",
                            dataIndex: "ID",
                            width: 200,
                            fixed: 'left',
                        },{
                            title: "Description",
                            dataIndex: "Description",
                            width: 200,
                        },{
                            title: "Permissions",
                            dataIndex: "Permissions", 
                            width: 350,
                            render: (v, r, index) => {
                                const vs = [];
                                if (v & 1) {
                                    vs.push("Create");
                                }
                                if (v & 2) {
                                    vs.push("Retrieve");
                                }
                                if (v & 4) {
                                    vs.push("Update");
                                }
                                if (v & 8) {
                                    vs.push("Detele");
                                }
                                return <Checkbox.Group className={classes.Checkbox} options={["Create", "Retrieve", "Update", "Detele"]} value={vs}/>
                            }
                        },{
                            title: "CreateTime",
                            dataIndex: 'CreateTime',
                        }]}
                        pagination={{
                            pageSizeOptions: [25,50,100],
                            current: pageNo+1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => {return `Total ${total}`},
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page-1);                                
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                    />
                </div>
            </div>
        </div>
    );
}

export default PermissionManagement;