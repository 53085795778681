import http from '../Http';
import { useContext, useState, useEffect } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Radio, Switch, TextArea, Select } from 'antd';
import { EnvironmentOutlined  } from '@ant-design/icons';


function Handler (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose, row, AlarmTypes3} = props;

    const [histories, setHistories] = useState([]);
    const [content, setContent] = useState("");

    useEffect( async () => {
        let active = true;

        (async () => {
            try {
                const resp = await http.post("alarm/management/handling-history/get", {
                    AlarmID: row ? (row.ID||"") : "",
                }).then(async (r)=>{
                    return await r.json();
                }).catch((e)=>{
                    console.log("[AlarmManagement]", e);
                    return null;
                });

                if (! active) {
                    return;
                }
                
                if (resp === null || resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                    
                    setHistories([])
            
                    return;
                }
                setHistories(resp.data);

            } catch (e) {
                console.log("[AlarmManagement]", e);
            }

            
        })();

        return () => {
            active = false;
        };
    }, [])


    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = {
            AlarmID: row.ID,
            Content: content,
        }
        const resp = await http.post("alarm/management/handle/do", ps).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            console.log("[AlarmManagement]", e);
            return null;
        }); 

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "add error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'add success'});

            const r = {...row, LatestHandleID: resp.data.ID, LatestHandleTime: resp.data.CreateTime}
            
            onClose(r);
        } catch (e) {
            console.log("[AlarmManagement]", e);

            setTip({open:true, severity:"error", msg: "add error"});
        }
    }

    return (<Modal 
        title="Handle Alarm"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button key="OK" type="primary" onClick={handleCancle}>OK</Button>
        ]}
    >
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column"   
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column"
            }}>
                 <div style={{
                    borderTop: "1px solid #888",
                    backgroundColor: "#91d5ff",
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "5px"
                }}>{AlarmTypes3[row.CategoryID]}[Level{row.Level}]</div>
                <Input.TextArea 
                    rows={8} 
                    maxLength={500} 
                    showCount={true} 
                    value={content} 
                    placeholder="Enter your Handling Records"
                    onChange={(e) => {setContent(e.target.value)}}
                />
            </div>
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
            }}>
                <Button key="Save" type="primary" onClick={handleSubmit}>Save</Button>
            </div>
            {/* history */}
            {histories.map((v) => {
                return (<div style={{
                    key: v.ID,
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <div style={{
                        borderTop: "1px solid #888",
                        backgroundColor: "#aaa",
                        fontWeight: "bold",
                        textAlign: "center",
                        padding: "5px"
                    }}>History</div>
                    <Input.TextArea rows={5} value={v.Content}/>
                    <div>{v.CreateTime}, {v.CreatorID}</div>
                </div>)
            })}
        </div>
    </Modal>);
}

export default Handler;