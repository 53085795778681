import http from '../Http';
import { useContext } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Select } from 'antd';


function ProductTypeAdd (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose} = props;

    const [form] = Form.useForm();

    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        
        if (ps === null) {
            return;
        }

        ps["TotalEnergy"] *= 10;
        ps["UsableEnergy"] *= 10;
        ps["NominalVoltage"] *= 10;
        ps["MinVoltage"] *= 10;
        ps["MaxVoltage"] *= 10;
        ps["NominalChargePower"] *= 100;
        ps["NominalDischargePower"] *= 100;
        ps["DischargePeakPower"] *= 10;
        ps["Weight"] *= 10; 

        const resp = await http.post("product/add", ps).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            console.log("[Product Type Add]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "add error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'add success'});

            ps.ID = resp.data.ID;
            ps.CreateTime = resp.data.CreateTime;
            ps.Dimensions = `${ps.Width}mm*${ps.Height}mm*${ps.Depth}mm`;
            onClose(ps);
        } catch (e) {
            console.log("[Product Type Add]", e);

            setTip({open:true, severity:"error", msg: "add error"});
        }
    }

    return (<Modal 
        title="Add Product Type"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={700}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button 
                key="Clear" 
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 14 }}
            form={form}
        >
            <Form.Item 
                name="Name"
                label="Name"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Category"
                label="Category"
                rules={[{ required: true }]}
            >
                <Select >
                    <Select.Option value={0}>General BMS</Select.Option>
                    <Select.Option value={1}>Energy Port</Select.Option>
                    <Select.Option value={2}>Master-Control Unit</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item 
                name="CellType"
                label="CellType"
                rules={[{ required: true }]}
            >
                <Select >
                    <Select.Option value="LFP">LFP</Select.Option>
                    <Select.Option value="NCA">NCA</Select.Option>
                    <Select.Option value="LCO">LCO</Select.Option>
                    <Select.Option value="LMO">LMO</Select.Option>
                    <Select.Option value="NMC">NMC</Select.Option>
                    <Select.Option value="LTO">LTO</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item 
                name="TotalEnergy"
                label="Total Energy"
                rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
            >
                <Input  suffix="kWh"/>
            </Form.Item>
            <Form.Item 
                name="UsableEnergy"
                label="Usable Energy(DC)"
                rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
            >
                <Input  suffix="kWh"/>
            </Form.Item>
            <Form.Item 
                name="RatedCapacity"
                label="Rated Capacity"
                rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
            >
                <Input  suffix="Ah"/>
            </Form.Item>
            <Form.Item 
                name="Efficiency"
                label="Efficiency"
                rules={[{ required: true, type: 'number', min: 1, max: 100, transform:function (v) {return parseFloat(v);} }]}
            >
                <Input suffix="%"/>
            </Form.Item>
            <Form.Item 
                name="VoltageType"
                label="Voltage Type"
                rules={[{ required: true }]}
            >
                <Select>
                    <Select.Option value="H">High Voltage</Select.Option>
                    <Select.Option value="L">Low Voltage</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item 
                label="Voltage Range"
                required={true}
            >
                <Input.Group compact>
                    <Form.Item 
                        name="MinVoltage"
                        noStyle
                        rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
                    >
                        <Input placeholder="Min" suffix="Vd.c" style={{width:100, borderRight: 0}}/>
                    </Form.Item>
                    <Form.Item 
                        noStyle
                    >
                        <Input placeholder="~" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                    }}/>
                    </Form.Item>
                    
                    <Form.Item 
                        name="MaxVoltage"
                        noStyle
                        rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
                    >
                        <Input placeholder="Max" suffix="Vd.c" style={{width:100, borderLeft: 0}}/>
                    </Form.Item>
                </Input.Group>
            </Form.Item>
            <Form.Item 
                name="NominalVoltage"
                label="Nominal Voltage"
                rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
            >
                <Input suffix="Vd.c"/>
            </Form.Item>
            <Form.Item 
                name="NominalCurrent"
                label="Nominal Current"
                rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
            >
                <Input suffix="A"/>
            </Form.Item>
            <Form.Item 
                name="NominalChargePower"
                label="Nominal Charge Power"
                rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
            >
                <Input suffix="kW"/>
            </Form.Item>
            <Form.Item 
                name="NominalDischargePower"
                label="Nominal Discharge Power"
                rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
            >
                <Input suffix="kW"/>
            </Form.Item>
            <Form.Item 
                label="Charge Temperature"
                required={true}
            >
                <Input.Group compact>
                    <Form.Item 
                        name="MinChargeTemperature"
                        noStyle
                        rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
                    >
                        <Input placeholder="Min" suffix="℃" style={{width:100, borderRight: 0}}/>
                    </Form.Item>
                    <Form.Item 
                        noStyle
                    >
                        <Input placeholder="~" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                    }}/>
                    </Form.Item>
                    
                    <Form.Item 
                        name="MaxChargeTemperature"
                        noStyle
                        rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
                    >
                        <Input placeholder="Max" suffix="℃" style={{width:100, borderLeft: 0}}/>
                    </Form.Item>
                </Input.Group>
            </Form.Item>
            <Form.Item 
                label="Discharge Temperature"
                required={true}
            >
                <Input.Group compact>
                    <Form.Item 
                        name="MinDischargeTemperature"
                        noStyle
                        rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
                    >
                        <Input placeholder="Min" suffix="℃" style={{width:100, borderRight: 0}}/>
                    </Form.Item>
                    <Form.Item 
                        noStyle
                    >
                        <Input placeholder="~" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                    }}/>
                    </Form.Item>
                    
                    <Form.Item 
                        name="MaxDischargeTemperature"
                        noStyle
                        rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
                    >
                        <Input placeholder="Max" suffix="℃" style={{width:100, borderLeft: 0}}/>
                    </Form.Item>
                </Input.Group>
            </Form.Item>
            <Form.Item 
                name="DischargePeakPower"
                label="Discharge Peak Power"
                rules={[{ required: true, type: 'number', transform:function (v) {return parseFloat(v);} }]}
            >
                <Input suffix="kW for 3sec"/>
            </Form.Item>
            <Form.Item 
                name="MaxNumbersOfParallelOrSeries"
                label="Max Numbers Of Parallel Or Series"
                rules={[{ required: true, type: 'integer', min: 1, max: 16, transform:function (v) {return parseInt(v);} }]}
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="OperatingCondition"
                label="Operating Condition"
                rules={[{ required: true }]}
            >
                <Select>
                    <Select.Option value="Indoor">Indoor</Select.Option>
                    <Select.Option value="Outdoor">Outdoor</Select.Option>
                    <Select.Option value="Indoor|Outdoor">Indoor|Outdoor</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item 
                label="Dimensions(W*H*D)"
                required={true}
            >
                <Input.Group compact>
                    <Form.Item 
                        name="Width"
                        noStyle
                        rules={[{ required: true, type: 'integer', transform:function (v) {return parseInt(v);} }]}
                    >
                        <Input placeholder="Width" suffix="mm" style={{width:100, borderRight: 0}}/>
                    </Form.Item>
                    <Form.Item 
                        noStyle
                    >
                        <Input placeholder="*" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                    }}/>
                    </Form.Item>
                    <Form.Item 
                        name="Height"
                        noStyle
                        rules={[{ required: true, type: 'integer', transform:function (v) {return parseInt(v);} }]}
                    >
                        <Input placeholder="Height" suffix="mm" style={{width:100, borderLeft: 0, borderRight: 0}}/>
                    </Form.Item>
                    <Form.Item 
                        noStyle
                    >
                        <Input placeholder="*" disabled style={{
                            width: 30,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                    }}/>
                    </Form.Item>
                    <Form.Item 
                        name="Depth"
                        noStyle
                        rules={[{ required: true, type: 'integer', transform:function (v) {return parseInt(v);} }]}
                    >
                        <Input placeholder="Depth" suffix="mm" style={{width:100, borderLeft: 0}}/>
                    </Form.Item>
                </Input.Group>
            </Form.Item>
            <Form.Item 
                name="Weight"
                label="Weight"
                rules={[{ required: true, type: 'integer', transform:function (v) {return parseInt(v);} }]}
            >
                <Input suffix="kg"/>
            </Form.Item>
            <Form.Item 
                name="IPRating"
                label="IP Rating"
                rules={[{ required: true, type: 'string' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="ProtectiveClass"
                label="Protective Class"
                rules={[{ required: true, type: 'string' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    </Modal>)
}

export default ProductTypeAdd;