import React, { useEffect, useState, useContext, useRef } from 'react';
import { Form, Select, DatePicker, Radio, Button } from 'antd';
import http from '../../Http';
import utils from '../../utils';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import { ReloadOutlined } from '@ant-design/icons';

const option1 = {
    tooltip: {
        trigger: 'item',
        formatter: '{b}<br/>Brands: <b>{d}%</b>',
        textStyle: {
            fontSize: 12
        },
    },
    color: ['#5CC9A0', '#F2A474'],
    series: [
        {
            type: 'pie',
            radius: '50%',
            showEmptyCircle: true,
            label: { show: false },
            data: [],
        }
    ]
}

const option2 = {
    tooltip: {
        trigger: 'item',
        formatter: '{b}<br/>Brands: <b>{d}%</b>',
        textStyle: {
            fontSize: 12
        },
    },
    color: ['#5CC9A0', '#F2A474'],
    series: [
        {
            type: 'pie',
            radius: '50%',
            showEmptyCircle: true,
            label: { show: false },
            data: [],
        }
    ]
}

const powerOption = {
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: ['Solar PV', 'Battery', 'Grid', 'Consumption']
    },
    xAxis: {
        type: 'category',
        data: [],
        name: '(Time)',
        nameLocation: "center",
        nameGap: 25,
        axisLabel: {
            formatter: (value) => {
                return moment(value).format('HH:mm')
            }
        }
    },
    yAxis: {
        type: 'value',
        name: '(W)',
        nameLocation: "middle",
        nameRotate: 90,
        nameGap: 40,
    },
    series: []
}

const energyOption = {
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: []
    },
    xAxis: {
        type: 'category',
        data: [],
        name: '(Time)',
        nameLocation: "center",
        nameGap: 25,
    },
    yAxis: {
        type: 'value',
        name: '(kWh)',
        nameLocation: "middle",
        nameRotate: 90,
        nameGap: 40,
    },
    series: []
}

function RealtimeMonitor(props) {
    const { inverterSelectOptions, SN, handleSelectSN } = props
    const setTip = useContext(utils.tipContext)

    //const [inverters, setInverters] = useState([])
    //const [inverterSelectOptions, setInverterSelectOptions] = useState([])  //逆变器选择框
    const [invData, setInvData] = useState(null)  //当前选择的逆变器的详细数据
    const [solarIndex, setSolarIndex] = useState(0)  //点击solar面板
    const [batteryIndex, setBatteryIndex] = useState(0)  //点击battery面板
    const [gridIndex, setGridIndex] = useState(0)  //点击grid面板

    const chart1 = useRef(null)
    const chart2 = useRef(null)
    const powerChart = useRef(null)
    const energyChart = useRef(null)

    const [powerDate, setPowerDate] = useState(moment().format('YYYY-MM-DD')) //power的时间
    const [size, setSize] = useState('month')  //月, 年, 总计
    const [energyDate, setEnergyDate] = useState(moment().format('YYYY-MM-DD')) //energy的时间

    const [refresh, setRefresh] = useState(false)

    //流图的方向
    const [production, setProduction] = useState(false)
    const [consumedDirectly, setConsumedDirectly] = useState(false)
    const [powerToBattery, setPowerToBattery] = useState(false)
    const [powerToGrid, setPowerToGrid] = useState(false)
    const [powerFromBattery, setPowerFromBattery] = useState(false)
    const [powerFromGrid, setPowerFromGrid] = useState(false)
    const [powerToEPS, setPowerToEPS] = useState(false)

    //获取电站列表
    // useEffect(() => {
    //     let active = true;

    //     (async () => {
    //         let resp = await http.post("integrated-inverter/list", {
    //             PageNo: 0,
    //             PageSize: 10000,
    //         }).then(async (r) => {
    //             return await r.json();
    //         }).catch((e) => {
    //             return null;
    //         });

    //         if (!active) {
    //             return;
    //         }

    //         if (resp === null || resp.code !== 200) {
    //             setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter list error" });
    //             return;
    //         }
    //         //逆变器选择框
    //         const ls = resp.data.map(v => {
    //             return <Select.Option key={v.SerialName} value={v.SerialName}>{v.SerialName}</Select.Option>
    //         })
    //         setInverterSelectOptions(ls)
    //     })();

    //     return () => {
    //         active = false;
    //     };

    // }, []);

    //获取具体逆变器详细数据
    useEffect(() => {
        if (!SN) {
            return;
        }
        let active = true;

        (async () => {
            let resp = await http.post("integrated-inverter/last/pvi/get", { SerialNumber: SN }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter details error" });
                setInvData(null)
                return;
            }

            setInvData(resp.data)

            //流图的方向
            setProduction(resp.data.PDC > 0)
            setConsumedDirectly(resp.data.PDCToLoad > 0)
            setPowerFromBattery(resp.data.PBat < 0)
            setPowerToBattery(resp.data.PBat > 0)
            setPowerFromGrid(resp.data.PAC1GRID + resp.data.PAC2GRID + resp.data.PAC3GRID > 0)
            setPowerToGrid(resp.data.PAC1GRID + resp.data.PAC2GRID + resp.data.PAC3GRID < 0)
            setPowerToEPS(resp.data.PAC1EPS + resp.data.PAC2EPS + resp.data.PAC3EPS > 0)


            //pie图
            option1.series[0].data = [
                { value: resp.data.EDayBatChrg, name: 'Charge Today' },
                { value: resp.data.EDayToGRID, name: 'Export Today' },
            ]
            const instance1 = chart1.current && chart1.current.getEchartsInstance();
            if (instance1) {
                instance1.setOption(option1, true);
            }

            option2.series[0].data = [
                { value: resp.data.ETotalBatChrg, name: 'Charge Total' },
                { value: resp.data.ETotalToGRID, name: 'Export Total' },
            ]
            const instance2 = chart2.current && chart2.current.getEchartsInstance();
            if (instance2) {
                instance2.setOption(option2, true);
            }

        })();

        return () => {
            active = false;
        };
    }, [SN, refresh])

    //获取日数据
    useEffect(() => {
        if (!SN) return;
        let active = true;
        (async () => {
            let resp = await http.post("integrated-inverter/report/daily/report", {
                SerialNumber: SN,
                Date: powerDate,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {  //请求日数据失败
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter record error" });
                return;
            } else {
                const xData = []
                const PDC = []
                const PBat = []
                const PGrid = []
                const PLoad = []
                //遍历结果，给坐标和数据赋值
                resp.data.forEach(v => {
                    xData.push(moment(v['YMDH']).format('YYYY-MM-DD HH:mm'))
                    PDC.push(v['PDC'] / 10)
                    PBat.push(v['PBat'] / 10)
                    PGrid.push((v['PAC1GRID'] + v['PAC2GRID'] + v['PAC3GRID']) / 10)
                    PLoad.push((v['PAC1LOAD'] + v['PAC2LOAD'] + v['PAC3LOAD']) / 10)
                })
                //x轴坐标
                powerOption.xAxis.data = xData;
                //数据
                powerOption.series = [
                    { name: 'Solar PV', type: 'line', smooth: true, symbol: 'none', data: PDC },
                    { name: 'Battery', type: 'line', smooth: true, symbol: 'none', data: PBat },
                    { name: 'Grid', type: 'line', smooth: true, symbol: 'none', data: PGrid },
                    { name: 'Consumption', type: 'line', smooth: true, symbol: 'none', data: PLoad },
                ]
            }

            //渲染数据到页面
            //setOption()的第二个参数设为true, 表示组件合并模式为替换合并
            const instance = powerChart.current.getEchartsInstance();
            if (instance) {
                instance.setOption(powerOption, true);
            }
        })();

        return () => {
            active = false;
        };

    }, [SN, powerDate]);

    //获取月, 年, 总体数据
    useEffect(() => {
        if (!SN) return;
        let active = true;
        if (size == 'month') { //月数据
            //坐标, 计算每个月有多少天
            const y = moment(energyDate).year()
            const m = moment(energyDate).month()
            let days = Array(((new Date(y, m + 1)).getTime() - (new Date(y, m)).getTime()) / 3600 / 1000 / 24)
            days.fill(0).forEach((v, i) => {
                days[i] = i + 1;
            })
            //坐标
            energyOption.xAxis.data = days;
            energyOption.xAxis.name = '(Day)';
            energyOption.yAxis.name = '(kWh)';
            //取月数据
            (async () => {
                let resp = await http.post("integrated-inverter/report/monthly/report", {
                    SerialNumber: SN,
                    Date: moment(energyDate).format('YYYY-MM') + '-01'
                }).then(async (r) => {
                    return await r.json();
                }).catch((e) => {
                    return null;
                });

                if (!active) {
                    return;
                }

                if (resp === null || resp.code !== 200) {  //请求月数据失败
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter record error" });
                    return;
                } else {  //请求月数据成功
                    let EDay = Array(days.length).fill(0)
                    let EDayBatChrg = Array(days.length).fill(0)
                    let EDayToGRID = Array(days.length).fill(0)
                    let EDayToLoad = Array(days.length).fill(0)
                    resp.data.forEach(v => {
                        EDay[moment(v['YMD']).date() - 1] = v['EDay']
                        EDayBatChrg[moment(v['YMD']).date() - 1] = v['EDayBatChrg']
                        EDayToGRID[moment(v['YMD']).date() - 1] = v['EDayToGRID']
                        EDayToLoad[moment(v['YMD']).date() - 1] = v['EDayToLoad']
                    })
                    //Echart坐标legend
                    energyOption.legend.data = ['Solar Production', 'Battery', 'Export to Grid', 'Consumption']
                    //Echart数据
                    energyOption.series = [
                        {
                            name: 'Solar Production',
                            type: 'bar',
                            data: EDay
                        },
                        {
                            name: 'Battery',
                            type: 'bar',
                            data: EDayBatChrg
                        },
                        {
                            name: 'Export to Grid',
                            type: 'bar',
                            data: EDayToGRID
                        },
                        {
                            name: 'Consumption',
                            type: 'bar',
                            data: EDayToLoad
                        },
                    ]

                }

                //渲染数据到页面
                const instance = energyChart.current.getEchartsInstance();
                if (instance) {
                    instance.setOption(energyOption, true);
                }
            })();
        } else if (size == 'year') { //年数据
            //坐标
            energyOption.xAxis.data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            energyOption.xAxis.name = '(Month)';
            energyOption.yAxis.name = '(kWh)';
            //请求数据
            (async () => {
                let resp = await http.post("integrated-inverter/report/yearly/report", {
                    SerialNumber: SN,
                    Date: moment(energyDate).format('YYYY-MM') + '-01'
                }).then(async (r) => {
                    return await r.json();
                }).catch((e) => {
                    return null;
                });

                if (!active) {
                    return;
                }

                if (resp === null || resp.code !== 200) { //请求年数据失败
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter record error" });
                    return;
                } else {  //请求年数据成功
                    let EMon = Array(12).fill(0)
                    let EMonBatChrg = Array(12).fill(0)
                    let EMonToGRID = Array(12).fill(0)
                    let EMonToLoad = Array(12).fill(0)
                    resp.data.forEach(v => {
                        EMon[moment(v['YM']).month()] = v['EMon']
                        EMonBatChrg[moment(v['YM']).month()] = v['EMonBatChrg']
                        EMonToGRID[moment(v['YM']).month()] = v['EMonToGRID']
                        EMonToLoad[moment(v['YM']).month()] = v['EMonToLoad']
                    })
                    //Echart坐标legend
                    energyOption.legend.data = ['Solar Production', 'Battery', 'Export to Grid', 'Consumption']
                    //Echart坐标数据
                    energyOption.series = [
                        {
                            name: 'Solar Production',
                            type: 'bar',
                            data: EMon
                        },
                        {
                            name: 'Battery',
                            type: 'bar',
                            data: EMonBatChrg
                        },
                        {
                            name: 'Export to Grid',
                            type: 'bar',
                            data: EMonToGRID
                        },
                        {
                            name: 'Consumption',
                            type: 'bar',
                            data: EMonToLoad
                        },
                    ]

                }

                //渲染数据到页面
                const instance = energyChart.current.getEchartsInstance();
                if (instance) {
                    instance.setOption(energyOption, true);
                }
            })();
        } else { //总体数据
            //显示近10年的数据
            const curYear = new Date().getFullYear()
            let days = Array(10).fill(0)
            days.fill(0).forEach((v, i) => {
                days[i] = curYear - 9 + i
            })
            //坐标
            energyOption.xAxis.data = days;
            energyOption.xAxis.name = '(Year)';
            energyOption.yAxis.name = '(kWh)';
            //取总体数据
            (async () => {
                let resp = await http.post("integrated-inverter/report/all-years/report", {
                    SerialNumber: SN,
                    Date: moment(energyDate).format('YYYY-MM') + '-01'
                }).then(async (r) => {
                    return await r.json();
                }).catch((e) => {
                    return null;
                });

                if (!active) {
                    return;
                }

                if (resp === null || resp.code !== 200) {  //请求总体数据失败
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter record error" });
                    return;
                } else {  //请求月数据成功
                    let EYear = Array(10)
                    let EYearBatChrg = Array(10)
                    let EYearToGRID = Array(10)
                    let EYearToLoad = Array(10)

                    resp.data.forEach(v => {
                        EYear[moment(v['Y']).year() - curYear + 9] = v['EYear']
                        EYearBatChrg[moment(v['Y']).year() - curYear + 9] = v['EYearBatChrg']
                        EYearToGRID[moment(v['Y']).year() - curYear + 9] = v['EYearToGRID']
                        EYearToLoad[moment(v['Y']).year() - curYear + 9] = v['EYearToLoad']
                    })
                    //Echart坐标legend
                    energyOption.legend.data = ['Solar Production', 'Battery', 'Export to Grid', 'Consumption']
                    //Echart数据
                    energyOption.series = [
                        {
                            name: 'Solar Production',
                            type: 'bar',
                            data: EYear
                        },
                        {
                            name: 'Battery',
                            type: 'bar',
                            data: EYearBatChrg
                        },
                        {
                            name: 'Export to Grid',
                            type: 'bar',
                            data: EYearToGRID
                        },
                        {
                            name: 'Consumption',
                            type: 'bar',
                            data: EYearToLoad
                        },
                    ]

                }

                //渲染数据到页面
                const instance = energyChart.current.getEchartsInstance();
                if (instance) {
                    instance.setOption(energyOption, true);
                }
            })();
        }

        return () => {
            active = false;
        };

    }, [SN, size, energyDate]);

    const handleClickSolar = () => {
        setSolarIndex((solarIndex + 1) % 3)
    }

    const handleClickBattery = () => {
        setBatteryIndex((batteryIndex + 1) % 2)
    }

    const handleClickGrid = () => {
        setGridIndex((gridIndex + 1) % 2)
    }

    //计算solar面板的百分比
    const calSolarRatio = (x, y) => {
        if (y == 0) {
            return 0
        }
        let r = 100 * x / y;
        if (r % 1 != 0) {
            r = r.toFixed(1)
        }
        return r
    }

    return (
        <div className="runtime-monitor">
            <div className="search">   {/* 搜索栏 */}
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => handleSelectSN(value)}
                    value={SN}
                    style={{ width: '200px' }}
                >{inverterSelectOptions}</Select>
            </div>
            <div className="panel">    {/* 面板 */}
                <div className="infoBlock">     {/* solar面板 */}
                    <div className="infoTitle" style={{ backgroundColor: '#63BFE6' }}>
                        Solar Yielding
                    </div>
                    <div className="infoContent" onClick={handleClickSolar} style={{ display: solarIndex == 0 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <img src="images/inverter/solar.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{invData ? invData.EDay : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Yielding</div>
                            <div style={{ fontSize: '18px' }}>{invData ? invData.ETotal : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Yielding</div>
                        </div>
                    </div>
                    <div className="infoContent" onClick={handleClickSolar} style={{ display: solarIndex == 1 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <div style={{ color: '#FF718F', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? (invData.EDay != 0 ? -calSolarRatio(invData.EDayLOAD, invData.EDay) : 0) : '--'}% Load Today
                            </div>
                            <div style={{ color: '#5CC9A0', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? (invData.EDay != 0 ? calSolarRatio(invData.EDayBatChrg, invData.EDay) : 0) : '--'}% Charge Today
                            </div>
                            <div style={{ color: '#F2A474', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? (invData.EDay != 0 ? calSolarRatio(invData.EDayToGRID, invData.EDay) : 0) : '--'}% Export Today
                            </div>
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Today {invData ? invData.EDay : '--'} kWh</div>
                        </div>
                        <div className="content-right">
                            {invData && invData.EDay != 0 ? <ReactECharts ref={chart1} option={option1} className="chart" /> : null}
                        </div>
                    </div>
                    <div className="infoContent" onClick={handleClickSolar} style={{ display: solarIndex == 2 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <div style={{ color: '#FF718F', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? (invData.ETotal != 0 ? -calSolarRatio(invData.ETotalLOAD, invData.ETotal) : 0) : '--'}% Load Total
                            </div>
                            <div style={{ color: '#5CC9A0', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? (invData.ETotal != 0 ? calSolarRatio(invData.ETotalBatChrg, invData.ETotal) : 0) : '--'}% Charge Total
                            </div>
                            <div style={{ color: '#F2A474', fontSize: '14px', marginBottom: '5px' }}>
                                {invData ? (invData.ETotal != 0 ? calSolarRatio(invData.ETotalToGRID, invData.ETotal) : 0) : '--'}% Export Total
                            </div>
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Total {invData ? invData.ETotal : '--'} kWh</div>
                        </div>
                        <div className="content-right">
                            {invData && invData.ETotal != 0 ? <ReactECharts ref={chart2} option={option2} className="chart" /> : null}
                        </div>
                    </div>
                </div>
                <div className="infoBlock">     {/* battery面板 */}
                    <div className="infoTitle" style={{ backgroundColor: '#F087A0' }}>
                        {batteryIndex == 0 ? 'Battery Charge' : 'Battery Discharge'}
                    </div>
                    <div className="infoContent" onClick={handleClickBattery} style={{ display: batteryIndex == 0 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <img src="images/inverter/battery.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{invData ? invData.EDayBatChrg : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Charge</div>
                            <div style={{ fontSize: '18px' }}>{invData ? invData.ETotalBatChrg : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Charge</div>
                        </div>
                    </div>
                    <div className="infoContent" onClick={handleClickBattery} style={{ display: batteryIndex == 1 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <img src="images/inverter/battery.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{invData ? invData.EDayBatDischrg : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Discharge</div>
                            <div style={{ fontSize: '18px' }}>{invData ? invData.ETotalBatDischrg : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Discharge</div>
                        </div>
                    </div>
                </div>
                <div className="infoBlock">     {/* grid面板 */}
                    <div className="infoTitle" style={{ backgroundColor: 'rgb(240, 156, 107)' }}>
                        {gridIndex == 0 ? 'Feed-in Energy' : 'Import'}
                    </div>
                    <div className="infoContent" onClick={handleClickGrid} style={{ display: gridIndex == 0 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <img src="images/inverter/grid.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{invData ? invData.EDayToGRID : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Export</div>
                            <div style={{ fontSize: '18px' }}>{invData ? invData.ETotalToGRID : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Export</div>
                        </div>
                    </div>
                    <div className="infoContent" onClick={handleClickGrid} style={{ display: gridIndex == 1 ? 'block' : 'none' }}>
                        <div className="content-left">
                            <img src="images/inverter/grid.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{invData ? invData.EDayFromGRID : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Import</div>
                            <div style={{ fontSize: '18px' }}>{invData ? invData.ETotalFromGRID : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Import</div>
                        </div>
                    </div>
                </div>
                <div className="infoBlock">     {/* load面板 */}
                    <div className="infoTitle" style={{ backgroundColor: '#57C696' }}>
                        Consumption
                    </div>
                    <div className="infoContent">
                        <div className="content-left">
                            <img src="images/inverter/load.png"></img>
                        </div>
                        <div className="content-right">
                            <div style={{ fontSize: '18px' }}>{invData ? invData.EDayLOAD : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Today Usage</div>
                            <div style={{ fontSize: '18px' }}>{invData ? invData.ETotalLOAD : '--'} kWh</div>
                            <div style={{ fontSize: '14px', color: '#a6b7bf' }}>Total Usage</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flow">     {/* 流图 */}
                <div className="flow-title">System Information</div>
                <div className="flow-content">
                    <img src="images/inverter/flow_pv.png" width="84" height="84" style={{ top: '-9px', left: '280px' }} />
                    <img src={"images/inverter/" + (invData && invData.IsOnline == 0 ? "flow_offline.png" : invData && (invData.ErrorCode1 || invData.ErrorCode2) ? "flow_notice.png" : "flow_normal.png")} height="32" style={{ top: '12px', left: '700px', }} />
                    <Button icon={<ReloadOutlined />} style={{ position: 'absolute', top: '12px', right: '50px' }} onClick={() => setRefresh(!refresh)} />
                    <img src="images/inverter/flow_battery.png" width="84" height="84" style={{ top: '172px', left: '40px', display: invData && invData.SOC > 10 ? 'block' : 'none' }} />
                    <img src="images/inverter/flow_battery_red.png" width="84" height="84" style={{ top: '172px', left: '40px', display: invData && invData.SOC <= 10 ? 'block' : 'none' }} />
                    <img src="images/inverter/flow_inverter.png" width="96" height="96" style={{ top: '166px', left: '280px' }} />
                    <img src="images/inverter/flow_grid.png" width="84" height="84" style={{ top: '172px', left: '720px' }} />
                    <img src="images/inverter/flow_eps.png" width="84" height="84" style={{ bottom: '15px', left: '280px' }} />
                    <img src="images/inverter/flow_load.png" width="84" height="84" style={{ bottom: '15px', left: '550px' }} />
                    <div style={{ width: '260px', height: '32px', top: '12px', left: '380px', fontSize: '18px' }}>  {/* PV数据 */}
                        <div>
                            <span style={{ marginRight: '50px' }}>PV1</span>
                            <span style={{ display: 'inline-block', width: '90px' }}>{invData ? invData.PDC / 10 : '--'} W</span>
                            <span>{invData ? invData.VMPPT1 / 10 : '--'} V</span>
                        </div>
                        <div>
                            <span style={{ marginRight: '50px' }}>PV2</span>
                            <span style={{ display: 'inline-block', width: '90px' }}>{invData ? invData.PDCToLoad / 10 : '--'} W</span>
                            <span>{invData ? invData.VMPPT2 / 10 : '--'} V</span>
                        </div>
                    </div>
                    <div style={{ width: '94px', height: '62px', top: '111px', left: '45px' }}>  {/* 电池数据 */}
                        <div style={{ fontSize: '18px' }}>{invData ? invData.PBat / 10 : '--'} W</div>
                        <div style={{ color: '#a6b7bf' }}>Battery Power</div>
                    </div>
                    <div style={{ width: '125px', height: '62px', top: '225px' }}>  {/* 电池数据 */}
                        <div style={{ fontSize: '18px' }}>{invData ? invData.SOC : '--'}%</div>
                        <div style={{ color: '#a6b7bf' }}>Battery Level(SOC)</div>
                    </div>
                    <div style={{ width: '84px', height: '32px', top: '275px' }}>  {/* 电池数据 */}
                        <div style={{ fontSize: '18px' }}>{invData ? invData.VBat / 10 : '--'} Vdc</div>
                    </div>
                    <div style={{ width: '92px', height: '62px', top: '111px', left: '720px' }}>  {/* Grid数据 */}
                        <div style={{ fontSize: '18px' }}>{invData ? invData.PAC1GRID / 10 : '--'} W</div>
                        <div style={{ color: '#a6b7bf' }}>Import Power</div>
                    </div>
                    <div style={{ width: '96px', height: '66px', top: '256px', left: '740px' }}>  {/* Grid数据 */}
                        <div style={{ fontSize: '18px' }}>{invData ? invData.VAC1GRID / 10 : '--'} Vac</div>
                        <div style={{ fontSize: '18px' }}>{invData ? invData.FACGRID / 100 : '--'} Hz</div>
                    </div>
                    <div style={{ width: '128px', height: '56px', top: '381px', left: '370px' }}>  {/* Eps数据 */}
                        <div style={{ fontSize: '18px' }}>StandBy</div>
                        <div style={{ color: '#a6b7bf' }}>Backup Power(EPS)</div>
                    </div>
                    <div style={{ width: '136px', height: '62px', top: '379px', left: '640px' }}>  {/* Load数据 */}
                        <div style={{ fontSize: '18px' }}>{invData ? invData.PAC1LOAD / 10 : '--'} W</div>
                        <div style={{ color: '#a6b7bf' }}>Consumption Power</div>
                    </div>
                    <div style={{ width: '178px', height: '26px', top: '410px' }}>  {/* Time */}
                        <div style={{ fontSize: '18px' }}>{invData ? invData.DataTime : null}</div>
                    </div>
                    {/* 逆变器数据 */}
                    <div style={{width: '150px', top: '242px', left: '370px'}}>
                        <div style={{fontSize: '14px'}}>{invData ? invData.ManufacturerName : null}</div>
                    </div>
                    {/* 流动的箭头 */}
                    <img src="images/inverter/flow_down.gif" style={{ top: '74px', left: '315px', display: production ? 'block' : 'none' }} /> {/* pv->inverter */}
                    <img src="images/inverter/flow_down.gif" style={{ top: '272px', left: '315px', display: powerToEPS ? 'block' : 'none' }} /> {/* inverter->eps */}
                    <img src="images/inverter/flow_right.gif" style={{ top: '206px', left: '106px', display: powerFromBattery ? 'block' : 'none' }} />  {/* battery->inverter */}
                    <img src="images/inverter/flow_right.gif" style={{ top: '206px', left: '188px', display: powerFromBattery ? 'block' : 'none' }} />  {/* battery->inverter */}
                    <img src="images/inverter/flow_left.gif" style={{ top: '206px', left: '106px', display: powerToBattery ? 'block' : 'none' }} />  {/* inverter->battery */}
                    <img src="images/inverter/flow_left.gif" style={{ top: '206px', left: '188px', display: powerToBattery ? 'block' : 'none' }} />  {/* inverter->battery */}
                    <img src="images/inverter/flow_right.gif" style={{ top: '206px', left: '380px', display: powerToGrid ? 'block' : 'none' }} /> {/* inverter->grid */}
                    <img src="images/inverter/flow_right.gif" style={{ top: '206px', left: '438px', display: powerToGrid ? 'block' : 'none' }} /> {/* inverter->grid */}
                    <img src="images/inverter/flow_right.gif" style={{ top: '206px', left: '520px', display: powerToGrid ? 'block' : 'none' }} /> {/* inverter->grid */}
                    <img src="images/inverter/flow_right.gif" style={{ top: '206px', left: '600px', display: powerToGrid ? 'block' : 'none' }} /> {/* inverter->grid */}
                    <img src="images/inverter/flow_right.gif" style={{ top: '206px', left: '650px', display: powerToGrid ? 'block' : 'none' }} /> {/* inverter->grid */}
                    <img src="images/inverter/flow_down.gif" style={{ top: '74px', left: '578px', display: consumedDirectly ? 'block' : 'none' }} /> {/*pv->load */}
                    <img src="images/inverter/flow_down.gif" style={{ top: '120px', left: '578px', display: consumedDirectly ? 'block' : 'none' }} /> {/*pv->load */}
                    <img src="images/inverter/flow_down.gif" style={{ top: '230px', left: '578px', display: consumedDirectly ? 'block' : 'none' }} /> {/*pv->load */} {/*grid->load*/}
                    <img src="images/inverter/flow_down.gif" style={{ top: '276px', left: '578px', display: consumedDirectly ? 'block' : 'none' }} /> {/*pv->load */} {/*grid->load*/}
                    {/* <img src="images/inverter/flow_left.gif" style={{top: '206px', left: '600px', display: invData&&invData.EDayFromGRID>0 ? 'block' : 'none'}} />
                    <img src="images/inverter/flow_left.gif" style={{top: '206px', left: '650px', display: invData&&invData.EDayFromGRID>0 ? 'block' : 'none'}} />  //grid->load */}
                </div>
            </div>
            <div>
                <div className="power">    {/* Input&Output Power */}
                    <div className="power-title">Input&Output Power</div>
                    <div className="power-powerDate">
                        <DatePicker
                            defaultValue={moment()}
                            value={moment(powerDate)}
                            onChange={(_, ds) => setPowerDate(ds)}
                            allowClear={false}
                        />
                    </div>
                    <div className="power-chart">
                        <ReactECharts ref={powerChart} option={powerOption} style={{ height: 320 }} />
                    </div>
                </div>
                <div className="siderbar">  {/* notice 和 revenue */}
                    <div className="notice">
                        <div className="notice-title">Notice</div>
                        <div className="notice-content">None</div>
                    </div>
                    <div className="revenue">
                        <div className="revenue-title">Other Revenue</div>
                        <div className="revenue-box CO2">
                            <img height="72" src="images/inverter/co2.png"></img>
                            <div className="box-num">
                                <span>{invData && invData.ETotal > 0 ? (invData.ETotal / 1.003).toFixed(2) : 0} kG</span><br />
                                <span>CO<sub>2</sub> Reduction</span>
                            </div>
                        </div>
                        <div className="revenue-box coal">
                            <img height="72" src="images/inverter/coal.png"></img>
                            <div className="box-num">
                                <span>{invData && invData.ETotal > 0 ? (invData.ETotal / 2.5).toFixed(2) : 0} kG</span><br />
                                <span>Coal Saving</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="energy">    {/* Energy Overview */}
                <div className="energy-title">
                    Energy Overview {size == 'month' ? '(' + moment(energyDate).format('YYYY-MM') + ')' : size == 'year' ? '(' + moment(energyDate).format('YYYY') + ')' : null}
                </div>
                <div className="energy-date" style={{ display: size == 'total' ? 'none' : 'block' }}>
                    <DatePicker
                        picker={size == "month" ? 'month' : 'year'}
                        onChange={(_, ds) => setEnergyDate(ds)}
                        defaultValue={moment()}
                        value={moment(energyDate)}
                        allowClear={false}
                    />
                </div>
                <div className="energy-btn">
                    <Radio.Group value={size} optionType='button' buttonStyle='solid' onChange={e => setSize(e.target.value)}>
                        <Radio.Button value="month">Monthly</Radio.Button>
                        <Radio.Button value="year">Yearly</Radio.Button>
                        <Radio.Button value="total">Total</Radio.Button>
                    </Radio.Group>
                </div>
                <div className="energy-chart">
                    <ReactECharts ref={energyChart} option={energyOption} style={{ height: 360 }} />
                </div>
            </div>
        </div>
    )
}

export default RealtimeMonitor;