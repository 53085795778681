import { useEffect, useState, useContext } from 'react';
import { Table, Input, Button, Modal, Form, Row, Col, Select } from 'antd';
import { CloudDownloadOutlined, ExclamationCircleOutlined, UpOutlined, DownOutlined, SwapRightOutlined } from '@ant-design/icons';
import utils from '../../../utils';
import http from '../../../Http';
import AddInverterBurning from './AddInverterBurning';


function InverterBurningList(props) {
    const setTip = useContext(utils.tipContext);
    const [form] = Form.useForm();

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        SerialNumber: '',
        DistributorName: '',
        UserID: '',
        InverterModel: '',
        FileName: '',
        Version: '',
    })
    const [expand, setExpand] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [showAddBurning, setShowAddBurning] = useState(false);

    //获取记录列表
    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            let resp = await http.post("integrated-inverter/burning/list2", {
                PageNo: pageNo,
                PageSize: pageSize, 
                ...params
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (!active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load inverter burning error"});
                setRowTotal(0)
                setRows([]);
                return;
            }

            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);

            console.log(resp.data)
        })();

        return () => {
            active = false;
        };

    }, [pageNo, pageSize, params]);

    const handleSearch = () => {
        setPageNo(0);
        const ps = form.getFieldsValue();
        ps.SerialNumber = ps.SN || '';
        ps.DistributorName = ps.DistributorName || '';
        ps.UserID = ps.UserID || '';
        ps.InverterModel = ps.InverterModel || '';
        ps.FileName = ps.FileName || '';
        ps.Version = ps.Version || '';
        setParams(ps)
    }

    //判断烧录进度
    const burnProgress = (v, r, idx) => {
        if(r.BurningID == r.BindingID) {
            if(!r.Progress) {
                return 'to be burned';
            } else if(r.Progress >= r.DataLength) {
                return 'burned';
            } else {
                return 'burning';
            }
        }
    }

    //操作
    const renderAction = (v, r, idx) => {
        if(r.BindingID != r.BurningID) return;
        var btns = [];
        const btnConfirm = <Button key="Confirm" onClick={() => { handleConfirm(r, idx) }} type="primary" size="small" style={{ marginRight: "3px" }}>Confirm</Button>;
        // const btnIgnore = <Button key="Ignore" onClick={() => { handleIgnore(r, idx) }} type="primary" size="small" style={{ marginRight: "3px" }}>Ignore</Button>;
        if(!r.Progress || r.Progress < r.DataLength) {
            btns.push(btnConfirm)
            //btns.push(btnIgnore)
        }
        return (
            <>{btns}</>
        )
    }

    //执行烧录
    const handleConfirm = async (r, idx) => {
        try {
            const resp = await http.postJSON("integrated-inverter/burning/request", {
                SerialNumber: r.InverterID,
                Burning: {
                    FirmwareID: r.FirmwareID,
                    ID: r.BurningID
                }
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningManagement]", e);
                return null;
            });

            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "Confirm error" });
                return;
            }

            setTip({ open: true, severity: "success", msg: "Confirm success" })
        } catch (e) {
            setTip({ open: true, severity: "error", msg: "Confirm error" });
        }
    }


    //取消烧录任务
    const handleIgnore = async (r, idx) => {
        
    }

    //关闭添加烧录任务窗口
    const handleAddingClose = (rs) => {
        setShowAddBurning(false);
        if (!rs) {
            return;
        }
        document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        setRows([...(rs instanceof Array ? rs : [rs]), ...rows]);
        setRowTotal(rowTotal+(rs instanceof Array ? rs.length : 1));
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => { 
        if(e.code == "Enter") {
            handleSearch()
        }
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}> 
                <Form 
                    form={form}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                    >
                        <Row gutter={24}>
                            <Col span={8} key="SN">
                                <Form.Item name="SN" label="SN">
                                    <Input placeholder="Enter Inverter SN" onPressEnter={handleSearch} />
                                </Form.Item>
                            </Col>
                            <Col span={8} key="Distributor">
                                <Form.Item name="DistributorName" label="Distributor">
                                    <Input placeholder="Enter Distributor Name" onPressEnter={handleSearch} />
                                </Form.Item>
                            </Col>
                            <Col span={8} key="UserID">
                                <Form.Item name="UserID" label="UserID">
                                    <Input placeholder="Enter User ID" onPressEnter={handleSearch} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8} key="InverterModel" style={{display:expand?"block":"none"}}>
                                <Form.Item name="InverterModel" label="InverterModel">
                                    <Input placeholder="Enter Inverter Model" onPressEnter={handleSearch} />
                                </Form.Item>
                            </Col>
                            <Col span={8} key="FileName" style={{display:expand?"block":"none"}}>
                                <Form.Item name="FileName" label="FileName">
                                    <Input placeholder="Enter Fileware FileName" onPressEnter={handleSearch} />
                                </Form.Item>
                            </Col>
                            <Col span={8} key="Version" style={{display:expand?"block":"none"}}>
                                <Form.Item name="Version" label="Version">
                                    <Input placeholder="Enter Firmware Version" onPressEnter={handleSearch} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Button type="primary" onClick={handleSearch}>
                                    Search
                                </Button>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    onClick={() => {
                                        form.resetFields();
                                    }}
                                >
                                    Clear
                                </Button>
                                <a
                                    style={{ fontSize: 12 }}
                                    onClick={() => {
                                        setExpand(!expand);
                                    }}
                                >
                                    {expand ? <UpOutlined /> : <DownOutlined />} {expand ? 'Collapse' : 'Expand'}
                                </a>
                            </Col>
                        </Row>
                    </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
            }}>
                <Button type="primary"  icon={<CloudDownloadOutlined /> } size="middle" style={{marginLeft: 5}} onClick={() => setShowAddBurning(true)}>Add Burning</Button>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 4,
                paddingBottom: 8,
            }}>
                <Table
                    columns={[
                        {
                            title: "",
                            width: 40,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        },
                        {
                            title: 'Inverter SN',
                            dataIndex: "InverterID",
                            width: 120,
                            fixed: 'left',
                        },
                        {
                            title: 'Inverter Model',
                            dataIndex: 'InverterModel',
                            width: 120,
                        },
                        {
                            title: 'File Name',
                            dataIndex: 'FileName',
                            width: 120,
                        },
                        {
                            title: 'Firmware Version',
                            dataIndex: 'Version',
                            width: 120,
                        },
                        {
                            title: 'DataLength',
                            dataIndex: 'DataLength',
                            width: 120,
                        },
                        {
                            title: 'Progress',
                            width: 120,
                            render: burnProgress,
                        },
                        {
                            title: 'Burning Time',
                            dataIndex: 'STime',
                            width: 120,
                        },
                        {
                            title: 'Note',
                            dataIndex: 'Note',
                            width: 120,
                        },
                        {
                            title: 'Create Time',
                            dataIndex: 'CreateTime',
                            width: 120,
                        },
                        {
                            title: 'Action',
                            width: 160,
                            render: renderAction
                        }
                    ]}
                    pagination={{
                        pageSizeOptions: [25,50,100],
                        current: pageNo+1,
                        pageSize: pageSize,
                        total: rowTotal,
                        showSizeChanger: true,
                        showTotal: (total) => {return `Total ${total}`},
                        onShowSizeChange: (current, size) => {
                            setPageSize(size);
                        },
                        onChange: (page, pageSize) => {
                            setPageNo(page-1);                                
                        }
                    }}
                    tableLayout="fixed"
                    bordered={true}
                    size="middle"
                    loading={loading}
                    rowKey="ID"
                    dataSource={rows}
                    scroll={{ x: 600, y: 600 }}
                >
                </Table>
            </div>
            <AddInverterBurning showAddBurning={showAddBurning} onClose={handleAddingClose}
                zIndex={2000} />
        </div>
    )
}

export default InverterBurningList;