import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import { createTheme } from '@material-ui/core/styles';
import utils from '../utils';
import { Table, Input, Button, Modal, Form, Row, Col, Checkbox, Select, Popover } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import defs from "./defs"
import FirmwareAdd from "./FirmwareAdd";

const theme = createTheme({});

function FirmwareManagement (props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        Name: '',
        ProductName: '',
        DistributorName: '',
        Type: '',
        BurningType: '',
        Version: '',
        NotExpired: ""
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [productSelectOptions, setProductSelectOptions] = useState([]);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [expand, setExpand] = useState(false);

    const refTableContainer = useRef();
    const refTimerProductSearcher = useRef(null);
    const refProductSearchValue = useRef(null);
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    const [form] = Form.useForm();

    useEffect(() => {
        let active = true;
        console.log("FirmwareManagement Effect");

        (async () => {
            setLoading(true);
            
            
            let resp = await http.post("firmware/management/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[FirmwareManagement]", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setRowTotal(0)
                setRows([]);
        
                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("FirmwareManagement Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }
        
        let obj = document.createElement('object');
        el.objResizer = obj; 
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (! el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const handleSearchProduct = (v) => {
        if (refTimerProductSearcher.current != null) {
            clearTimeout(refTimerProductSearcher.current);
        }
        refProductSearchValue.current = v;

        refTimerProductSearcher.current = setTimeout(async () => {
            const resp = await http.post("product/name/list", {
                Value: v,
            }).then(async(r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[FirmwareManagement]", e);
                return null;
            });

            if (refProductSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load product list error"});
                    return;
                }

                const ls = resp.data.map(({Name, ID}) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setProductSelectOptions(ls);
            } catch(e) {
                console.log("[FirmwareManagement]", e);

                setTip({open:true, severity:"error", msg: "load product list error"});
            }
        }, 300);
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async(r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[FirmwareManagement]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load distributor list error"});
                    return;
                }

                const ls = resp.data.map(({Name, ID}) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch(e) {
                console.log("[FirmwareManagement]", e);

                setTip({open:true, severity:"error", msg: "load distributor list error"});
            }
        }, 300);
    }

    const handleSearch = () => {
        setSelectedKeys([]);
        setPageNo(0);

        const ps = form.getFieldsValue();
        ps.Name = ps.Name || "";
        ps.ProductName = ps.ProductName || "";
        ps.DistributorName = ps.DistributorName || "";
        ps.Type = ps.Type || "";
        ps.BurningType = ps.BurningType || "";
        ps.Version = ps.Version || "";
        ps.NotExpired = ps.NotExpired ? (ps.NotExpired.indexOf("NotExpired")>-1 && ps.NotExpired.indexOf("Expired")> -1 ? "" : ps.NotExpired.indexOf("NotExpired")>-1 ? 1 : 0) : "";
        setParams(ps);
    }

    const renderAction = (v, r, idx) => {
        return (
            <>
                <Button onClick={()=>{handleDelete(r.ID, r.Name, idx)}} type="link" icon={<DeleteOutlined  size="small"/>} />
            </>
        )
    }

    const handleAdd = () => {
        setShowAdd(true);
    }

    const handleDelete = async (ID, Name, idx) => {
        Modal.confirm({
            title: 'Do you Want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: Name,
            onOk: async () => {
                try {
                    const resp = await http.post("firmware/management/delete", {ID: ID}).then(async(r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[FirmwareManagement]", e);
                        return null;
                    });
        
                    if (resp.code !== 200) {
                        setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "delete error"});
                        return
                    }
                    setTip({open: true, severity: 'success', msg: 'delete success'});
        
                    setRows([...rows.slice(0,idx), ...rows.slice(idx+1)]);
                    setRowTotal(rowTotal-1);
                    selectedKeys.every((v,i) => {
                        if (v === ID) {
                            setSelectedKeys([...selectedKeys.slice(0,i), ...selectedKeys.slice(i+1)]);
                            return false;
                        }
        
                        return true;
                    })
                } catch(e) {
                    console.log("[FirmwareManagement]", e);
        
                    setTip({open:true, severity:"error", msg: "delete error"});
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleDeleteAllOfSelected = async () => {
        if (selectedKeys.length < 1) {
            return;
        }

        let ns = [];
        selectedKeys.forEach((ID) => {
            rows.every((r) => {
                if (ID === r.ID) {
                    ns.push(<label>{r.Name}</label>);
                    return false;
                }

                return true;
            });
        })
        if (ns.length > 10) {
            ns = ns.slice(0, 10);
            ns.push(<lable>...</lable>);
        }

        Modal.confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: (<div style={{display:'flex', flexDirection:'column'}}>{ns}</div>),
            onOk: async () => {
                try {
                    const resp = await http.post("firmware/management/multi/delete", {IDs: selectedKeys.join(",")}).then(async(r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[FirmwareManagement]", e);
                        return null;
                    });
        
                    if (resp.code !== 200) {
                        setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "delete error"});
                        return
                    }
                    setTip({open: true, severity: 'success', msg: 'delete success'});
        
                    let rs = [];
                    rows.forEach((v, i) => {
                        const ID = v.ID;
                        let ok = false;
                        selectedKeys.every((ID2) => {
                            if (ID === ID2) {
                                ok = true;
                                return false;
                            }
        
                            return true;
                        });
                        if (!ok) {
                            rs.push(v);
                        } 
                    });
                    setRows(rs);
                    setRowTotal(rowTotal - selectedKeys.length);
                    setSelectedKeys([]);
                } catch(e) {
                    console.log("[FirmwareManagement]", e);
        
                    setTip({open:true, severity:"error", msg: "delete error"});
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleSelect = (selectedRowKeys, selectedRows) => {
        setSelectedKeys([...selectedRowKeys]);
    }

    const handleAddingClose = (row) => {
        setShowAdd(false);

        if (! row) {
            return;
        }

        document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        setSelectedKeys([row.ID]);
        setRows([row, ...rows]);
        setRowTotal(rowTotal+1);
    }

    const calcTableBodyHeight = () => {
        if (! refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return  h>=54 ? h - 54 : h /2;
    }

    var ts = [];
    for (let k in defs.types) {
        //defs.types.map((t, k) => {return (<Select.Option key={k} value={k}>t</Select.Option>);})
        ts.push(<Select.Option key={k} value={k}>{defs.types[k]}</Select.Option>)
    }

    console.log(ts);

    //按下Enter键时进行查找
    const searchByEnter = (e) => { 
        if(e.code == "Enter") {
            handleSearch()
        }
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form 
                    form={form}
                    initialValues={{
                        NotExpired: ["Expired", "NotExpired"],
                    }}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={6} key="Name">
                            <Form.Item 
                                name="Name"
                                label="Name"
                            >
                                <Input placeholder="Enter Firmware Name" onPressEnter={handleSearch}/>
                            </Form.Item>
                        </Col>
                        <Col span={6} key="Product">
                            <Form.Item 
                                name="ProductName"
                                label="Product"
                            >
                                <Select 
                                    showSearch
                                    placeholder="Enter Product Name"
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={handleSearchProduct}
                                    onInputKeyDown={searchByEnter}
                                >{productSelectOptions}</Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} key="Distributor">
                            <Form.Item 
                                name="DistributorName"
                                label="Distributor"
                            >
                                <Select 
                                    showSearch
                                    placeholder="Enter Distributor Name"
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={handleSearchDistributor}
                                    onInputKeyDown={searchByEnter}
                                >{distributorSelectOptions}</Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} key="Type">
                            <Form.Item 
                                name="Type"
                                label="Type"
                            >
                                <Select onInputKeyDown={searchByEnter}>
                                    {ts}
                                    {/* <Select.Option value={1}>4820</Select.Option>
                                    <Select.Option value={2}>4850/48100</Select.Option>
                                    <Select.Option value={3}>3.4kWh</Select.Option>
                                    <Select.Option value={4}>5.8kWh</Select.Option>
                                    <Select.Option value={5}>6.8kWh</Select.Option> */}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} key="BurningType" style={{display:expand?"block":"none"}}>
                            <Form.Item 
                                name="BurningType"
                                label="BurningType"
                            >
                                <Select onInputKeyDown={searchByEnter}>
                                    <Select.Option value={1}>APP</Select.Option>
                                    <Select.Option value={2}>BOOT</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} key="Version" style={{display:expand?"block":"none"}}>
                            <Form.Item 
                                name="Version"
                                label="Version"
                            >
                                <Input placeholder="e.g.: 1.8.8" onPressEnter={handleSearch} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="NotExpired" style={{display:expand?"block":"none"}}>
                            <Form.Item 
                                name="NotExpired"
                                label=""
                            >
                                <Checkbox.Group options={["NotExpired", 'Expired']} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={handleSearch}>
                                Search
                            </Button>
                            <Button
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                            <a
                                style={{ fontSize: 12 }}
                                onClick={() => {
                                    setExpand(!expand);
                                }}
                            >
                                {expand ? <UpOutlined /> : <DownOutlined />} {expand ? 'Collapse' : 'Expand'}
                            </a>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
                paddingBottom: 8,
            }}>
                <Button onClick={handleAdd} type="primary"  icon={<PlusOutlined  /> } size="middle" />
                <Button onClick={handleDeleteAllOfSelected} disabled={selectedKeys.length===0} type="primary"  icon={<DeleteOutlined />} size="middle" style={{marginLeft: 5}}/>
            </div>
            <div style={{
                flex:"1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{position: "absolute", height: '100%', width: "100%", overflow: "hidden"}}>
                    <Table 
                        columns={[{
                            title: "",
                            width: 70,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo*pageSize+idx+1);
                            }
                        },{
                            title: "Product",
                            dataIndex: 'ProductName',
                            width: 200,
                            fixed: 'left',
                        },{
                            title: "Name",
                            dataIndex: "Name",
                            width: 200,
                            fixed: 'left',
                        },{
                            title: "Version",
                            dataIndex: "Version",
                            width: 80,
                        },{
                            title: "Type",
                            dataIndex: 'Type',
                            width: 120,
                            render: (v, r, idx) => {
                                return defs.types[v] || "";
                            }
                        },{
                            title: "BurningType",
                            dataIndex: "BurningType",
                            width: 100,
                            render: (v, r, idx) => {
                                return defs.burningTypes[v] || "";
                            }
                        },{
                            title: "MD5",
                            dataIndex: "MD5",
                            width: 270,
                        },{
                            title: "DataLength",
                            dataIndex: "DataLength",
                            width: 140,
                            render: (v) => {
                                return v ? Math.round(v/1024) + 'KB (' + v + 'B)' : ''
                            }
                        },{
                            title: "ExpiredTime",
                            dataIndex: "ExpiredTime",
                            width: 150,
                            render: (v) => {
                                return v && v != "0000-00-00 00:00:00" ? v : "";
                            }
                        },{
                            title: "Distributor",
                            dataIndex: "DistributorName",
                            width: 200,
                        },{
                            title: "Note",
                            dataIndex: "Note",
                            width: 200,
                            render: (v) => {
                                if (!v) {
                                    return v;
                                }

                                if (v.indexOf("\n") > -1) {
                                    const content = (
                                        <Input.TextArea rows={5} value={v} readOnly/>
                                    )
                                    return (
                                        <Popover title="Note" content={content}>
                                            <div>{v}</div>
                                        </Popover>
                                    );
                                }

                                return v;
                            }
                        },{
                            title: "Create Time",
                            dataIndex: 'CreateTime',
                            width: 150,
                        },{
                            title: 'Action',
                            key: 'Action',
                            width: 90,
                            render: renderAction,
                        }]}
                        pagination={{
                            pageSizeOptions: [25,50,100],
                            current: pageNo+1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => {return `Total ${total}`},
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page-1);                                
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                        rowSelection={{
                            selectedRowKeys: selectedKeys,
                            onChange: handleSelect,
                            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                        }}
                    />
                </div>
            </div>
            <FirmwareAdd show={showAdd} onClose={handleAddingClose}/>
            
        </div>
    );
}

export default FirmwareManagement;