import React, { useEffect, useState, useContext, useRef } from 'react';
import { Select } from 'antd';
import http from '../../Http';
import utils from '../../utils';
import RealtimeMonitor from './RealtimeMonitor';
import DataAnalysis from './DataAnalysis/DataAnalysis';
import SiteManagement from './SiteManagement';
import Outline from './Outline';
import RemoteSetting from './RemoteSetting/RemoteSetting';
import './RealtimeMonitor.css';
import './DataAnalysis/DataAnalysis.css';
import './Outline.css';
import './RemoteSetting/RemoteSetting.css';

function Inverter2(props) {
    const{activeMenu} = props;

    const setTip = useContext(utils.tipContext)

    const [inverterSelectOptions, setInverterSelectOptions] = useState([])  //所有电站列表
    const [SN, setSN] = useState(null)  //当前选择的逆变器SN

    //获取电站列表
    useEffect(() => {
        let active = true;

        (async () => {
            let resp = await http.post("integrated-inverter/list", {
                PageNo: 0,
                PageSize: 10000,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load inverter list error" });
                return;
            }
            //逆变器选择框
            const ls = resp.data.map(v => {
                return <Select.Option key={v.SerialName} value={v.SerialName}>{v.SerialName}</Select.Option>
            })
            setInverterSelectOptions(ls)
        })();

        return () => {
            active = false;
        };

    }, []);

    return (
        <>
        {/* <div className="inverter-container"> */}
            {
                activeMenu == 0 ? <RealtimeMonitor inverterSelectOptions={inverterSelectOptions} SN={SN} handleSelectSN={(sn)=>setSN(sn)} /> : 
                activeMenu == 1 ? <DataAnalysis inverterSelectOptions={inverterSelectOptions} SN={SN} handleSelectSN={(sn)=>setSN(sn)} /> :
                activeMenu == 2 ? <SiteManagement inverterSelectOptions={inverterSelectOptions} SN={SN} handleSelectSN={(sn)=>setSN(sn)} /> :
                activeMenu == 3 ? <Outline inverterSelectOptions={inverterSelectOptions} SN={SN} handleSelectSN={(sn)=>setSN(sn)} /> :
                activeMenu == 4 ? <RemoteSetting inverterSelectOptions={inverterSelectOptions} SN={SN} handleSelectSN={(sn)=>setSN(sn)} /> :
                null
            }
        {/* </div> */}
        </>
    )
}

export default Inverter2;