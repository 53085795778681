import { useState, useEffect, useRef } from 'react';
import { Typography } from "@material-ui/core";
import utils from "../utils";

function CellVoltage (props) {
    const {setTip, data} = props;

    // if (data) {
    //     data["CurrentCollectorFault"] = 2;
    // }

    const bs = [];
    let ln = 16;
    const ds = data ? data.CellVoltages : [];
    if (data) {
        ln = data.CellVoltages.length;
    }
    for (let i=0; i<ln; i++) {
        bs.push(
            <div style={{
                margin: 5,
                display: "flex",
                flexDirection: 'column',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "center",
                }}>
                    <div style={{
                        backgroundColor: "white",
                        width: 15,
                        height: 3,
                        borderTopLeftRadius: 3,
                        borderTopRightRadius: 3,
                    }}></div>
                </div>
                <div style={{
                    width: 40,
                    height: 150,
                    border: "2px solid white",
                    borderRadius: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        transform: "matrix(0, 1, -1, 0, 0, -20)",
                        color: "white",
                    }}>V{(i+1).toString().padStart(3, 0)}:&nbsp;{i<ds.length ? (ds[i]/1000).toFixed(3):"-"}</div>
                </div>
            </div>
        );
    }

    return (
        <div style={{
            flex: "1 0 1px",
            display: "flex",
            flexDirection: "column",
        }}>
            <div style={{
                flex: "0 0 36px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "black",
            }}>
                <Typography style={{color:"#eee"}}>CellVoltage</Typography>
                <Typography style={{color:"#aaa", fontSize:12}}>{data ? utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date()) : "yyyy-mm-dd hh:mm:ss"}</Typography>
            </div>
            <div style={{
                backgroundColor: "black",
                flex: "1, 1 1px",
                minWidth: 300,
                display: "flex",
                flexDirection: 'row',
                flexWrap: "wrap",
                padding: 5,
            }} >
                {bs}
            </div>
        </div>
    );
}

export default CellVoltage;