import { useState, useEffect, useRef } from 'react';
import { Typography } from "@material-ui/core";
import utils from "../utils";
import Thermometer from "./Thermometer";

function Temperature (props) {
    const {setTip, data} = props;

    // if (data) {
    //     data["CurrentCollectorFault"] = 2;
    // }

    const bs = [];
    let ln = 3;
    const ds = data ? data.Temperatures : [];
    if (data) {
        ln = data.Temperatures.length;
    }
    for (let i=0; i<ln; i++) {
        const t = i<ds.length ? (ds[i]-50) : 0;
        bs.push(
            <div style={{
                margin: 5,
                display: "flex",
                flexDirection: 'column',
            }}>
                <div style={{
                    width: 100,
                    height: 200,
                    display: 'flex',
                }}>
                    <Thermometer id_x={i.toString()} width={100} height={200} steps={6} minValue={-50} maxValue={100} currentValue={t}/>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "center",
                }}>
                    <div style={{
                        color: "white",
                    }}>T{(i+1).toString().padStart(2, 0)}:&nbsp;{i<ds.length ? (ds[i]-50):"-"}</div>
                </div>
            </div>
        );
    }

    return (
        <div style={{
            flex: "1 0 1px",
            display: "flex",
            flexDirection: "column",
        }}>
            <div style={{
                flex: "0 0 36px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "black",
            }}>
                <Typography style={{color:"#eee"}}>Temperature</Typography>
                <Typography style={{color:"#aaa", fontSize:12}}>{data ? utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date()) : "yyyy-mm-dd hh:mm:ss"}</Typography>
            </div>
            <div style={{
                backgroundColor: "black",
                flex: "1, 1 1px",
                minWidth: 250,
                display: "flex",
                flexDirection: 'row',
                flexWrap: "wrap",
                padding: 5,
            }} >
                {bs}
            </div>
        </div>
    );
}

export default Temperature;