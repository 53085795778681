import { useState, useEffect, useRef } from 'react';
import { Typography } from "@material-ui/core";
import utils from "../utils";

function Alarm(props) {
    const { setTip, data } = props;

    // if (data) {
    //     data["CurrentCollectorFault"] = 2;
    // }

    return (
        <div style={{
            flex: "1 0 1px",
            backgroundColor: "#666",
            display: "flex",
            flexDirection: "column",
        }}>
            <div style={{
                flex: "0 0 36px",
                backgroundColor: "black",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Typography style={{ color: "#eee" }}>Warn&Fault</Typography>
                <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date()) : "yyyy-mm-dd hh:mm:ss"}</Typography>
            </div>
            <div style={{
                backgroundColor: "black",
                flex: "1, 1 1px",
                minWidth: 350,
                display: "flex",
                flexDirection: 'column',
                alignItems: 'stretch',
            }} >
                {/* Sensor&Link Faults */}
                <div style={{
                    margin: 10,
                    padding: 5,
                    display: "flex",
                    flexDirection: 'column',
                    backgroundColor: '#565353',
                }}>
                    <Typography style={{
                        alignSelf: "center",
                        color: "#fff",
                        fontSize: 16,
                    }}>Sensor&Link Faults</Typography>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>BMU Communication:</Typography>
                        {data ? AlarmFomatter(data["CommunicationFault"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Cell Voltage Sensor Link:</Typography>
                        {data ? AlarmFomatter(data["CLF_CellVoltage"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Temperature Sensor Link:</Typography>
                        {data ? AlarmFomatter(data["CLF_T"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Current Sensor Link:</Typography>
                        {data ? AlarmFomatter(data["CurrentCollectorFault"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Battary Sensor:</Typography>
                        {data ? AlarmFomatter(data["BattaryCollectorFault"]) : "-"}
                    </div>
                </div>
                {/* Cell Faults */}
                <div style={{
                    margin: 10,
                    padding: 5,
                    display: "flex",
                    flexDirection: 'column',
                    backgroundColor: '#565353',
                }}>
                    <Typography style={{
                        alignSelf: "center",
                        color: "#fff",
                        fontSize: 16,
                    }}>Cell Faults</Typography>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Excessive Voltage Difference:</Typography>
                        {data ? AlarmFomatter(data["CellVoltageDiffExcess"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Voltage Over:</Typography>
                        {data ? AlarmFomatter(data["CellVoltageOver"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Voltage Under:</Typography>
                        {data ? AlarmFomatter(data["CellVoltageUnder"]) : "-"}
                    </div>
                </div>
                {/* Charge Faults */}
                <div style={{
                    margin: 10,
                    padding: 5,
                    display: "flex",
                    flexDirection: 'column',
                    backgroundColor: '#565353',
                }}>
                    <Typography style={{
                        alignSelf: "center",
                        color: "#fff",
                        fontSize: 16,
                    }}>Charge Faults</Typography>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Temperature Difference:</Typography>
                        {data ? AlarmFomatter(data["T_DiffExcess"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Temperature Over:</Typography>
                        {data ? AlarmFomatter(data["T_Over"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Temperature Under:</Typography>
                        {data ? AlarmFomatter(data["T_Under"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Current Over:</Typography>
                        {data ? AlarmFomatter(data["OverCurrentCharge"]) : "-"}
                    </div>
                </div>
                {/* Discharge Faults */}
                <div style={{
                    margin: 10,
                    padding: 5,
                    display: "flex",
                    flexDirection: 'column',
                    backgroundColor: '#565353',
                }}>
                    <Typography style={{
                        alignSelf: "center",
                        color: "#fff",
                        fontSize: 16,
                    }}>Discharge Faults</Typography>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Temperature Difference:</Typography>
                        {data ? AlarmFomatter(data["Discharge_T_DiffExcess"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Temperature Over:</Typography>
                        {data ? AlarmFomatter(data["Discharge_T_Over"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Temperature Under:</Typography>
                        {data ? AlarmFomatter(data["Discharge_T_Under"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>:</Typography>
                        {data ? AlarmFomatter(data["OverCurrentDischarge"]) : "-"}
                    </div>
                </div>
                {/* Other Faults */}
                <div style={{
                    margin: 10,
                    padding: 5,
                    display: "flex",
                    flexDirection: 'column',
                    backgroundColor: '#565353',
                }}>
                    <Typography style={{
                        alignSelf: "center",
                        color: "#fff",
                        fontSize: 16,
                    }}>Other Faults</Typography>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Total Voltage Over:</Typography>
                        {data ? AlarmFomatter(data["VoltageOver"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Total Voltage Under:</Typography>
                        {data ? AlarmFomatter(data["VoltageUnder"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>SOC:</Typography>
                        {data ? AlarmFomatter(data["SOCLower"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Total Voltage Difference:</Typography>
                        {data ? AlarmFomatter(data["VoltageDiffExcess"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Capacity Inconsistency</Typography>
                        {data ? AlarmFomatter(data["CapacityInconsistency"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>MOS Temperature Fault</Typography>
                        {data ? AlarmFomatter(data["MOSTemperatureFault"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>ContactRod Temperature Fault</Typography>
                        {data ? AlarmFomatter(data["ContactRodTemperatureFault"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Relay or MOS Sticking</Typography>
                        {data ? AlarmFomatter(data["RelayOrMOSSticking"]) : "-"}
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Internal Fault</Typography>
                        {data ? AlarmFomatter(data["InternalFault"]) : "-"}
                    </div>
                </div>
            </div>
        </div>
    );
}

function AlarmFomatter(lvl) {
    return (<Typography style={{
        fontSize: 12,
        color: lvl == 1 ? "#ffc107" : lvl == 2 ? "red" : lvl == 3 ? "#e040fb" : "lime",
    }}>&nbsp;{lvl >= 1 && lvl <= 3 ? `level ${lvl} fault` : "OK"}</Typography>)
}
export default Alarm;