import { useEffect, useState, useContext} from 'react';
import { Modal, Tabs, TimePicker, Button, Form, Input, Select, Switch, Table, Row, Col, Popconfirm } from 'antd';
import { EditOutlined, ClockCircleOutlined  } from '@ant-design/icons';
import utils from '../../../utils';
import http from '../../../Http';
import moment from 'moment';
import InverterBurningList from './InverterBurningList';


//给保存不同数据类型的寄存器地址分类(共122个)
const isTime = '2102,2103,2104,2105';
const isSelect = '2100,2101,2110,30B1,30B2,30B3,30B5,5030,5059,5101,5113,6001';  //Select
const isSwitch = '2115,211C,2143,510E,5111,5112,5115,5117,5118';  //Switch
const isValueByThousand = '5031,5038,5039,503A,503B';  //0.001
const isValueByHundred = '211E,2120,2121,5002,5003,500A,500B,5121';  //0/01
const isValueByTen = '2113,2114,211D,5004,5005,500C,500D,5012,5013,5018,501A,5040,5041,5042,5043,5049,504A,504B,504C,5051,5052,5053,5055,5056,5057,5058,505E,505F,5060,5061';  //0.1
const isValueByEleven = '5054';  //0.11
const isValue = '2111,2112,2116,2117,2118,2119,211A,211B,211F,3000,3001,3002,3003,303E,30B9,30BA,5000,5001,5006,5007,5008,5009,500E,500F,5010,5011,5014,5015,5016,5017,5019,501B,502B,5033,5034,5035,5036,5037,503C,503D,503E,503F,504D,504E,504F,5050,505A,505B,505C,505D,5104,510F,5110,5114';  //1

function InvSetting(props) {
    const {SN} = props;
    const setTip = useContext(utils.tipContext);
    const {TabPane} = Tabs;
    const {Item} = Form;
    const {Option} = Select;

    //表单实例
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const [form] = Form.useForm();  //固件

    //逆变器参数已有的值
    const [initValues, setInitValues] = useState({});
    //每次设置成功后, 重新从后台取值保存到initValues中
    const [flag, setFlag] = useState(true);
    //标记输入框是否修改
    const [isEdits, setIsEdits] = useState({});

    //保存设置的值
    const[changedValues, setChangedValues] = useState({});

    //固件列表分页
    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    //固件查询参数
    const [params, setParams] = useState({
        InverterModel: '',
        FileName: '',
        Version: '',
    })

    //固件列表数据
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);

    //获取逆变器的参数的值
    useEffect(() => {
        if(!SN) return;
        form1.resetFields();
        form2.resetFields();
        form3.resetFields();
        form4.resetFields();
        let active = true;
        (async () => {
            let resp = await http.post("integrated-inverter/setting/list", {
                SerialNumber: SN
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (!active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load inverter record error"});
                
                return;
            }
            
            const values = {};
            let edits = {};
            resp.data.map(v => {
                const addr = v['RegisterAddress'];
                if(isTime.indexOf(addr) > -1) {
                    values[addr] = moment(Math.floor(v.Value/256).toString().padStart(2, '0') + ':' + (v.Value%256).toString().padStart(2, '0'), 'HH:mm');
                } else if(isSelect.indexOf(addr) > -1) {
                    values[addr] = v.Value;
                } else if(isSwitch.indexOf(addr) > -1) {
                    values[addr] = v.Value == 1 ? true : false;
                } else {
                    values[addr] = v.Value * v.Scale;
                }
                edits[addr] = false;
            })

            //'30B9', '30BA'两个地址表示一个值, 要单独处理
            if(values['30B9'] || values['30BA']) {
                values['30B9,30BA'] = 65536*values['30B9'] + values['30BA'];
                edits['30B9,30BA'] = false;
            }

            setInitValues(values)
            setIsEdits(edits);
            form1.resetFields();
            form2.resetFields();
            form3.resetFields();
            form4.resetFields();
            

        })();

        return () => {
            active = false;
        };

    }, [SN, flag]);


    //保存设置的地址和值
    const changeValues = (obj) => {
        let key = Object.keys(obj)[0];
        let value = Object.values(obj)[0];
        if(key == '30B9,30BA'){  //特殊寄存器
            obj = {'30B9': Math.floor(value/65536), '30BA': value%65536}
        } else if(isTime.indexOf(key) > -1) {  //时间
            obj = {[key]: parseInt(value.format('HH'))*256 + parseInt(value.format('mm'))}
        }else if(isValueByThousand.indexOf(key) > -1) {
            obj = {[key]: value*1000}
        } else if(isValueByHundred.indexOf(key) > -1) {
            obj = {[key]: value*100}
        } else if(isValueByTen.indexOf(key) > -1) {
            obj = {[key]: value*10}
        } else if(isSwitch.indexOf(key) > -1) {
            obj = {[key]: value ? 1 : 0}
        } else {
            obj = {[key]: parseInt(value)}
        }
        setChangedValues({...changedValues, ...obj})
        setIsEdits({...isEdits, ...{[key]: true}})
    }

    //将设置的值发送到服务器
    const finish = () => {
        //console.log('修改的值: ', changedValues)
        //console.log('修改的输入框: ', isEdits)
        if(Object.keys(changedValues).length == 0) {  //没有做任何修改
            return;
        }
        let active = true;
        (async () => {
            let resp = await http.postJSON("integrated-inverter/setting/request", {
                SerialNumber: SN,
                Setting: changedValues
            }).then(async r => {
                return await r.json();
            }).catch((e)=>{
                return null;
            });
            if(!active) {
                return
            }
            //设置失败
            if(resp == null || resp.code !== 200) { 
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "inverter setting error"});
                return;
            }
            //设置成功
            setTip({ open: true, severity: 'success', msg: 'inverter setting success' })
            setChangedValues({});
            setFlag(!flag);
        })();
        
    }

    //重置
    const reset = () => {
        setFlag(!flag);
        form1.resetFields();
        form2.resetFields();
        form3.resetFields();
        form4.resetFields();
        setChangedValues({});
    }

    //获取固件列表
    useEffect(() => {
        if(!SN) return;
        let active = true;
        (async () => {
            setLoading(true);
            let resp = await http.post("integrated-inverter/firmware/list2", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (!active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load inverter record error"});
                setRowTotal(0)
                setRows([]);
                return;
            }

            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [SN, pageNo, pageSize, params]);

    //搜索固件
    const handleSearch = () => {
        setPageNo(0);
        const ps = form.getFieldsValue();
        ps.InverterModel = ps.InverterModel || '';
        ps.FileName = ps.FileName || '';
        ps.Version = ps.Version || '';
        setParams(ps)
    }

    //执行烧录
    const burn = (r) => {
        console.log('SN: ', SN)
        console.log('r: ', r)
    }

    //关闭模态对话框时进行一些清理工作
    const handleClose = () => {
        setInitValues({});
        setChangedValues({});
    }

    return (
        <Tabs tabPosition="top">
            <TabPane tab="Grid Parameters" key="1">
                <Form 
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    form={form1}
                    initialValues={initValues}
                    onValuesChange={changeValues}
                    onFinish={finish}
                >
                    <Item
                        name="5101"
                        label="Regulation code"
                    >
                        <Select allowClear suffixIcon={isEdits['5101'] ? <EditOutlined /> : null}>
                            <Option value="1">AU(Australia AS/NZS 4777.2/3)</Option>
                            <Option value="2">DE(Germany VDE 0126-1-1/A1)</Option>
                            <Option value="3">TW(Taiwan TW GRID)</Option>
                            <Option value="4">DE(Germany VED-AR-N 4105)</Option>
                            <Option value="5">JP(Japan JETGR0002-1-2.0)</Option>
                            <Option value="6">IT(Italy CEI 0-21)</Option>
                            <Option value="7">SE(Sweden SWEDEN Grid)</Option>
                            <Option value="8">UK(British G83/1-1, G59)</Option>
                            <Option value="9">UL(USA UL)</Option>
                            <Option value="10">TL(Thailand PEA)</Option>
                            <Option value="11">SE(Sweden SWEDEN GRID) 2007</Option>
                            <Option value="12">NL(Netherlands NETHERLANDS Grid)</Option>
                            <Option value="13">TL(Thailand  MEA)</Option>
                            <Option value="14">CN(China NB/T 32004)</Option>
                            <Option value="15">IND(India IEC61727)</Option>
                            <Option value="16">AU(Australia AS/NZS 4777.2:2015)</Option>
                            <Option value="17">NZ(Australia AS/NZS 4777.2:2015)</Option>
                            <Option value="18">MX(Mexico IEEE1547)</Option>
                            <Option value="19">UA(Ukraine VDE-AR-N 4105.)</Option>
                            <Option value="20">TW(Taiwan CNS 15382:2017)</Option>
                            <Option value="21">DE(Germany VDE0126-1-1/08.13)</Option>
                            <Option value="22">BR(Brazil ABNT NBR16149 2013)</Option>
                            <Option value="23">CS(Czech EN 50160)</Option>
                            <Option value="24">UK(British G59)</Option>
                            <Option value="25">PT(Portugal EN50438)</Option>
                            <Option value="26">ES(Spain RD1699/RD413)</Option>
                            <Option value="27">ES(Spain RD661/RD413/PO12.3)</Option>
                            <Option value="28">IT(Italy CEI 0-21 ACEA)</Option>
                            <Option value="29">DE(Germany VDE-AR-N 4105:2018)</Option>
                            <Option value="65535">Not defined</Option>
                        </Select>
                    </Item>
                    <Item
                        name="5000"
                        label="First Connect Delay Time(s)"
                    >
                        <Input placeholder="[10, 600]" suffix={isEdits['5000'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5001"
                        label="Reconnect Delay Time(s)"
                    >
                        <Input placeholder="[10, 900]" suffix={isEdits['5001'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5002"
                        label="Frequency High Loss Level_1(Hz)"
                    >
                        <Input placeholder="[50, 60]" suffix={isEdits['5002'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5003"
                        label="Frequency Low Loss Level_1(Hz)"
                    >
                        <Input placeholder="[40, 50]" suffix={isEdits['5003'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5004"
                        label="Voltage High Loss Level_1(V)"
                    >
                        <Input placeholder="[230, 322]" suffix={isEdits['5004'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5005"
                        label="Voltage Low Loss Level_1(V)"
                    >
                        <Input placeholder="[23, 230]" suffix={isEdits['5005'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5006"
                        label="Frequency High Loss Time Level_1(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5006'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5007"
                        label="Frequency Low Loss Time Level_1(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5007'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5008"
                        label="Voltage High Loss Time Level_1(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5008'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5009"
                        label="Voltage Low Loss Time Level_1(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5009'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5010"
                        label="Frequency High Loss Level_2(Hz)"
                    >
                        <Input placeholder="[50, 60]" suffix={isEdits['5010'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5011"
                        label="Frequency Low Loss Level_2(Hz)"
                    >
                        <Input placeholder="[40, 50]" suffix={isEdits['5011'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5012"
                        label="Voltage High Loss Level_2(V)"
                    >
                        <Input placeholder="[230, 322]" suffix={isEdits['5012'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5013"
                        label="Voltage Low Loss Level_2(V)"
                    >
                        <Input placeholder="[23, 230]" suffix={isEdits['5013'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5014"
                        label="Frequency High Loss Time Level_2(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5014'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5015"
                        label="Frequency Low Loss Time Level_2(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5015'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5016"
                        label="Voltage High Loss Time Level_2(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5016'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5017"
                        label="Voltage Low Loss Time Level_2(ms)"
                    >
                        <Input placeholder="[0, 60000]" suffix={isEdits['5017'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        wrapperCol={{span: 16, offset: 10}}    
                    >
                        <Button type="primary" htmlType="reset" onClick={reset}>Reset</Button>
                        <div style={{width: '50px', display: 'inline-block'}}></div>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Item>
                </Form>
            </TabPane>
            <TabPane tab="Feature Parameters" key="2">
                <Form 
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    form={form2}
                    initialValues={initValues}
                    onValuesChange={changeValues}
                    onFinish={finish}
                >
                    <Item
                        name="5112"
                        label="Low Voltage Through"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['5112'] ? <EditOutlined /> : null} 
                            unCheckedChildren={isEdits['5112'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="510E"
                        label="Island Detection"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['510E'] ? <EditOutlined /> : null} 
                            unCheckedChildren={isEdits['510E'] ? <EditOutlined /> : null}/>
                    </Item>
                    <Item
                        name="5117"
                        label="Isolation Detection"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['5117'] ? <EditOutlined /> : null} 
                            unCheckedChildren={isEdits['5117'] ? <EditOutlined /> : null}/>
                    </Item>
                    <Item
                        name="5118"
                        label="Leakage Current Detection(GFCI)"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['5118'] ? <EditOutlined /> : null} 
                            unCheckedChildren={isEdits['5118'] ? <EditOutlined /> : null}/>
                    </Item>
                    <Item
                        name="5114"
                        label="Reactive Power(%)"
                    >
                        <Input placeholder="[-110, 110]" suffix={isEdits['5114'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5104"
                        label="Derated Power(%)"
                    >
                        <Input placeholder="[0, 110]" suffix={isEdits['5104'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5031"
                        label="Power Factor"
                    >
                        <Input placeholder="[-1000, -800], [800, 1000]" suffix={isEdits['5031'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5113"
                        label="Insulation Type"
                    >
                        <Select allowClear suffixIcon={isEdits['5113'] ? <EditOutlined /> : null}>
                            <Option value="0">with ground line, no transformer</Option>
                            <Option value="1">no ground line, with transformer</Option>
                            <Option value="2">with ground line, with transformer</Option>
                        </Select>
                    </Item>
                    <Item
                        name="501B"
                        label="Insulation Impedance(KΩ)"
                    >
                        <Input placeholder="[10, 5000]" suffix={isEdits['501B'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5110"
                        label="Leakage Current Point(mA)"
                    >
                        <Input placeholder="[10, 300]" suffix={isEdits['5110'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="510F"
                        label="Unbalanced Voltage Point(%)"
                    >
                        <Input placeholder="[1, 50]" suffix={isEdits['510F'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="5018"
                        label="Moving Average Voltage Limit(V)"
                    >
                        <Input placeholder="[230, 999]" suffix={isEdits['5018'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        wrapperCol={{span: 16, offset: 10}}    
                    >
                        <Button type="primary" htmlType="reset" onClick={reset}>Reset</Button>
                        <div style={{width: '50px', display: 'inline-block'}}></div>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Item>
                </Form>
            </TabPane>
            <TabPane tab="Power Limit" key="3">
                <Form 
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    form={form3}
                    initialValues={initValues}
                    onValuesChange={changeValues}
                    onFinish={finish}
                >
                    <Item
                        name="30B3"
                        label="Power Control"
                    >
                        <Select allowClear suffixIcon={isEdits['30B3'] ? <EditOutlined /> : null}>
                            <Option value="0">Disable</Option>
                            <Option value="1">External device</Option>
                            <Option value="2">PVI external CT sensor</Option>
                            <Option value="3">Digital meter device</Option>
                        </Select>
                    </Item>
                    <Item
                        name="30B1"
                        label="Meter Type"
                    >
                        <Select allowClear suffixIcon={isEdits['30B1'] ? <EditOutlined /> : null}>
                            <Option value="0">Unknown</Option>
                            <Option value="1">Gavazzi/EM340DINAV23XS1X</Option>
                            <Option value="2">CHINT/DTSU666</Option>
                            <Option value="3">Acrel/DTSF1352-C</Option>
                            <Option value="4">Lovato/DMG210</Option>
                            <Option value="5">G2000</Option>
                            <Option value="6">Gavazzi/ET112</Option>
                            <Option value="7">Kostal /KSEM</Option>
                            <Option value="8">CHINT/DDSU666</Option>
                        </Select>
                    </Item>
                    <Item
                        name="30B5"
                        label="Meter Location"
                    >
                        <Select allowClear suffixIcon={isEdits['30B5'] ? <EditOutlined /> : null}>
                            <Option value="0">Meter on Grid</Option>
                            <Option value="1">Meter on Load</Option>
                        </Select>
                    </Item>
                    <Item
                        name="30B2"
                        label="Power Flow Direction"
                    >
                        <Select allowClear suffixIcon={isEdits['30B2'] ? <EditOutlined /> : null}>
                            <Option value="0">From grid to inverter</Option>
                            <Option value="1">From inverter to gird</Option>
                        </Select>
                    </Item>
                    <Item
                        name="30B9,30BA"
                        label="Maximum feed in grid power(W)"
                    >
                        <Input placeholder="[0, 200000]" suffix={isEdits['30B9,30BA'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        wrapperCol={{span: 16, offset: 10}}    
                    >
                        <Button type="primary" htmlType="reset" onClick={reset}>Reset</Button>
                        <div style={{width: '50px', display: 'inline-block'}}></div>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Item>
                </Form>
            </TabPane>
            <TabPane tab="Hybird Setting" key="4">
                <Form 
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    form={form4}
                    initialValues={initValues}
                    onValuesChange={changeValues}
                    onFinish={finish}
                >
                    <Item
                        name="2100"
                        label="Hybird work mode"
                    >
                        <Select allowClear suffixIcon={isEdits['2100'] ? <EditOutlined /> : null}>
                            <Option value="0">Self Used Mode</Option>
                            <Option value="1">Feed-in Priority Mode</Option>
                            <Option value="2">Time-Based Control</Option>
                            <Option value="3">Back-up Mode</Option>
                            <Option value="4">Battery Discharge mode</Option>
                        </Select>
                    </Item>
                    <Item
                        name="2101"
                        label="Charge frequence"
                    >
                        <Select allowClear suffixIcon={isEdits['2101'] ? <EditOutlined /> : null}>
                            <Option value="0">Once</Option>
                            <Option value="1">Everyday</Option>
                        </Select>
                    </Item>
                    <Item
                        name="2102"
                        label="Charge start time"
                    >
                        <TimePicker format='HH:mm' suffixIcon={isEdits['2102'] ? <EditOutlined /> : <ClockCircleOutlined />} />
                    </Item>
                    <Item
                        name="2103"
                        label="Charge end time"
                    >
                        <TimePicker format='HH:mm' suffixIcon={isEdits['2103'] ? <EditOutlined /> : <ClockCircleOutlined />} />
                    </Item>
                    <Item
                        name="2104"
                        label="Discharge start time"
                    >
                        <TimePicker format='HH:mm' suffixIcon={isEdits['2104'] ? <EditOutlined /> : <ClockCircleOutlined />} />
                    </Item>
                    <Item
                        name="2105"
                        label="Discharge end time"
                    >
                        <TimePicker format='HH:mm' suffixIcon={isEdits['2105'] ? <EditOutlined /> : <ClockCircleOutlined />} />
                    </Item>
                    <Item
                        name="2115"
                        label="Grid Charge"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['2115'] ? <EditOutlined /> : null} 
                            unCheckedChildren={isEdits['2115'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="2116"
                        label="Maximum grid charger power(W)"
                    >
                        <Input suffix={isEdits['2116'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="2117"
                        label="Capacity of grid charger end(%)"
                    >
                        <Input suffix={isEdits['2117'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="2118"
                        label="Maximum charger power(W)"
                    >
                        <Input suffix={isEdits['2118'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="2119"
                        label="Capacity of charger end(%)"
                    >
                        <Input suffix={isEdits['2119'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="211A"
                        label="Maximum discharger power(W)"
                    >
                        <Input suffix={isEdits['211A'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="211B"
                        label="Capacity of discharger end(%)"
                    >
                        <Input suffix={isEdits['211B'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="211C"
                        label="Off-grid mode"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['211C'] ? <EditOutlined /> : null} 
                            unCheckedChildren={isEdits['211C'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="211D"
                        label="Rated output voltage(V)"
                    >
                        <Input suffix={isEdits['211D'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="211E"
                        label="Rated output frequency(Hz)"
                    >
                        <Input suffix={isEdits['211E'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="211F"
                        label="Off-gird start-up battery capacity(%)"
                    >
                        <Input suffix={isEdits['211F'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        name="2143"
                        label="Parallel mode"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={isEdits['2143'] ? <EditOutlined /> : null} 
                            unCheckedChildren={isEdits['2143'] ? <EditOutlined /> : null} />
                    </Item>
                    <Item
                        wrapperCol={{span: 16, offset: 10}}    
                    >
                        <Button type="primary" htmlType="reset" onClick={reset}>Reset</Button>
                        <div style={{width: '50px', display: 'inline-block'}}></div>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Item>
                </Form>
            </TabPane>
            <TabPane tab="Remote Update" key="5">
                <InverterBurningList />
            </TabPane>
        </Tabs>
    )
}

export default InvSetting;