const setting_metas = [{
    ReadID: 0x3001,
    WriteID: 0x2001,
    Title: "Base",
    Fields: [{
        Name: "Capacity",
        Title: "Capacity(Ah)",
        DataType: "uint16",
        ValueRange: [0, 0x7fff]
    },
    {
        Name: "SOC",
        Title: "Remain Capacity(Ah)",
        DataType: "uint16",
        ValueRange: [0, 0x7fff]
    },
    {
        Name: "CurrentRange",
        Title: "CurrentRange(A)",
        DataType: "uint16",
        ValueRange: [-0x7fff, 0x7fff],
    },
    {
        Name: "BMUTotal",
        Title: "BMUTotal",
        DataType: "byte",
        ValueRange: [0, 16]
    },
    {
        Name: "CollectionBoardsPerBMU",
        Title: "CollectionBoardsPerBMU",
        DataType: "byte",
        ValueRange: [0, 16]
    },
    {
        Name: "CollectionVoltages1",
        Title: "CollectionVoltages1",
        DataType: "byte",
        ValueRange: [0, 256]
    },
    {
        Name: "CollectionVoltages2",
        Title: "CollectionVoltages2",
        DataType: "byte",
        ValueRange: [0, 256]
    },
    {
        Name: "CollectionVoltages3",
        Title: "CollectionVoltages3",
        DataType: "byte",
        ValueRange: [0, 256]
    },
    {
        Name: "CollectionTemps1",
        Title: "CollectionTemps1",
        DataType: "byte",
        ValueRange: [0, 256]
    },
    {
        Name: "CollectionTemps2",
        Title: "CollectionTemps2",
        DataType: "byte",
        ValueRange: [0, 256]
    },
    {
        Name: "CollectionTemps3",
        Title: "CollectionTemps3",
        DataType: "byte",
        ValueRange: [0, 256]
    },]
}, {
    ReadID: 0x3002,
    WriteID: 0x2002,
    Title: "LinkAndAddress",
    Fields: [{
        Name: "LinkType",
        Title: "LinkType",
        DataType: "byte",
        ValueRange: [{
            Value: 1,
            Title: 'single',
        }, {
            Value: 2,
            Title: 'parallel',
        }, {
            Value: 3,
            Title: 'in-series',
        }, {
            Value: 4,
            Title: 'bms-control',
        }]
    }, {
        Name: "Address",
        Title: "Address",
        DataType: "byte",
        ValueRange: [0, 0xff]
    },],
}, {
    ReadID: 0x3003,
    WriteID: 0x2003,
    Title: "Charge/DischargeCurrent",
    Fields: [{
        Name: "MaxChargeCurrent",
        Title: "MaxChargeCurrent(100mA)",
        DataType: "uint16",
        ValueRange: [-0x7fff, 0x7fff],
        Offset: -0x7FFF
    }, {
        Name: "MaxDischargeCurrent",
        Title: "MaxDischargeCurrent(100mA)",
        DataType: "uint16",
        ValueRange: [-0x7fff, 0x7fff],
        Offset: -0x7FFF
    },],
}, {
    ReadID: 0x3004,
    WriteID: 0x2004,
    Title: "RelayControl",
    Fields: [{
        Name: "RelayStatuses",
        Title: "Route",
        DataType: "[]byte",
        ValueRange: [{ Title: 'auto', Value: 0 }, { Title: 'turn-on', Value: 1 }, { Title: 'turn-off', Value: 2 }, { Title: 'reserved', Value: 3 }],
    },],
}, {
    ReadID: 0x3006,
    WriteID: 0x2006,
    Title: "Balance",
    Fields: [{
        Name: "BalanceTime",
        Title: "Opportunity",
        DataType: "byte",
        ValueRange: [{
            Title: 'charge',
            Value: 1,
        }, {
            Title: 'discharge',
            Value: 2,
        }, {
            Title: 'static',
            Value: 3,
        }, {
            Title: 'charge or static',
            Value: 4,
        }, {
            Title: 'charge or discharge',
            Value: 5,
        }, {
            Title: 'all above',
            Value: 6,
        }]
    },
    {
        Name: "BalanceMaxDuration",
        Title: "BalanceMaxDuration(S)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "BalanceStopTime",
        Title: "BalanceStopTime(S)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "BalanceEnableMinVoltage",
        Title: "BalanceEnableMinVoltage(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "BalanceEnableMinVoltageDiff",
        Title: "BalanceEnableMinVoltageDiff(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "BalanceDisableMaxVoltage",
        Title: "BalanceDisableMaxVoltage(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "BalanceDisableMaxVoltageDiff",
        Title: "BalanceDisableMaxVoltageDiff(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "EnableBalance",
        Title: "EnableBalance",
        DataType: "uint16",
        ValueRange: [{
            Title: 'bms-control',
            Value: 0,
        }, {
            Title: 'turn-on',
            Value: 1,
        }, {
            Title: 'turn-off',
            Value: 2,
        }]
    },],
}, {
    ReadID: 0x3010,
    WriteID: 0x2010,
    Title: "CellVoltageUnder",
    Fields: [{
        Name: "CellVoltageUnder1",
        Title: "CellVoltageUnder1(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnderRelease1",
        Title: "CellVoltageUnderRelease1(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnder2",
        Title: "CellVoltageUnder2(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnderRelease2",
        Title: "CellVoltageUnderRelease2(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnder3",
        Title: "CellVoltageUnder3(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnderRelease3",
        Title: "CellVoltageUnderRelease3(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnderDelay1",
        Title: "CellVoltageUnderDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnderDelayRelease1",
        Title: "CellVoltageUnderDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnderDelay2",
        Title: "CellVoltageUnderDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnderDelayRelease2",
        Title: "CellVoltageUnderDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnderDelay3",
        Title: "CellVoltageUnderDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageUnderDelayRelease3",
        Title: "CellVoltageUnderDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3011,
    WriteID: 0x2011,
    Title: "CellVoltageOver",
    Fields: [{
        Name: "CellVoltageOver1",
        Title: "CellVoltageOver1(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOverRelease1",
        Title: "CellVoltageOverRelease1(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOver2",
        Title: "CellVoltageOver2(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOverRelease2",
        Title: "CellVoltageOverRelease2(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOver3",
        Title: "CellVoltageOver3(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOverRelease3",
        Title: "CellVoltageOverRelease3(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOverDelay1",
        Title: "CellVoltageOverDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOverDelayRelease1",
        Title: "CellVoltageOverDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOverDelay2",
        Title: "CellVoltageOverDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOverDelayRelease2",
        Title: "CellVoltageOverDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOverDelay3",
        Title: "CellVoltageOverDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageOverDelayRelease3",
        Title: "CellVoltageOverDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3012,
    WriteID: 0x2012,
    Title: "CellVoltageDiffOver",
    Fields: [{
        Name: "CellVoltageDiffOver1",
        Title: "CellVoltageDiffOver1(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOverRelease1",
        Title: "CellVoltageDiffOverRelease1(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOver2",
        Title: "CellVoltageDiffOver2(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOverRelease2",
        Title: "CellVoltageDiffOverRelease2(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOver3",
        Title: "CellVoltageDiffOver3(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOverRelease3",
        Title: "CellVoltageDiffOverRelease3(mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOverDelay1",
        Title: "CellVoltageDiffOverDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOverDelayRelease1",
        Title: "CellVoltageDiffOverDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOverDelay2",
        Title: "CellVoltageDiffOverDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOverDelayRelease2",
        Title: "CellVoltageDiffOverDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOverDelay3",
        Title: "CellVoltageDiffOverDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageDiffOverDelayRelease3",
        Title: "CellVoltageDiffOverDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3013,
    WriteID: 0x2013,
    Title: "TotalVoltageUnder",
    Fields: [{
        Name: "VoltageDown1",
        Title: "VoltageDown1(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageDownRelease1",
        Title: "VoltageDownRelease1(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageDown2",
        Title: "VoltageDown2(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageDownRelease2",
        Title: "VoltageDownRelease2(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageDown3",
        Title: "VoltageDown3(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageDownRelease3",
        Title: "VoltageDownRelease3(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageDownDelay1",
        Title: "VoltageDownDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageDownDelayRelease1",
        Title: "VoltageDownDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageDownDelay2",
        Title: "VoltageDownDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageDownDelayRelease2",
        Title: "VoltageDownDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageDownDelay3",
        Title: "VoltageDownDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    ],
}, {
    ReadID: 0x3014,
    WriteID: 0x2014,
    Title: "TotalVoltageOver",
    Fields: [{
        Name: "VoltageOver1",
        Title: "VoltageOver1(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageOverRelease1",
        Title: "VoltageOverRelease1(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageOver2",
        Title: "VoltageOver2(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageOverRelease2",
        Title: "VoltageOverRelease2(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageOver3",
        Title: "VoltageOver3(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageOverRelease3",
        Title: "VoltageOverRelease3(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageOverDelay1",
        Title: "VoltageOverDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageOverDelayRelease1",
        Title: "VoltageOverDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageOverDelay2",
        Title: "VoltageOverDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageOverDelayRelease2",
        Title: "VoltageOverDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "VoltageOverDelay3",
        Title: "VoltageOverDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    ],
}, {
    ReadID: 0x3015,
    WriteID: 0x2015,
    Title: "ChargeTemperatureUnder",
    Fields: [{
        Name: "ChargingTempDown1",
        Title: "ChargeTemperatureDown1(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempDownRelease1",
        Title: "ChargeTemperatureDownRelease1(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempDown2",
        Title: "ChargeTemperatureDown2(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempDownRelease2",
        Title: "ChargeTemperatureDownRelease2(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempDown3",
        Title: "ChargeTemperatureDown3(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempDownRelease3",
        Title: "ChargeTemperatureDownRelease3(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempDownDelay1",
        Title: "ChargeTemperatureDownDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingTempDownDelayRelease1",
        Title: "ChargeTemperatureDownDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingTempDownDelay2",
        Title: "ChargeTemperatureDownDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingTempDownDelayRelease2",
        Title: "ChargeTemperatureDownDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingTempDownDelay3",
        Title: "ChargeTemperatureDownDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingTempDownDelayRelease3",
        Title: "ChargeTemperatureDownDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3016,
    WriteID: 0x2016,
    Title: "ChargeTemperatureOver",
    Fields: [{
        Name: "ChargingTempOver1",
        Title: "ChargeTemperatureOver1(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempOverRelease1",
        Title: "ChargeTemperatureOverRelease1(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempOver2",
        Title: "ChargeTemperatureOver2(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempOverRelease2",
        Title: "ChargeTemperatureOverRelease2(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempOver3",
        Title: "ChargeTemperatureOver3(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempOverRelease3",
        Title: "ChargeTemperatureOverRelease3(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "ChargingTempOverDelay1",
        Title: "ChargeTemperatureOverDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingTempOverDelayRelease1",
        Title: "ChargeTemperatureOverDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingTempOverDelay2",
        Title: "ChargeTemperatureOverDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingTempOverDelayRelease2",
        Title: "ChargeTemperatureOverDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingTempOverDelay3",
        Title: "ChargeTemperatureOverDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingTempOverDelayRelease3",
        Title: "ChargeTemperatureOverDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3017,
    WriteID: 0x2017,
    Title: "TemperatureDiffOver",
    Fields: [{
        Name: "TempDiffOver1",
        Title: "ChargeTemperatureDiffOver1(℃)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOverRelease1",
        Title: "ChargeTemperatureDiffOverRelease1(℃)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOver2",
        Title: "ChargeTemperatureDiffOver2(℃)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOverRelease2",
        Title: "ChargeTemperatureDiffOverRelease2(℃)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOver3",
        Title: "ChargeTemperatureDiffOver3(℃)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOverRelease3",
        Title: "ChargeTemperatureDiffOverRelease3(℃)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOverDelay1",
        Title: "ChargeTemperatureDiffOverDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOverDelayRelease1",
        Title: "ChargeTemperatureDiffOverDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOverDelay2",
        Title: "ChargeTemperatureDiffOverDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOverDelayRelease2",
        Title: "ChargeTemperatureDiffOverDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOverDelay3",
        Title: "ChargeTemperatureDiffOverDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempDiffOverDelayRelease3",
        Title: "ChargeTemperatureDiffOverDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3018,
    WriteID: 0x2018,
    Title: "DischargeTemperatureUnder",
    Fields: [{
        Name: "DischargingTempDown1",
        Title: "DischargeTemperatureDown1(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempDownRelease1",
        Title: "DischargeTemperatureDownRelease1(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempDown2",
        Title: "DischargeTemperatureDown2(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempDownRelease2",
        Title: "DischargeTemperatureDownRelease2(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempDown3",
        Title: "DischargeTemperatureDown3(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempDownRelease3",
        Title: "DischargeTemperatureDownRelease3(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempDownDelay1",
        Title: "DischargeTemperatureDownDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingTempDownDelayRelease1",
        Title: "DischargeTemperatureDownDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingTempDownDelay2",
        Title: "DischargeTemperatureDownDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingTempDownDelayRelease2",
        Title: "DischargeTemperatureDownDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingTempDownDelay3",
        Title: "DischargeTemperatureDownDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingTempDownDelayRelease3",
        Title: "DischargeTemperatureDownDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3019,
    WriteID: 0x2019,
    Title: "DischargeTemperatureOver",
    Fields: [{
        Name: "DischargingTempOver1",
        Title: "DischargeTemperatureOver1(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempOverRelease1",
        Title: "DischargeTemperatureOverRelease1(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempOver2",
        Title: "DischargeTemperatureOver2(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempOverRelease2",
        Title: "DischargeTemperatureOverRelease2(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempOver3",
        Title: "DischargeTemperatureOver3(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempOverRelease3",
        Title: "DischargeTemperatureOverRelease3(℃)",
        DataType: "uint16",
        ValueRange: [-50, 100],
        Offset: -50,
    },
    {
        Name: "DischargingTempOverDelay1",
        Title: "DischargeTemperatureOverDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingTempOverDelayRelease1",
        Title: "DischargeTemperatureOverDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingTempOverDelay2",
        Title: "DischargeTemperatureOverDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingTempOverDelayRelease2",
        Title: "DischargeTemperatureOverDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingTempOverDelay3",
        Title: "DischargeTemperatureOverDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingTempOverDelayRelease3",
        Title: "DischargeTemperatureOverDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x301a,
    WriteID: 0x201a,
    Title: "ChargeCurrentOver",
    Fields: [{
        Name: "ChargingCurrentOver1",
        Title: "ChargingCurrentOver1(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOverRelease1",
        Title: "ChargingCurrentOverRelease1(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOver2",
        Title: "ChargingCurrentOver2(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOverRelease2",
        Title: "ChargingCurrentOverRelease2(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOver3",
        Title: "ChargingCurrentOver3(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOverRelease3",
        Title: "ChargingCurrentOverRelease3(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOverDelay1",
        Title: "ChargingCurrentOverDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOverDelayRelease1",
        Title: "ChargingCurrentOverDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOverDelay2",
        Title: "ChargingCurrentOverDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOverDelayRelease2",
        Title: "ChargingCurrentOverDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOverDelay3",
        Title: "ChargingCurrentOverDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ChargingCurrentOverDelayRelease3",
        Title: "ChargingCurrentOverDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x301b,
    WriteID: 0x201b,
    Title: "DischargeCurrentOver",
    Fields: [{
        Name: "DischargingCurrentOver1",
        Title: "DischargeCurrentOver1(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOverRelease1",
        Title: "DischargeCurrentOverRelease1(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOver2",
        Title: "DischargeCurrentOver2(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOverRelease2",
        Title: "DischargeCurrentOverRelease2(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOver3",
        Title: "DischargeCurrentOver3(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOverRelease3",
        Title: "DischargeCurrentOverRelease3(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOverDelay1",
        Title: "DischargeCurrentOverDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOverDelayRelease1",
        Title: "DischargeCurrentOverDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOverDelay2",
        Title: "DischargeCurrentOverDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOverDelayRelease2",
        Title: "DischargeCurrentOverDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOverDelay3",
        Title: "DischargeCurrentOverDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargingCurrentOverDelayRelease3",
        Title: "DischargeCurrentOverDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x301c,
    WriteID: 0x201c,
    Title: "SOCUnder",
    Fields: [{
        Name: "SOCDown1",
        Title: "SOCDown1(%)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDownRelease1",
        Title: "SOCDownRelease1(%)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDown2",
        Title: "SOCDown2(%)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDownRelease2",
        Title: "SOCDownRelease2(%)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDown3",
        Title: "SOCDown3(%)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDownRelease3",
        Title: "SOCDownRelease3(%)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDownDelay1",
        Title: "SOCDownDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDownDelayRelease1",
        Title: "SOCDownDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDownDelay2",
        Title: "SOCDownDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDownDelayRelease2",
        Title: "SOCDownDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDownDelay3",
        Title: "SOCDownDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SOCDownDelayRelease3",
        Title: "SOCDownDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x301d,
    WriteID: 0x201d,
    Title: "VoltageCollectionLine",
    Fields: [{
        Name: "CLFVoltage1",
        Title: "CLFVoltage1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CLFVoltageRelease1",
        Title: "CLFVoltageRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CLFVoltage2",
        Title: "CLFVoltage2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CLFVoltageRelease2",
        Title: "CLFVoltageRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CLFVoltage3",
        Title: "CLFVoltage3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CLFVoltageRelease3",
        Title: "CLFVoltageRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x301e,
    WriteID: 0x201e,
    Title: "TemperatureCollectionLine",
    Fields: [{
        Name: "CLFTemp1",
        Title: "CLFTemperature1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CLFTempRelease1",
        Title: "CLFTemperatureRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CLFTemp2",
        Title: "CLFTemperature2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CLFTempRelease2",
        Title: "CLFTemperatureRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CLFTemp3",
        Title: "CLFTemp3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CLFTempRelease3",
        Title: "CLFTemperatureRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x301f,
    WriteID: 0x201f,
    Title: "BMUCommunication",
    Fields: [{
        Name: "CommunicationFault1",
        Title: "CommunicationFault1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CommunicationFaultRelease1",
        Title: "CommunicationFaultRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CommunicationFault2",
        Title: "CommunicationFault2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CommunicationFaultRelease2",
        Title: "CommunicationFaultRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CommunicationFault3",
        Title: "CommunicationFault3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CommunicationFaultRelease3",
        Title: "CommunicationFaultRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3020,
    WriteID: 0x2020,
    Title: "InsulationUnder",
    Fields: [{
        Name: "InsulationDown1",
        Title: "InsulationDown1(1KΩ)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDownRelease1",
        Title: "InsulationDownRelease1(1KΩ)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDown2",
        Title: "InsulationDown2(1KΩ)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDownRelease2",
        Title: "InsulationDownRelease2(1KΩ)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDown3",
        Title: "InsulationDown3(1KΩ)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDownRelease3",
        Title: "InsulationDownRelease3(1KΩ)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDownDelay1",
        Title: "InsulationDownDelay1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDownDelayRelease1",
        Title: "InsulationDownDelayRelease1(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDownDelay2",
        Title: "InsulationDownDelay2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDownDelayRelease2",
        Title: "InsulationDownDelayRelease2(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDownDelay3",
        Title: "InsulationDownDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "InsulationDownDelayRelease3",
        Title: "InsulationDownDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3021,
    WriteID: 0x2021,
    Title: "CountDown",
    Fields: [{
        Name: "ChangingProtectEscape",
        Title: "ChangeProtectCountDown(S)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SleepingEscape",
        Title: "SleepingCountDown(S)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3022,
    WriteID: 0x2022,
    Title: "ForceDormancy",
    Fields: [{
        Name: "SleepingEscape",
        Title: "SleepingCountDown(S)",
        DataType: "byte",
        ValueRange: [{
            Title: 'force-dormancy',
            Value: 1,
        }, {
            Title: 'force-resetting',
            Value: 2,
        },]
    }],
}, {
    ReadID: 0x3023,
    WriteID: 0x2023,
    Title: "Cyc",
    Fields: [{
        Name: "SummaryCyc",
        Title: "SummaryCyc(S)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "AlarmCyc",
        Title: "AlarmCyc(S)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "RelayCyc",
        Title: "RelayCyc(S)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "SwitchCyc",
        Title: "SwitchCyc(S)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CellVoltageCyc",
        Title: "CellVoltageCyc(S)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TempCyc",
        Title: "TempCyc(S)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "TopologyCyc",
        Title: "TopologyCyc(S)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x3024,
    Title: "Hardware Version",
    TitleCol: 6,
    WrapperCol: 14,
    Fields: [{
        Name: "Version",
        Title: "Version",
        DataType: "string",
        ValueRange: []
    }],
}, {
    ReadID: 0x3025,
    Title: "Firmware Version",
    TitleCol: 6,
    WrapperCol: 14,
    Fields: [{
        Name: "VersionType",
        Title: "Version Type",
        DataType: "byte",
        ValueRange: [{
            Title: 'App',
            Value: 1,
        }, {
            Title: 'Boot',
            Value: 2,
        }, {
            Title: 'Lib',
            Value: 3,
        }, {
            Title: 'HDL',
            Value: 4,
        }, {
            Title: 'MCU',
            Value: 5,
        }, {
            Title: 'Inverter',
            Value: 6,
        }]
    }, {
        Name: "Version",
        Title: "Version",
        DataType: "string",
        ValueRange: []
    }],
}, {
    ReadID: 0x3026,
    WriteID: 0x2026,
    Title: "Interver&Battery",
    Fields: [{
        Name: "InveterType",
        Title: "Inverter",
        DataType: "byte",
        ValueRange: [{
            Title: "1: Aobo",
            Value: 1,
        }, {
            Title: "2: Sermatec High Voltage",
            Value: 2,
        }, {
            Title: "3: GoodWe High Voltage",
            Value: 3,
        }, {
            Title: "4: Growatt High Voltage",
            Value: 4,
        }, {
            Title: "5: Aobo_SE",
            Value: 5,
        }, {
            Title: "6: Solis High Voltage",
            Value: 6,
        }, {
            Title: "7: Megarevo Mega",
            Value: 7,
        }, {
            Title: "8: Megarevo High Voltage",
            Value: 8,
        }, {
            Title: "9: SOFAR High Voltage",
            Value: 9,
        }, {
            Title: "10: Victron",
            Value: 10,
        }, {
            Title: "11: Must Modbus",
            Value: 11,
        }, {
            Title: "12: Phocos Modbus",
            Value: 12,
        }, {
            Title: "13: Voltronic Modbus",
            Value: 13,
        }, {
            Title: "14: Pace Modbus",
            Value: 14,
        }, {
            Title: "15: SY UPS Modbu",
            Value: 15,
        }, {
            Title: "16: Aobo Modbus",
            Value: 16,
        }, {
            Title: "17: Invt High Voltage",
            Value: 17,
        }, {
            Title: "18: Livotek",
            Value: 18,
        }, {
            Title: "19: Solx",
            Value: 19,
        }, {
            Title: "20: SAN JING",
            Value: 20,
        }, {
            Title: "21: AISWEI_HV",
            Value: 21,
        }, {
            Title: "22: Atess",
            Value: 22,
        }]
    }, {
        Name: "BatteryManufacturer",
        Title: "Battery",
        DataType: "byte",
        ValueRange: [{
            Title: "1: AB",
            Value: 1,
        }, {
            Title: "2: CFEG",
            Value: 2,
        }, {
            Title: "3: TB",
            Value: 3,
        }, {
            Title: "4: LG",
            Value: 4,
        }, {
            Title: "5: AISWEI",
            Value: 5,
        }]
    }],
}, {
    ReadID: 0x3027,
    WriteID: 0x2027,
    Title: "AEF",
    Fields: [{
        Name: "AEF1_1",
        Title: "AEF1_01",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_2",
        Title: "AEF1_02",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_3",
        Title: "AEF1_03",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_4",
        Title: "AEF1_04",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_5",
        Title: "AEF1_05",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_6",
        Title: "AEF1_06",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_7",
        Title: "AEF1_07",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_8",
        Title: "AEF1_08",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_9",
        Title: "AEF1_09",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_10",
        Title: "AEF1_10",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_11",
        Title: "AEF1_11",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_12",
        Title: "AEF1_12",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_13",
        Title: "AEF1_13",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_14",
        Title: "AEF1_14",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_15",
        Title: "AEF1_15",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_16",
        Title: "AEF1_16",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_17",
        Title: "AEF1_17",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_18",
        Title: "AEF1_18",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_19",
        Title: "AEF1_19",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_20",
        Title: "AEF1_20",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_21",
        Title: "AEF1_21",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_22",
        Title: "AEF1_22",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_23",
        Title: "AEF1_23",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF1_24",
        Title: "AEF1_24",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_1",
        Title: "AEF2_01",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_2",
        Title: "AEF2_02",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_3",
        Title: "AEF2_03",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_4",
        Title: "AEF2_04",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_5",
        Title: "AEF2_05",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_6",
        Title: "AEF2_06",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_7",
        Title: "AEF2_07",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_8",
        Title: "AEF2_08",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_9",
        Title: "AEF2_09",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_10",
        Title: "AEF2_10",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_11",
        Title: "AEF2_11",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_12",
        Title: "AEF2_12",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_13",
        Title: "AEF2_13",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_14",
        Title: "AEF2_14",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_15",
        Title: "AEF2_15",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_16",
        Title: "AEF2_16",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_17",
        Title: "AEF2_17",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_18",
        Title: "AEF2_18",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_19",
        Title: "AEF2_19",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_20",
        Title: "AEF2_20",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_21",
        Title: "AEF2_21",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_22",
        Title: "AEF2_22",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_23",
        Title: "AEF2_23",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }, {
        Name: "AEF2_24",
        Title: "AEF2_24",
        DataType: "byte",
        ValueRange: [{
            Title: "0: Ignore",
            Value: 0,
        }, {
            Title: "1: Normal",
            Value: 1,
        }]
    }],

}, {
    ReadID: 0x3028,
    WriteID: 0x2028,
    Title: "Server Address",
    Fields: [{
        Name: "ConfigMdoe",
        Title: "Address Type",
        DataType: "byte",
        ValueRange: [{
            Title: "1: IP",
            Value: 1,
        }, {
            Title: "2: Domain Name",
            Value: 2,
        }]
    }, {
        Name: "IP",
        Title: "IP",
        DataType: "string",
        ValueRange: []
    }, {
        Name: "DomainName",
        Title: "Domain Name",
        DataType: "byte",
        ValueRange: [{
            Title: "1: iot.uhomeenergy.com",
            Value: 1,
        }, {
            Title: "2: iot.cfenergy.net",
            Value: 2,
        }, {
            Title: "3: iot.ess-hub.com",
            Value: 3,
        }]
    }, {
        Name: "Port",
        Title: "Port",
        DataType: "uint16",
        ValueRange: []
    }],
}, {
    ReadID: 0x3029,
    WriteID: 0x2029,
    Title: "Battery Type",
    TitleCol: 6,
    WrapperCol: 14,
    Fields: [{
        Name: "BattaryType",
        Title: "Battery Type",
        DataType: "byte",
        ValueRange: [{ Title: "1:EVPS-2400", Value: 1 },
        { Title: "2:ZTTek-2400-MOS-15", Value: 2 },
        { Title: "3:ZTTek-2500", Value: 3 },
        { Title: "4:EVPS-5000", Value: 4 },
        { Title: "5:ZTTek-2600", Value: 5 },
        { Title: "6:Chunlan-5000", Value: 6 },
        { Title: "7:Chunlan-4800-MOS-15", Value: 7 },
        { Title: "8:HiGEE-5.8 kWh", Value: 8 },
        { Title: "9:EVPS-7.5 kWh", Value: 9 },
        { Title: "10:EVPS-10 kWh", Value: 10 },
        { Title: "11:EVPS-12.5 kWh", Value: 11 },
        { Title: "12:EVPS-5 kWh", Value: 12 },
        { Title: "13:Chunlan-5 kWh", Value: 13 },
        { Title: "14:Chunlan-7.5 kWh", Value: 14 },
        { Title: "15:Chunlan-10 kWh", Value: 15 },
        { Title: "16:Chunlan-12.5 kWh", Value: 16 },
        { Title: "17:Chunlan-4800-Stackable/15", Value: 17 },
        { Title: "18:TPLB-Kart/13", Value: 18 },
        { Title: "19:ANC-2400-Stackable/15", Value: 19 },
        { Title: "20:Chunlan-25.6V", Value: 20 },
        { Title: "21:Chunlan-12.8V", Value: 21 },
        { Title: "22:ANC-5000", Value: 22 },
        { Title: "23:Battero-5000", Value: 23 },
        { Title: "24:.Chingtao Semi-Solid HV", Value: 24 },
        { Title: "255:OTHERS", Value: 255 },]
    }],
}, {
    ReadID: 0x302a,
    WriteID: 0x202a,
    Title: "MOSTemperatureOver",
    TitleCol: 15,
    WrapperCol: 5,
    Fields: [{
        Name: "MOSTemperatureOver3",
        Title: "MOSTemperatureOver3(℃)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "MOSTemperatureOverRelease3",
        Title: "MOSTemperatureOverRelease3(℃)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "MOSTemperatureOverDelay3",
        Title: "MOSTemperatureOverDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "MOSTemperatureOverDelayRelease3",
        Title: "MOSTemperatureOverDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x302b,
    WriteID: 0x202b,
    Title: "ContactRodTemperatureOver",
    TitleCol: 16,
    WrapperCol: 4,
    Fields: [{
        Name: "ContactRodTemperatureOver3",
        Title: "ContactRodTemperatureOver3(℃)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ContactRodTemperatureOverRelease3",
        Title: "ContactRodTemperatureOverRelease3(℃)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ContactRodTemperatureOverDelay3",
        Title: "ContactRodTemperatureOverDelay3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "ContactRodTemperatureOverDelayRelease3",
        Title: "ContactRodTemperatureOverDelayRelease3(100mS)",
        DataType: "uint16",
        ValueRange: []
    },],
}, {
    ReadID: 0x302c,
    WriteID: 0x202c,
    Title: "Log-Config",
    Fields: [{
        Name: "APP",
        Title: "APP",
        DataType: "byte",
        ValueRange: [{
            Title: 'enable',
            Value: 1,
        }, {
            Title: 'disable',
            Value: 2,
        }]
    },
    {
        Name: "FML",
        Title: "FML",
        DataType: "byte",
        ValueRange: [{
            Title: 'enable',
            Value: 1,
        }, {
            Title: 'disable',
            Value: 2,
        }]
    },
    {
        Name: "HDL",
        Title: "HDL",
        DataType: "byte",
        ValueRange: [{
            Title: 'enable',
            Value: 1,
        }, {
            Title: 'disable',
            Value: 2,
        }]
    },],
}, {
    ReadID: 0x302f,
    WriteID: 0x202f,
    Title: "Charge/Discharge Control",
    Fields: [{
        Name: "SOCS1",
        Title: "SOCS1(%)",
        DataType: "byte",
        ValueRange: [0,100]
    },
    {
        Name: "SOCE1",
        Title: "SOCE1(%)",
        DataType: "byte",
        ValueRange: [0,100]
    },
    {
        Name: "Voltage1",
        Title: "Voltage1(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CharegeCurrent1",
        Title: "CharegeCurrent1(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargeCurrent1",
        Title: "DischargeCurrent1(100mA)",
        DataType: "uint16",
        ValueRange: []
    },{
        Name: "SOCS2",
        Title: "SOCS2(%)",
        DataType: "byte",
        ValueRange: [0,100]
    },
    {
        Name: "SOCE2",
        Title: "SOCE2(%)",
        DataType: "byte",
        ValueRange: [0,100]
    },
    {
        Name: "Voltage2",
        Title: "Voltage2(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CharegeCurrent2",
        Title: "CharegeCurrent2(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargeCurrent2",
        Title: "DischargeCurrent2(100mA)",
        DataType: "uint16",
        ValueRange: []
    },{
        Name: "SOCS3",
        Title: "SOCS3(%)",
        DataType: "byte",
        ValueRange: [0,100]
    },
    {
        Name: "SOCE3",
        Title: "SOCE3(%)",
        DataType: "byte",
        ValueRange: [0,100]
    },
    {
        Name: "Voltage3",
        Title: "Voltage3(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CharegeCurrent3",
        Title: "CharegeCurrent3(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargeCurrent3",
        Title: "DischargeCurrent3(100mA)",
        DataType: "uint16",
        ValueRange: []
    },{
        Name: "SOCS4",
        Title: "SOCS4(%)",
        DataType: "byte",
        ValueRange: [0,100]
    },
    {
        Name: "SOCE4",
        Title: "SOCE4(%)",
        DataType: "byte",
        ValueRange: [0,100]
    },
    {
        Name: "Voltage4",
        Title: "Voltage4(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CharegeCurrent4",
        Title: "CharegeCurrent4(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargeCurrent4",
        Title: "DischargeCurrent4(100mA)",
        DataType: "uint16",
        ValueRange: []
    },{
        Name: "SOCS5",
        Title: "SOCS5(%)",
        DataType: "byte",
        ValueRange: [0,100]
    },
    {
        Name: "SOCE5",
        Title: "SOCE5(%)",
        DataType: "byte",
        ValueRange: [0,100]
    },
    {
        Name: "Voltage5",
        Title: "Voltage5(100mV)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "CharegeCurrent5",
        Title: "CharegeCurrent5(100mA)",
        DataType: "uint16",
        ValueRange: []
    },
    {
        Name: "DischargeCurrent5",
        Title: "DischargeCurrent5(100mA)",
        DataType: "uint16",
        ValueRange: []
    },],
}];

const setting_meta_map = {}
setting_metas.forEach((v) => {
    setting_meta_map[v.ReadID] = v;
    if (v.WriteID) {
        setting_meta_map[v.WriteID] = v;
    }
});

const getValueTitle = (field, value) => {
    const f = field;
    if (f.ValueRange.length === 2 && (f.ValueRange[0] instanceof Object || f.ValueRange[1] instanceof Object) || f.ValueRange.length > 2) {
        for (let r of f.ValueRange) {
            if (value == r) {
                return value;
            } else if (r instanceof Object && r.Value === value) {
                return r.Title;
            }
        }

        return value;
    }

    return value;
}

const all = {
    setting_metas,
    setting_meta_map,
    getValueTitle,
}

export default all;