import config from '../Config';
import http from '../Http';
import { useState } from 'react';
import { Modal, Button, Upload  } from 'antd';

function DeviceImporter (props) {
    const {show, onClose} = props;

    const [resultCode, setResultCode] = useState("")
    const [resultMsg, setResultMsg] = useState("")

    const handleCancle = () => {
        onClose();
    }

    const handleOK = () => {
        onClose();
    }

    const handleChange = async ({file}) => {
        console.log("--->", file);
        if (file.status === "error") {
            setResultCode(441);
            setResultMsg("error");
            return;
        } 
        if (file.status !== "done" || file.response == null) {
            setResultMsg("");
            return;
        }
        const resp = file.response;
        setResultCode(resp.code);
        if (resp.code !== 200) {
            setResultMsg(resp.msg || "error");
        } else {
            setResultMsg(resp.msg || "Import OK");
        }

        //console.log("++++>>", file.response);
    }

    return (<Modal 
        title="Import Devices"
        visible={show}
        cancelText="Cancel"
        okText="OK"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleOK}
        footer={[
            <Button key="Cancel" onClick={handleCancle}>Cancel</Button>,
            <Button key="OK" type="primary" onClick={handleOK}>OK</Button>
        ]}
    >
        <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
            <div style={{
                flex: "0 0 auto",
                color: "red",
            }}>
                Please fill out your uploading file by The Template File:"<a href={config.apiUrl+"device/management/xls-template/get?Token="+http.token} style={{fontWeight:700}}>Devices.xls</a>". 
            </div>
            <div style={{flex: "0 0 30px"}}></div>
            <Upload 
                maxCount={1}
                accept=".xlsx,.xlsm,.xltm,.xltx"
                name="Devices.XLS"
                action={config.apiUrl+"device/management/import/do"}
                headers={{Token:http.token}}
                showUploadList={{showRemoveIcon:false}}
                onChange={handleChange}
            >
                <Button>Choose Data File (XLSX,XLSM,XLTM,XLTX) to Import</Button>
            </Upload>
            <div style={{
                flex: "1 1 auto",
                color: resultCode === 200 || resultCode === "200" ? "green": "red"
            }}>{resultMsg}</div>
        </div>
    </Modal>);
}

export default DeviceImporter;