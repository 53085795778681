import {  Button, Modal } from 'antd';
import defs from "./defs"


function SettingDetail (props) {
    const {show, onClose, SettingID, SN, CreateTime, CreatorID, Data, RspData, RspTime} = props;
    //

    const setting = SettingID ? defs.setting_meta_map[SettingID*1] : null;
    let reqData = {};
    let rspData = {};
    try {
        reqData = JSON.parse(Data);
    } catch (e) {
        //
    }
    if (! reqData) {
        reqData = {};
    }
    try {
        rspData = JSON.parse(RspData);
    } catch (e) {
        //
    }
    if (! rspData) {
        rspData = {};
    }
    if (setting) {
        if (setting.Fields.length === 1 && setting.Fields[0].DataType === "[]byte") {
            const name = setting.Fields[0].Name;
            const name2 = setting.Fields[0].Title;
            if (rspData[name].length > 0) {
                //const ss = atob(rspData[name])
                const ss = Buffer.from(rspData[name], "base64")
                let i = 0;
                ss.map((b) => {
                    for (let j=0; j<4; j++) {
                        rspData[`${name2}${i}`] = (b >> (j*2)) & 3;
                        i++;
                    }
                })
                rspData[name] = ss;
            }
        } else {
            for (let f of setting.Fields) {
                if (f.Offset) {
                    rspData[f.Name] += f.Offset;
                }
            }
        }
    }

    return (
        <Modal 
            title={`Setting Deatails:${SN}`}
            visible={show}
            cancelText="Cancel"
            okText="Submit"
            mask={true}
            maskClosable={true}
            //width={600}
            onCancel={onClose}
            onOk={onClose}
            footer={[
                <Button key="Close" type="primary" onClick={onClose}>Close</Button>
            ]}
        >
            <div style={{
                textAlign: 'center',
                fontWeight: "bold",
                paddingBottom: "10px"
            }}>
                {setting ? setting.Title : ""}&nbsp;{SettingID>0x3000 ? "Read" : "Read"}
            </div>
            <div style={{
                paddingBottom: "10px",
                color: "#888",
            }}>
                User: {CreatorID}, Request Time: {CreateTime}, Response Time: {RspTime || ""}
            </div>
            <table cellPadding="5px" width="100%" border= "1px solid, #aaa">
                <thead><tr>
                    <th>Field</th>
                    <th>Request</th>
                    <th>Response</th>
                </tr></thead>
                <tbody>
                {setting ? (
                    setting.Fields.map((f) => {
                        if (setting.ReadID===0x3004) {
                            const rs = [];
                            (reqData[f.Name] || rspData[f.Name] || []).map((r, idx) => {
                                for (let j=0; j<4;j ++) {
                                    const fn = `${f.Title}${idx*4+j}`;
                                    rs.push((<tr>
                                        <td align="right">{f.Title}{idx*4+j+1}:&nbsp;</td>
                                        <td>{SettingID>0x3000 ? "" : (reqData.hasOwnProperty(fn) ? defs.getValueTitle(f,reqData[fn]) : "")}</td>
                                        <td>{rspData.hasOwnProperty(fn) ? defs.getValueTitle(f, rspData[fn]) : ""}</td>
                                    </tr>));
                                }
                                
                            });

                            return rs;
                        }
                        return (<tr>
                            <td align="right">{f.Title}:&nbsp;</td>
                            <td>{
                                SettingID>0x3000 ? "" : 
                                    (reqData.hasOwnProperty(f.Name) ? 
                                        defs.getValueTitle(f, reqData[f.Name])
                                        : ""
                                    )
                            }</td>
                            <td>{rspData.hasOwnProperty(f.Name) ?defs.getValueTitle(f, rspData[f.Name]) : ""}</td>
                        </tr>)
                    })
                ) : <tr></tr>}
                </tbody>
            </table>
        </Modal>
    )
}

export default SettingDetail;