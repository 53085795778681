import React, { useEffect, useState, useContext } from 'react';
import { Form, Select, DatePicker, Radio, Button } from 'antd';
import InvSetting from './InvSetting';

function RemoteSetting(props) {
    const {inverterSelectOptions, SN, handleSelectSN} = props

    return (
        <div className="inv-setting">
            <div className="inv-setting-header">
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => handleSelectSN(value)}
                    value={SN}
                    style={{ width: '200px', marginRight: '20px' }}
                >{inverterSelectOptions}</Select>
            </div>
            <div className="inv-setting-main">
                <InvSetting SN={SN} />
            </div>
        </div>
    )
}

export default RemoteSetting;