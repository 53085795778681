import { useState, useEffect, useRef } from 'react';
import { Typography } from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import utils from "../utils";

function Switch (props) {
    const {setTip, data} = props;

    //const data = {ByteCount:1, Route1:1, Route3:0};

    const bs = [];
    let ln = 4;
    if (data) {
        ln = data.ByteCount*4;
    }
    for (let i=0; i<ln; i++) {
        let x = data ? (data[`Route${i+1}`] == undefined ? null : data[`Route${i+1}`]) : null;

        bs.push(
            <div style={{
                margin: 5,
                display: "flex",
                flexDirection: 'column',
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#565353",
                width: 60,
                height: 350,
                borderRadius: 30,
            }}>
                <div style={{
                    width: 56,
                    height: 56,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid white",
                    borderRadius: 28,
                    backgroundColor: x==0 ? "#0280ba" : "transparent",
                }}>
                    <Typography style={{color:'white',fontSize:16}}>OFF</Typography>
                </div>
                {x ==  0 ? <ArrowUpwardIcon style={{color:"white", fontSize:50}}/> : x == 1 ? 
                    <ArrowDownwardIcon style={{color:"white", fontSize:50}}/> : 
                    <SwapVertIcon style={{color:"#bbb", fontSize:50}}/>}
                <div style={{
                    width: 56,
                    height: 56,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid white",
                    borderRadius: 28,
                    backgroundColor: x==1 ? "#0280ba" : "transparent",
                }}>
                    <Typography style={{color:'white',fontSize:16}}>ON</Typography>
                </div>
            </div>
        );
    }

    return (
        <div style={{
            flex: "1 0 1px",
            display: "flex",
            flexDirection: "column",
        }}>
            <div style={{
                flex: "0 0 36px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "black",
            }}>
                <Typography style={{color:"#eee"}}>Switch</Typography>
                <Typography style={{color:"#aaa", fontSize:12}}>{data ? utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date()) : "yyyy-mm-dd hh:mm:ss"}</Typography>
            </div>
            <div style={{
                backgroundColor: "black",
                flex: "1, 1 1px",
                minWidth: 250,
                display: "flex",
                flexDirection: 'row',
                flexWrap: "wrap",
                padding: 5,
            }} >
                {bs}
            </div>
        </div>
    );
}

export default Switch;