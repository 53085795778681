import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import utils from '../utils';
import { Table, Input, Button, Modal, Form, Row, Col, Checkbox, Select, Popover, Menu, Dropdown, Tabs } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import defs from "./defs"
import validators from "./validators";
import WS from "../WS";


const useStyles = makeStyles(() => ({
    tabs: {
        "& .ant-tabs-content": {
            height: "100%",
        },
    },
    settingInput: {
        "& ::before": {
            content: "√+",
            display: "inline-block",
        },
        "& .ant-form-item-control-input::after": {
            content: "√-"
        }
    }
}));

function Setting(props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        SN: '',
        ProductName: '',
        DistributorName: '',
        UserID: '',
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedSN, setSelectedSN] = useState(null);
    const [selectedDatagramVersion, setSelectedDatagramVersion] = useState(0x201);
    const [productSelectOptions, setProductSelectOptions] = useState([]);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [expand, setExpand] = useState(false);
    const [online, setOnline] = useState(false);
    const [readSettingRsps, setReadSettingRsps] = useState({});
    const [writeSettingRsps, setWriteSettingRsps] = useState({});

    const refTableContainer = useRef();
    const refTimerProductSearcher = useRef(null);
    const refProductSearchValue = useRef(null);
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    const [form] = Form.useForm();

    const classes = useStyles();

    const ws = new WS.WSInstance();

    const settingForms = {};
    const _metas = defs.setting_metas;
    settingForms[_metas[0].ReadID] = Form.useForm()[0];
    settingForms[_metas[1].ReadID] = Form.useForm()[0];
    settingForms[_metas[2].ReadID] = Form.useForm()[0];
    settingForms[_metas[3].ReadID] = Form.useForm()[0];
    settingForms[_metas[4].ReadID] = Form.useForm()[0];
    settingForms[_metas[5].ReadID] = Form.useForm()[0];
    settingForms[_metas[6].ReadID] = Form.useForm()[0];
    settingForms[_metas[7].ReadID] = Form.useForm()[0];
    settingForms[_metas[8].ReadID] = Form.useForm()[0];
    settingForms[_metas[9].ReadID] = Form.useForm()[0];
    settingForms[_metas[10].ReadID] = Form.useForm()[0];
    settingForms[_metas[11].ReadID] = Form.useForm()[0];
    settingForms[_metas[12].ReadID] = Form.useForm()[0];
    settingForms[_metas[13].ReadID] = Form.useForm()[0];
    settingForms[_metas[14].ReadID] = Form.useForm()[0];
    settingForms[_metas[15].ReadID] = Form.useForm()[0];
    settingForms[_metas[16].ReadID] = Form.useForm()[0];
    settingForms[_metas[17].ReadID] = Form.useForm()[0];
    settingForms[_metas[18].ReadID] = Form.useForm()[0];
    settingForms[_metas[19].ReadID] = Form.useForm()[0];
    settingForms[_metas[20].ReadID] = Form.useForm()[0];
    settingForms[_metas[21].ReadID] = Form.useForm()[0];
    settingForms[_metas[22].ReadID] = Form.useForm()[0];
    settingForms[_metas[23].ReadID] = Form.useForm()[0];
    settingForms[_metas[24].ReadID] = Form.useForm()[0];
    settingForms[_metas[25].ReadID] = Form.useForm()[0];
    settingForms[_metas[26].ReadID] = Form.useForm()[0];
    settingForms[_metas[27].ReadID] = Form.useForm()[0];
    settingForms[_metas[28].ReadID] = Form.useForm()[0];
    settingForms[_metas[29].ReadID] = Form.useForm()[0];
    settingForms[_metas[30].ReadID] = Form.useForm()[0];
    settingForms[_metas[31].ReadID] = Form.useForm()[0];
    settingForms[_metas[32].ReadID] = Form.useForm()[0];
    settingForms[_metas[33].ReadID] = Form.useForm()[0];
    settingForms[_metas[34].ReadID] = Form.useForm()[0];
    // defs.setting_metas.map( (v) => {
    //     settingForms[v.WriteID] = Form.useForm()[0];
    // });


    useEffect(() => {
        let active = true;
        console.log("Setting Effect");

        (async () => {
            setLoading(true);


            let resp = await http.post("device/management/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Setting]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setRowTotal(0)
                setRows([]);

                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("Setting Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }

        let obj = document.createElement('object');
        el.objResizer = obj;
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (!el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const handleSearchProduct = (v) => {
        if (refTimerProductSearcher.current != null) {
            clearTimeout(refTimerProductSearcher.current);
        }
        refProductSearchValue.current = v;

        refTimerProductSearcher.current = setTimeout(async () => {
            const resp = await http.post("product/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Setting]", e);
                return null;
            });

            if (refProductSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load product list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setProductSelectOptions(ls);
            } catch (e) {
                console.log("[Setting]", e);

                setTip({ open: true, severity: "error", msg: "load product list error" });
            }
        }, 300);
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Setting]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load distributor list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch (e) {
                console.log("[Setting]", e);

                setTip({ open: true, severity: "error", msg: "load distributor list error" });
            }
        }, 300);
    }

    const handleSearch = () => {
        if (selectedSN) {
            ws.unregister(selectedSN);
        }

        setSelectedKeys([]);
        setSelectedSN(null);
        setReadSettingRsps({});
        setWriteSettingRsps({});
        setPageNo(0);

        for (let id in settingForms) {
            let fm = settingForms[id];
            fm.resetFields();
        }

        const ps = form.getFieldsValue();
        ps.SN = ps.SN || "";
        ps.ProductName = ps.ProductName || "";
        ps.DistributorName = ps.DistributorName || "";
        ps.UserID = ps.UserID || "";
        setParams({ ...ps });
    }

    const handleSelect = (selectedRowKeys, selectedRows) => {
        if (selectedSN) {
            ws.unregister(selectedSN);
        }

        setSelectedKeys([...selectedRowKeys]);
        if (selectedRows.length > 0) {
            setSelectedSN(selectedRows[0].SN);
            ws.register(selectedRows[0].SN);


            //2022.1.29
            let MsgVersion = 0x201;
            rows.forEach((v) => {
                if (!v["DatagramVersion"] || isNaN(v["DatagramVersion"])) {
                    return;
                }

                let x = parseInt(v["DatagramVersion"])
                if (x > MsgVersion) {
                    MsgVersion = x;
                }
            });
            setSelectedDatagramVersion(MsgVersion);
            //
        } else {
            setSelectedSN(null);
        }
        for (let id in settingForms) {
            let fm = settingForms[id];
            fm.resetFields();
        }
        setReadSettingRsps({});
        setWriteSettingRsps({});
    }

    const calcTableBodyHeight = () => {
        if (!refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return h >= 54 ? h - 54 : h / 2;
    }

    const handleWSConnect = () => {
        setOnline(true);
    }


    const handleWSData = (msgID, data) => {
        if (msgID >= 0x3001 && msgID <= 0x3099) {
            console.log("[Setting][ReadRsp]", msgID.toString(16), data);
            setReadSettingRsps({ ...readSettingRsps, [msgID]: data });

            try {
                const fm = settingForms[msgID];
                if (fm) {
                    const setting = defs.setting_meta_map[msgID];
                    const ps = { ...data };
                    if (setting.Fields.length === 1 && setting.Fields[0].DataType === "[]byte") {
                        const name = setting.Fields[0].Name;
                        const name2 = setting.Fields[0].Title;
                        for (let i = 0; i < ps[name].length; i++) {
                            ps[`${name2}${i}`] = ps[name][i];
                        }
                    } else {
                        for (let f of setting.Fields) {
                            if (f.Offset) {
                                ps[f.Name] += f.Offset;
                            }
                        }
                    }
                    fm.setFieldsValue(ps);
                }
            } catch (e) {
                console.log("[Setting]", e);
            }
        } else if (msgID >= 0x2001 && msgID <= 0x2099) {
            console.log("[Setting][WriteRsp]", msgID.toString(16), data);
            setWriteSettingRsps({ ...writeSettingRsps, [msgID]: data });
        }
    }

    const handleWSClose = () => {
        setOnline(false)
    }

    const handleWSError = () => {
        //
    }

    const handleRead = async (settingmeta) => {
        if (!selectedSN) {
            return;
        }

        setReadSettingRsps({});
        setWriteSettingRsps({});

        try {
            let MsgBody = "";

            if (selectedDatagramVersion >= 0x202 && settingmeta.ReadID == 0x3025) {
                const ps = await settingForms[settingmeta.ReadID].validateFields().catch(() => { return null });
                if (!ps) {
                    return;
                }

                MsgBody = "0" + ps["VersionType"].toString();
            }

            const resp = await http.post("setting/do", {
                DeviceSN: selectedSN,
                MsgID: settingmeta.ReadID,
                MsgBody: MsgBody,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Setting]", e);
                return null;
            });
        } catch (e) {
            console.log("[Setting]", e);

            setTip({ open: true, severity: "error", msg: "Read error" });
        }
    }

    const handleWrite = async (settingmeta) => {
        if (!selectedSN) {
            return;
        }

        setWriteSettingRsps({});

        try {
            const ps = await settingForms[settingmeta.ReadID].validateFields().catch(() => { return null });
            if (!ps) {
                return;
            }

            if (settingmeta.Fields.length === 1 && settingmeta.Fields[0].DataType === "[]byte") {
                const vs = [];
                if (settingmeta.WriteID === 0x2004) {
                    let v = 0;
                    for (let i = 0; i < 100; i++) {
                        const name = settingmeta.Fields[0].Title + i.toString();
                        if (!ps.hasOwnProperty(name)) {
                            if ((i % 4) > 0) {
                                vs.push(v);
                            }
                            break;
                        }
                        v += (ps[name] << ((i % 4) * 2));
                        if ((i + 1) % 4 == 0) {
                            vs.push(v);
                            v = 0;
                        }
                    }
                } else {
                    for (let i = 0; i < 100; i++) {
                        const name = settingmeta.Fields[0].Title + i.toString();
                        if (!ps.hasOwnProperty(name)) {
                            break;
                        }
                        vs.push(ps[name]);
                    }
                }

                ps[settingmeta.Fields[0].Name] = vs;
            } else {
                for (let f of settingmeta.Fields) {
                    if ("byte,uint16, uint32".indexOf(f.DataType) > -1) {
                        ps[f.Name] = parseFloat(ps[f.Name]);
                    }
                    if (f.Offset) {
                        ps[f.Name] -= f.Offset;
                    }
                }
            }

            const resp = await http.post("setting/do", {
                DeviceSN: selectedSN,
                MsgID: settingmeta.WriteID,
                MsgBodyJson: JSON.stringify(ps),
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[Setting]", e);
                return null;
            });
        } catch (e) {
            console.log("[Setting]", e);

            setTip({ open: true, severity: "error", msg: "Write error" });
        }
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => {
        if (e.code == "Enter") {
            handleSearch()
        }
    }

    return (
        <div
            style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "row",
                padding: 8,
            }}
        >
            <div style={{
                flex: "0 0 400px",
                display: "flex",
                flexDirection: "column",
            }}>
                <div style={{
                    flex: "0 1 auto",
                }}>
                    <Form
                        form={form}
                        initialValues={{
                            Burning: ["No-Burning", 'Done', 'Ignored', "Expired", 'To-Be-Confirmed', 'Errored', 'In-Progress'],
                        }}
                        style={{
                            padding: 24,
                            background: '#fbfbfb',
                            border: '1px solid #d9d9d9',
                            borderRadius: 2
                        }}
                    >
                        <Row gutter={24}>
                            <Col span={12} key="SN">
                                <Form.Item
                                    name="SN"
                                    label="SN"
                                >
                                    <Input placeholder="Enter Device SN" onPressEnter={handleSearch} />
                                </Form.Item>
                            </Col>
                            <Col span={12} key="Product">
                                <Form.Item
                                    name="ProductName"
                                    label="Product"
                                >
                                    <Select
                                        showSearch
                                        placeholder="Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchProduct}
                                        onInputKeyDown={searchByEnter}
                                    >{productSelectOptions}</Select>
                                </Form.Item>
                            </Col>
                            <Col span={12} key="Distributor" style={{ display: expand ? "block" : "none" }}>
                                <Form.Item
                                    name="DistributorName"
                                    label="Distributor"
                                >
                                    <Select
                                        showSearch
                                        placeholder="Name"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearchDistributor}
                                        onInputKeyDown={searchByEnter}
                                    >{distributorSelectOptions}</Select>
                                </Form.Item>
                            </Col>
                            <Col span={12} key="UserID" style={{ display: expand ? "block" : "none" }}>
                                <Form.Item
                                    name="UserID"
                                    label="UserID"
                                >
                                    <Input placeholder="Enter User ID" onPressEnter={handleSearch} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Button type="primary" onClick={handleSearch}>
                                    Search
                                </Button>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    onClick={() => {
                                        form.resetFields();
                                    }}
                                >
                                    Clear
                                </Button>
                                <a
                                    style={{ fontSize: 12 }}
                                    onClick={() => {
                                        setExpand(!expand);
                                    }}
                                >
                                    {expand ? <UpOutlined /> : <DownOutlined />} {expand ? 'Collapse' : 'Expand'}
                                </a>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div style={{
                    flex: "1 1 1px",
                    position: "relative",
                }}>
                    <div ref={refTableContainer} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden" }}>
                        <Table
                            columns={[{
                                title: "",
                                width: 50,
                                fixed: 'left',
                                render: (v, r, idx) => {
                                    return (pageNo * pageSize + idx + 1);
                                }
                            }, {
                                title: "SN",
                                dataIndex: 'SN',
                                width: 200,
                                fixed: 'left',
                                render: (v, r) => {
                                    const content = (
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}>
                                            <div style={{
                                                display: "flex"
                                            }}>
                                                <div style={{
                                                    flex: "0 0 80px",
                                                    textAlign: "right",
                                                    fontWeight: "bold",
                                                }}>Product:&nbsp;</div>
                                                <div style={{ flex: "1" }}>{r.ProductName}</div>
                                            </div>
                                            <div style={{
                                                display: "flex"
                                            }}>
                                                <div style={{
                                                    flex: "0 0 80px",
                                                    textAlign: "right",
                                                    fontWeight: "bold",
                                                }}>Distributor:&nbsp;</div>
                                                <div style={{ flex: "1" }}>{r.DistributorName}</div>
                                            </div>
                                            <div style={{
                                                display: "flex"
                                            }}>
                                                <div style={{
                                                    flex: "0 0 80px",
                                                    textAlign: "right",
                                                    fontWeight: "bold",
                                                }}>Users:&nbsp;</div>
                                                <div style={{ flex: "1" }}>{r.Users}</div>
                                            </div>
                                        </div>
                                    );
                                    return (
                                        <Popover title="Note" content={content}>
                                            <div>{v}</div>
                                        </Popover>
                                    );
                                }
                            }]}
                            pagination={{
                                pageSizeOptions: [25, 50, 100],
                                current: pageNo + 1,
                                pageSize: pageSize,
                                total: rowTotal,
                                showSizeChanger: true,
                                showTotal: (total) => { return `Total ${total}` },
                                onShowSizeChange: (current, size) => {
                                    setPageSize(size);
                                },
                                onChange: (page, pageSize) => {
                                    setPageNo(page - 1);
                                }
                            }}
                            tableLayout="fixed"
                            bordered={true}
                            size="middle"
                            loading={loading}
                            rowKey="ID"
                            dataSource={rows}
                            scroll={{ x: 250, y: tableBodyHeight }}
                            rowSelection={{
                                type: "radio",
                                selectedRowKeys: selectedKeys,
                                onChange: handleSelect,
                                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
                            }}
                        />
                    </div>
                </div>
            </div>
            <div style={{
                flex: '1 1 300px',
                position: 'relative',
            }}>
                <Tabs
                    defaultActiveKey="1"
                    tabPosition="left"
                    className={classes.tabs}
                    size="small"
                    style={{
                        height: "100%",
                        width: "100%",
                        position: 'absolute',
                    }}
                >{
                        defs.setting_metas.map((v) => {
                            return (
                                <Tabs.TabPane
                                    key={v.Title}
                                    tab={v.Title}
                                    style={{
                                        height: "100%",
                                        border: "1px solid #ccc"
                                    }}
                                >
                                    <div style={{
                                        maxHeight: "100%",
                                        overflow: "auto",
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                    }}>
                                        <div style={{ fontWeight: 'bold', padding: '10px', textAlign: 'center' }}>{selectedSN ? selectedSN : "Select a Device in the Left Grid"}</div>
                                        <Form
                                            form={settingForms[v.ReadID]}
                                            labelCol={{ span: 12 }}
                                            wrapperCol={{ span: 8 }}
                                            layout="horizontal"
                                            size="small"
                                            initialValues={validators.getinitialValues(v)}
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            {v.Fields.map((f) => (
                                                f.DataType === "[]byte" ?
                                                    (
                                                        readSettingRsps[v.ReadID] ?
                                                            readSettingRsps[v.ReadID][f.Name].map((it, idx) => (
                                                                <Form.Item
                                                                    key={`${f.Title}${idx}`}
                                                                    label={readSettingRsps[v.ReadID] && writeSettingRsps[v.WriteID] ?
                                                                        (readSettingRsps[v.ReadID][`${f.Title}${idx}`] !== writeSettingRsps[v.WriteID][`${f.Title}${idx}`] ? <span>{`${f.Title}${idx + 1}`}<label style={{ color: "red" }}>×</label></span> : <span>{`${f.Title}${idx + 1}`}<label style={{ color: "#76ff03" }}>√</label></span>) : `${f.Title}${idx + 1}`}
                                                                    labelCol={v.hasOwnProperty("TitleCol") ? { span: v.TitleCol } : null}
                                                                    wrapperCol={v.hasOwnProperty("WrapperCol") ? { span: v.WrapperCol } : null}
                                                                    name={`${f.Title}${idx}`}
                                                                    required={v.WriteID ? true : false}
                                                                    rules={[{
                                                                        required: v.WriteID ? true : false,
                                                                        type: validators.getRuleType(f),
                                                                        transform: validators.getRuleTransform(f),
                                                                        min: validators.getRuleMin(f),
                                                                        max: validators.getRuleMax(f),
                                                                    }]}
                                                                    className={classes.settingInput}
                                                                >
                                                                    {f.ValueRange.length === 1 ?
                                                                        (
                                                                            <Input readOnly={true} />
                                                                        ) : (f.ValueRange.length === 2 && (f.ValueRange[0] instanceof Object || f.ValueRange[1] instanceof Object) || f.ValueRange.length > 2) ?
                                                                            (
                                                                                <Select>
                                                                                    {f.ValueRange.map((it) => {
                                                                                        return (
                                                                                            <Select.Option
                                                                                                key={it instanceof Object ? it.Title : it}
                                                                                                value={it instanceof Object ? it.Value : it}
                                                                                            >{it instanceof Object ? it.Title : it}</Select.Option>
                                                                                        )
                                                                                    })}
                                                                                </Select>
                                                                            ) : <Input />}
                                                                </Form.Item>

                                                            ))
                                                            :
                                                            <div key={f.Name} style={{ textAlign: 'center', color: "red", fontWeight: "bold", padding: '20px' }}><label>Please "Read" first.</label></div>
                                                    )
                                                    :
                                                    <Form.Item
                                                        key={f.Name}
                                                        hidden={selectedDatagramVersion < 0x202 && f.Name == "VersionType"}
                                                        label={readSettingRsps[v.ReadID] && writeSettingRsps[v.WriteID] ?
                                                            (readSettingRsps[v.ReadID][f.Name] !== writeSettingRsps[v.WriteID][f.Name] ? <span>{f.Title}<label style={{ color: "red" }}>×</label></span> : <span>{f.Title}<label style={{ color: "#76ff03" }}>√</label></span>) : f.Title}
                                                        labelCol={v.hasOwnProperty("TitleCol") ? { span: v.TitleCol } : null}
                                                        wrapperCol={v.hasOwnProperty("WrapperCol") ? { span: v.WrapperCol } : null}
                                                        name={f.Name}
                                                        rules={[{
                                                            required: v.WriteID ? true : false,
                                                            type: validators.getRuleType(f),
                                                            transform: validators.getRuleTransform(f),
                                                            min: validators.getRuleMin(f),
                                                            max: validators.getRuleMax(f),
                                                        }]}
                                                        className={classes.settingInput}
                                                    >
                                                        {f.ValueRange.length === 1 ?
                                                            (
                                                                <Input readOnly={true} />
                                                            ) : (f.ValueRange.length === 2 && (f.ValueRange[0] instanceof Object || f.ValueRange[1] instanceof Object) || f.ValueRange.length > 2) ?
                                                                (
                                                                    <Select>
                                                                        {f.ValueRange.map((it) => {
                                                                            return (
                                                                                <Select.Option
                                                                                    key={it instanceof Object ? it.Title : it}
                                                                                    value={it instanceof Object ? it.Value : it}
                                                                                >{it instanceof Object ? it.Title : it}</Select.Option>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                ) : <Input />}
                                                    </Form.Item>
                                            ))}
                                        </Form>
                                        <div style={{
                                            width: "100%",
                                            padding: "10px",
                                            display: 'flex',
                                            justifyContent: "center",
                                        }}>
                                            {v.WriteID ? (
                                                <>
                                                    <Button type="primary" onClick={() => settingForms[v.ReadID].resetFields()}>Reset</Button>
                                                    <div style={{ width: "50px" }}></div>
                                                    <Button type="primary" onClick={() => handleRead(v)}>Read</Button>
                                                    <div style={{ width: "25px" }}></div>
                                                    <Button type="primary" onClick={() => handleWrite(v)}>Write</Button>
                                                </>
                                            ) : (
                                                <Button type="primary" onClick={() => handleRead(v)}>Read</Button>
                                            )}
                                        </div>
                                    </div>
                                </Tabs.TabPane>
                            )
                        })
                    }</Tabs>
            </div>
            <WS
                ws={ws}
                onConnect={handleWSConnect}
                onData={handleWSData}
                onClose={handleWSClose}
                onError={handleWSError}
            ></WS>
        </div>
    );
}

export default Setting;