import config from '../Config';
import http from "../Http";
import utils from "../utils";
import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Input, Form, Select, Table, Row, Col, Popover, Checkbox } from 'antd';
import { UpOutlined, DownOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import LinkIcon from '@material-ui/icons/Link';
import WS from "../WS";

function BurningController(props) {
    const setTip = useContext(utils.tipContext);

    const ws = new WS.WSInstance();
    const { show, onClose, sn, dataLength, deviceID, burningID, firmwareID } = props;

    const [online, setOnline] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState("");
    const [done, setDone] = useState(false);

    useEffect(() => {
        ws.register(sn);

        return () => {
            ws.unregister(sn);
        }
    }, [])

    const handleWSConnect = () => {
        setOnline(true);
    }


    const handleWSData = (msgID, data) => {
        if (msgID !== 0x4002) {
            return;
        }

        try {
            if (data.Code === 3) {
                if (data.Mixed === 0) {
                    setError("version too old");
                } else if (data.Mixed === 1) {
                    setError("crc error");
                } else if (data.Mixed === 2) {
                    setError("frame sn error");
                } else if (data.Mixed === 3) {
                    setError("file type error");
                } else if (data.Mixed === 4) {
                    setError("product type error");
                } else if (data.Mixed === 5) {
                    setError("data store error");
                } else if (data.Mixed === 6) {
                    setError("md5 error");
                } else if (data.Mixed === 7) {
                    setError("other error");
                }
            } else if (data.Code == 1) {
                setProgress(data.Mixed * config.burningPacketSize / dataLength);
            } else if (data.Code === 2) {
                setProgress(1);
                setDone(true);
            }
        } catch (e) {
            console.log("[BurningController]", e);

            setProgress(0);
        }
    }

    const handleWSClose = () => {
        setOnline(false)
    }

    const handleWSError = () => {
        //
    }

    const onCancleBurning = async () => {
        try {
            const resp = await http.post("device/upgrade/cancel/do", {
                DeviceID: deviceID,
                BurningID: burningID,
                FirmwareID: firmwareID,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningController]", e);
                return null;
            });

            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "Cancle error" });
                return;
            }

            const data = resp.data;

            if (data["EventCode"] === 5) {
                setTip({ open: true, severity: "success", msg: "Cancle success" })
            } else {
                setTip({ open: true, severity: "error", msg: "Cancle error" });
            }
        } catch (e) {
            console.log("[BurningController]", e)
            setTip({ open: true, severity: "error", msg: "Cancle error" });
        }
    }

    return (<Modal
        title={`Upgrade Controller`}
        visible={show}
        mask={true}
        maskClosable={false}
        onCancel={onClose}
        width={540}
        footer={[
            <Button style={{
                display: done == true ? "none" : ""
            }} key="CancelBurning" type="primary" onClick={onCancleBurning}>Cancel Upgrade</Button>,
            <Button key="Close" type="primary" onClick={onClose}>Close</Button>
        ]}
    >
        <div style={{
            display: "flex",
            flexDirection: 'column',
        }}>
            <div style={{
                display: "flex",
                alignItems: 'flex-end',
            }}>
                <div style={{
                    flex: '1 1 auto',
                    textAlign: "center"
                }}>
                    {sn}
                </div>
                <LinkIcon
                    style={{ color: online ? "#76ff03" : "#888" }}
                />
            </div>
            <div style={{
                padding: "10px",
            }}>
                <div style={{
                    width: "100%",
                    height: 30,
                    border: "1px solid #888",
                    borderRadius: 10,
                    position: "relative",
                    overflow: "hidden",
                }}>
                    <div style={{
                        position: "absolute",
                        height: "100%",
                        width: `calc(${progress * 100}%)`,
                        backgroundColor: "green",
                    }}></div>
                    <div style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                        lineHeight: "28px",
                        color: "#aaa",
                        backgroundColor: 'transparent',
                    }}>
                        {(progress > 1 ? 100 : progress * 100).toFixed(1)}%
                    </div>
                </div>
            </div>
            <div style={{
                color: "red",
                padding: "5px",
            }}>{error}</div>
            <div style={{
                display: done == true ? "" : "none",
                color: "green",
                padding: "5px",
            }}>upgrade success</div>
        </div>
        <WS
            ws={ws}
            onConnect={handleWSConnect}
            onData={handleWSData}
            onClose={handleWSClose}
            onError={handleWSError}
        ></WS>
    </Modal>)
}

export default BurningController;