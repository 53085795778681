import http from './Http';
import { ThemeContext } from './Http';
import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    caption: {
        flex: "1 0 1px",
        textAlign: "right",
        color: "blue",
    },
    value: {
        flex: "1 0 1px",
    }
})
function CustomerService (props) {
    const session = useContext(ThemeContext);

    const classes = useStyles();

    const cs = [];
    let hasManufacture = false;
    if (session.Distributors) {
        session.Distributors.forEach((c) => {
            if (c.Category === 2) {
                hasManufacture = true
            }
            cs.push(
                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    margin: "5px",
                    pading: "5px",
                    border: "1px solid #aaa",
                }}>
                    <div style={{
                        display: 'flex',
                        pading: "5px",
                    }}>
                        <div className={classes.caption}>{c.Category===2 ? "Manufacturer" : c.Category===1 ? "Installer" : "Distributor"}:&nbsp;</div>
                        <div className={classes.value}>{c.Name||""}</div>
                    </div>
                    <div style={{
                        display: 'flex',
                        pading: "5px",
                    }}>
                        <div className={classes.caption}>Phone:&nbsp;</div>
                        <div className={classes.value}>{c.Phone||""}</div>
                    </div>
                    <div style={{
                        display: 'flex',
                        pading: "5px",
                    }}>
                        <div className={classes.caption}>E-Mail:&nbsp;</div>
                        <div className={classes.value}>{c.EMail||""}</div>
                    </div>
                </div>
            )
        });
    }
    const c = session.CustomerService || {};
    if (! hasManufacture) {
        cs.push(
            <div style={{
                display: 'flex',
                flexDirection: "column",
                margin: "5px",
                pading: "5px",
                border: "1px solid #aaa",
            }}>
                <div style={{
                    display: 'flex',
                    pading: "5px",
                }}>
                    <div className={classes.caption}>Manufacturer:&nbsp;</div>
                    <div className={classes.value}>{c.Name||""}</div>
                </div>
                <div style={{
                    display: 'flex',
                    pading: "5px",
                }}>
                    <div className={classes.caption}>Phone:&nbsp;</div>
                    <div className={classes.value}>{c.Phone||""}</div>
                </div>
                <div style={{
                    display: 'flex',
                    pading: "5px",
                }}>
                    <div className={classes.caption}>E-Mail:&nbsp;</div>
                    <div className={classes.value}>{c.EMail||""}</div>
                </div>
            </div>
        )
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            fontSize: "20px",
            padding: "20px",
        }}>{cs}</div>
    )
}

export default CustomerService;