import { useState, useEffect, useRef } from 'react';
import { Typography } from "@material-ui/core";
import utils from "../utils";

function InverterStatus(props) {
    const { setTip, data } = props;

    // if (data) {
    //     data["CurrentCollectorFault"] = 2;
    // }

    return (
        <div style={{
            flex: "1 0 1px",
            backgroundColor: "#666",
            display: "flex",
            flexDirection: "column",
        }}>
            <div style={{
                flex: "0 0 36px",
                backgroundColor: "black",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Typography style={{ color: "#eee" }}>Inverter Status</Typography>
                <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date()) : "yyyy-mm-dd hh:mm:ss"}</Typography>
            </div>
            <div style={{
                backgroundColor: "black",
                flex: "1, 1 1px",
                minWidth: 350,
                display: "flex",
                flexDirection: 'column',
                alignItems: 'stretch',
            }} >
                {/* Current,Voltage & Temperature */}
                <div style={{
                    margin: 10,
                    padding: 5,
                    display: "flex",
                    flexDirection: 'column',
                    backgroundColor: '#565353',
                }}>
                    <Typography style={{
                        alignSelf: "center",
                        color: "#fff",
                        fontSize: 16,
                    }}> Current,Voltage & Temperature</Typography>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Max Charge Current:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? (((data["MaxChargeCurrent"] - 32767) * 0.1).toFixed(1)) + " A" : "-"}</Typography>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Max Discharge Current:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? (((data["MaxDischargeCurrent"] - 32767) * 0.1).toFixed(1)) + " A" : "-"}</Typography>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Charge Voltage:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? ((data["ChargeVoltage"] * 0.1).toFixed(1)) + " V" : "-"}</Typography>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Discharge Voltage:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? ((data["DischargeVoltage"] * 0.1).toFixed(1)) + " V" : "-"}</Typography>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Battery Voltage:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? ((data["BatteryVoltage"] * 0.01).toFixed(1)) + " V" : "-"}</Typography>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Battery Current:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? (((data["BatteryCurrent"] - 32767) * 0.1).toFixed(1)) + " A" : "-"}</Typography>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Battery Temperature:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? ((data["BatteryTemperature"] * 0.1).toFixed(1)) + " ℃" : "-"}</Typography>
                    </div>
                </div>
                {/* Status */}
                <div style={{
                    margin: 10,
                    padding: 5,
                    display: "flex",
                    flexDirection: 'column',
                    backgroundColor: '#565353',
                }}>
                    <Typography style={{
                        alignSelf: "center",
                        color: "#fff",
                        fontSize: 16,
                    }}>Status</Typography>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Charge Enable:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? (data["ChargeEnabled"] == 1 ? "enabled" : "disabled") : "-"}</Typography>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Discharge Enable:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? (data["DischargeEnabled"] == 1 ? "enabled" : "disabled") : "-"}</Typography>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>Emergency Charge Enable:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? (data["EmergencyChargeEnabled"] == 1 ? "enabled" : "disabled") : "-"}</Typography>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Typography style={{ color: "#fff", fontSize: 12 }}>ChargeEnabled:&nbsp;</Typography>
                        <Typography style={{ color: "#aaa", fontSize: 12 }}>{data ? (data["Status"] == 1 ? "init" : data["Status"] == 2 ? "comm" : data["Status"] == 3 ? "comm failed" : "unknown") : "-"}</Typography>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default InverterStatus;