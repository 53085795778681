const utc_office = [
    -43200,
    -39600,
    -36000,
    -34200,
    -32400,
    -28800,
    -25200,
    -21600,
    -18000,
    -14400,
    -10800,
    -9000,
    -7200,
    -3600,
    0,
    3600,
    7200,
    10800,
    14400,
    16200,
    18000,
    19800,
    20700,
    21600,
    23400,
    25200,
    28800,
    31500,
    32400,
    34200,
    36000,
    37800,
    39600,
    43200,
    45900,
    46800,
    50400,
];

function toTimeDuration(offset) {
    let x = "";
    let d = Math.abs(offset);
    x = Math.floor(d / 60 / 60).toFixed(0).padStart(2, "0");
    d %= 60*60;
    x += ":"+Math.floor(d / 60).toFixed(0).padStart(2, "0")+":"
        + (d % 60).toString().padStart(2, '0');

    return "UTC" + (offset < 0 ? "-" : "+") + x;
}
const all = {
    utc_office,
    toTimeDuration,
}

export default all;