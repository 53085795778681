import React, { useEffect, useState, useContext } from 'react';
import RuntimeParameter from './RuntimeParameter';
import EnergyStatistics from './EnergyStatistics';
import DataHistory from './DataHistory';
import AlarmHistory from './AlarmHistory';

function DataAnalysis(props) {
    const {inverterSelectOptions, SN, handleSelectSN} = props
    const [activeMenu, setActiveMenu] = useState(0) 

    return (
        <div className="data-analysis">
            <div className="data-nav"> {/* 左侧菜单栏 */}
                <ul>
                    <li className={activeMenu==0 ? 'active' : null} onClick={() => setActiveMenu(0)}>Runtime Parameter</li>
                    <li className={activeMenu==1 ? 'active' : null} onClick={() => setActiveMenu(1)}>Energy Statistics</li>
                    <li className={activeMenu==2 ? 'active' : null} onClick={() => setActiveMenu(2)}>Historical Data</li>
                    <li className={activeMenu==3 ? 'active' : null} onClick={() => setActiveMenu(3)}>Historical Event</li>
                </ul>
            </div>
            <div className="data-content">  {/* 主体内容 */}
                { 
                    activeMenu == 0 ? <RuntimeParameter inverterSelectOptions={inverterSelectOptions} SN={SN} handleSelectSN={handleSelectSN} /> : 
                    activeMenu == 1 ? <EnergyStatistics inverterSelectOptions={inverterSelectOptions} SN={SN} handleSelectSN={handleSelectSN} /> :
                    activeMenu == 2 ? <DataHistory inverterSelectOptions={inverterSelectOptions} SN={SN} handleSelectSN={handleSelectSN} /> :
                    activeMenu == 3 ? <AlarmHistory inverterSelectOptions={inverterSelectOptions} SN={SN} handleSelectSN={handleSelectSN} /> : 
                    null
                }
            </div>
        </div>
    )
}

export default DataAnalysis;