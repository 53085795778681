var Config = {
    apiUrl: "https://ess.endurenergy.com/api/",
        //"http://ec2-18-222-2-53.us-east-2.compute.amazonaws.com:3306/api/",
        //"http://127.0.0.1:8086/",
        //"https://ess.endurenergy.com/api/",
        //"http://47.99.127.57:8086/api/",
    wssUrl: "wss://ess.endurenergy.com/ws/pusher",
    wssInverterUrl: "wss://ess.endurenergy.com/ws-inverter/pusher",
    appName_General: "BMS",
    appName_IMS: "IMS",
    appName_Tip_General: "Batteries Management System",
    appName_Tip_IMS: "Inverters Management System",
    appIcon: "images/logo.webp",
    burningPacketSize: 200,
    bingMapKey: "AslkTsmeO4zglWKa9lKy9Z8moDUPDa-HsWSVcPD0AhjyXZAMrnecBgLIkyeuZLL_",
}

export default Config;