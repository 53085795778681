import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Select, DatePicker, Table } from 'antd';
import moment from 'moment';
import http from '../../../Http';
import utils from '../../../utils';
import config from '../../../Config';
import errors from '../Errors';

function getTableScroll() {
    var th = document.getElementsByClassName("ant-table-thead")[0];
    let tHeaderBottom = 0
    if (th) {
        tHeaderBottom = th.getBoundingClientRect().top
    }
    let height = `calc(100vh - ${tHeaderBottom + 180}px)`
    return height
}

function DataHistory(props) {
    const { inverterSelectOptions, SN, handleSelectSN } = props
    const setTip = useContext(utils.tipContext)

    const [dataHistoryDate, setDataHistoryDate] = useState(moment().format('YYYY-MM-DD')) //时间
    const [aWeekAgoDate, setAWeekAgoDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'))  //一周前的日期, 导出用

    const [pageNo, setPageNo] = useState(0)
    const [pageSize, setPageSize] = useState(100)
    const [rows, setRows] = useState([])
    const [rowTotal, setRowTotal] = useState(0)
    const [loading, setLoading] = useState(false)


    const refDownloader1 = useRef(null)
    const refDownloader2 = useRef(null)

    const [scrollY, setScrollY] = useState("")

    useEffect(() => {
        setScrollY(getTableScroll())
      }, [])

    //取历史数据
    useEffect(() => {
        if (!SN) return;

        let active = true;

        (async () => {
            setLoading(true);

            let resp = await http.post("integrated-inverter/history/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                SerialNumber: SN,
                STime: moment(dataHistoryDate).format("YYYY-MM-DD"),
                ETime: moment(dataHistoryDate).add(1, "days").format("YYYY-MM-DD"),
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });
                setRowTotal(0)
                setRows([])
                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [pageNo, pageSize, SN, dataHistoryDate]);

    const handleExport = () => { 
        if (!refDownloader1.current) {
            return;
        }
        refDownloader1.current.click()
    }

    const handleExportWeek = () => {
        if (!refDownloader2.current) {
            return;
        }
        refDownloader2.current.click()
    }

    return (
        <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
            <div style={{flex: "none", padding: "10px"}}>
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => handleSelectSN(value)}
                    value={SN}
                    style={{ width: '200px', marginRight: '20px' }}
                >{inverterSelectOptions}</Select>
                <DatePicker
                    defaultValue={moment()}
                    value={moment(dataHistoryDate)}
                    onChange={(_, ds) => {
                        setDataHistoryDate(ds)
                        setAWeekAgoDate(moment(ds).subtract(7, 'days').format('YYYY-MM-DD'))
                    }}
                    allowClear={false}
                />

                <Button type="primary" disabled={rows.length < 1 ? true : false} onClick={handleExportWeek} style={{ float: 'right', marginRight: '10px', backgroundColor: 'rgb(99, 173, 130)' }}>Export Data({dataHistoryDate} - {aWeekAgoDate})</Button>
                <Button type="primary" disabled={rows.length < 1 ? true : false} onClick={handleExport} style={{ float: 'right', marginRight: '10px', backgroundColor: 'rgb(99, 173, 130)' }}>Export Data</Button>
            </div>
            <div style={{flex:"auto", overflow:"hidden"}}>
                <Table
                    columns={[
                        {
                            title: "",
                            width: 40,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        },
                        {
                            title: 'SN',
                            dataIndex: "SerialNumber",
                            width: 120,
                            fixed: 'left',
                        },
                        {
                            title: 'Inverter',
                            children: [{
                                title: 'Model Name',
                                dataIndex: 'ModelName',
                                width: 90,
                            }, {
                                title: 'DSP1Version',
                                dataIndex: 'DSP1Version',
                                width: 90,
                            }, {
                                title: 'DSP2Version',
                                dataIndex: 'DSP2Version',
                                width: 90,
                            }, {
                                title: 'DSP3Version',
                                dataIndex: 'DSP3Version',
                                width: 90,
                            }, {
                                title: 'CSBVersion',
                                dataIndex: 'CSBVersion',
                                width: 90,
                            }, {
                                title: 'Mode',
                                dataIndex: 'Mode',
                                width: 90,
                            }, {
                                title: 'EMSStatus',
                                dataIndex: 'EMSStatus',
                                width: 90,
                            }, {
                                title: 'Temp(°C)',
                                dataIndex: 'Temp',
                                width: 90,
                            }, {
                                title: 'HTotal(m)',
                                dataIndex: 'HTotal',
                                width: 90,
                            }, {
                                title: 'SelfConsumptionDay(%)',
                                dataIndex: 'SelfConsumptionDay',
                                width: 90,
                            }, {
                                title: 'SelfSufficiencyDay(%)',
                                dataIndex: 'SelfSufficiencyDay',
                                width: 90,
                            }, {
                                title: 'SelfConsumptionMon(%)',
                                dataIndex: 'SelfConsumptionMon',
                                width: 90,
                            }, {
                                title: 'SelfSufficiencyMon(%)',
                                dataIndex: 'SelfSufficiencyMon',
                                width: 90,
                            }, {
                                title: 'SelfConsumptionYear(%)',
                                dataIndex: 'SelfConsumptionYear',
                                width: 90,
                            }, {
                                title: 'SelfSufficiencyYear(%)',
                                dataIndex: 'SelfSufficiencyYear',
                                width: 90,
                            }, {
                                title: 'SelfConsumptionTotal(%)',
                                dataIndex: 'SelfConsumptionTotal',
                                width: 90,
                            }, {
                                title: 'SelfSufficiencyTotal(%)',
                                dataIndex: 'SelfSufficiencyTotal',
                                width: 90,
                            }, {
                                title: 'DRMStatus',
                                dataIndex: 'DRMStatus',
                                width: 90,
                            }]
                        },
                        {
                            title: 'Photo Voltaic',
                            children: [{
                                title: 'PDC(W)',
                                dataIndex: 'PDC',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'PPeak(W)',
                                dataIndex: 'PPeak',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'EDay(kWh)',
                                dataIndex: 'EDay',
                                width: 90,
                            }, {
                                title: 'EMon(kWh)',
                                dataIndex: 'EMon',
                                width: 90,
                            }, {
                                title: 'EYear(kWh)',
                                dataIndex: 'EYear',
                                width: 90,
                            }, {
                                title: 'ETotal(kWh)',
                                dataIndex: 'ETotal',
                                width: 90,
                            }, {
                                title: 'PDCToLoad(kWh)',
                                dataIndex: 'PDCToLoad',
                                width: 90,
                            }, {
                                title: 'EDayToLoad(kWh)',
                                dataIndex: 'EDayToLoad',
                                width: 90,
                            }, {
                                title: 'EMonToLoad(kWh)',
                                dataIndex: 'EMonToLoad',
                                width: 90,
                            }, {
                                title: 'EYearToLoad(kWh)',
                                dataIndex: 'EYearToLoad',
                                width: 90,
                            }, {
                                title: 'ETotalToLoad(kWh)',
                                dataIndex: 'ETotalToLoad',
                                width: 90,
                            }, {
                                title: 'VMPPT1(V)',
                                dataIndex: 'VMPPT1',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IMPPT1(A)',
                                dataIndex: 'IMPPT1',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'VMPPT2(V)',
                                dataIndex: 'VMPPT2',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IMPPT2(A)',
                                dataIndex: 'IMPPT2',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }]
                        },
                        {
                            title: 'Battery',
                            children: [{
                                title: 'BatType',
                                dataIndex: 'BatType',
                                width: 90,
                            }, {
                                title: 'Reserved',
                                dataIndex: 'Reserved',
                                width: 90,
                            }, {
                                title: 'Ah',
                                dataIndex: 'Ah',
                                width: 90,
                            }, {
                                title: 'BMSVersion',
                                dataIndex: 'BMSVersion',
                                width: 90,
                            }, {
                                title: 'BMSStatus',
                                dataIndex: 'BMSStatus',
                                width: 90,
                            }, {
                                title: 'VBat(V)',
                                dataIndex: 'VBat',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IBat(A)',
                                dataIndex: 'IBat',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'PBat(W)',
                                dataIndex: 'PBat',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'SOC',
                                dataIndex: 'SOC',
                                width: 90,
                            }, {
                                title: 'SOH',
                                dataIndex: 'SOH',
                                width: 90,
                            }, {
                                title: 'BatTemp(°C)',
                                dataIndex: 'BatTemp',
                                width: 90,
                            }, {
                                title: 'CyclesTimes',
                                dataIndex: 'CyclesTimes',
                                width: 90,
                            }, {
                                title: 'BatErrorCode',
                                dataIndex: 'BatErrorCode',
                                width: 90,
                            }, {
                                title: 'EDayBatChrg(kWh)',
                                dataIndex: 'EDayBatChrg',
                                width: 90,
                            }, {
                                title: 'EMonBatChrg(kWh)',
                                dataIndex: 'EMonBatChrg',
                                width: 90,
                            }, {
                                title: 'EYearBatChrg(kWh)',
                                dataIndex: 'EYearBatChrg',
                                width: 90,
                            }, {
                                title: 'ETotalBatChrg(kWh)',
                                dataIndex: 'ETotalBatChrg',
                                width: 90,
                            }, {
                                title: 'EDayBatDischrg(kWh)',
                                dataIndex: 'EDayBatDischrg',
                                width: 90,
                            }, {
                                title: 'EMonBatDischrg(kWh)',
                                dataIndex: 'EMonBatDischrg',
                                width: 90,
                            }, {
                                title: 'EYearBatDischrg(kWh)',
                                dataIndex: 'EYearBatDischrg',
                                width: 90,
                            }, {
                                title: 'ETotalBatDischrg(kWh)',
                                dataIndex: 'ETotalBatDischrg',
                                width: 90,
                            }]
                        },
                        {
                            title: 'EPS',
                            children: [, {
                                title: 'PAC1EPS(W)',
                                dataIndex: 'PAC1EPS',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                    title: 'PAC2EPS(W)',
                                    dataIndex: 'PAC2EPS',
                                    width: 90,
                                    render: (v, r, index) => {
                                        if (v) {
                                            return v / 10;
                                        }
                                    }
                                }, {
                                    title: 'PAC3EPS(W)',
                                    dataIndex: 'PAC3EPS',
                                    width: 90,
                                    render: (v, r, index) => {
                                        if (v) {
                                            return v / 10;
                                        }
                                    }
                                }, {
                                    title: 'FACEPS(Hz)',
                                    dataIndex: 'FACEPS',
                                    width: 90,
                                    render: (v, r, index) => {
                                        if (v) {
                                            return v / 100;
                                        }
                                    }
                                }, {
                                    title: 'VAC1EPS(V)',
                                    dataIndex: 'VAC1EPS',
                                    width: 90,
                                    render: (v, r, index) => {
                                        if (v) {
                                            return v / 10;
                                        }
                                    }
                                }, {
                                    title: 'IAC1EPS(A)',
                                    dataIndex: 'IAC1EPS',
                                    width: 90,
                                    render: (v, r, index) => {
                                        if (v) {
                                            return v / 100;
                                        }
                                    }
                                }, {
                                    title: 'VAC2EPS(V)',
                                    dataIndex: 'VAC2EPS',
                                    width: 90,
                                    render: (v, r, index) => {
                                        if (v) {
                                            return v / 10;
                                        }
                                    }
                                }, {
                                    title: 'IAC2EPS(A)',
                                    dataIndex: 'IAC2EPS',
                                    width: 90,
                                    render: (v, r, index) => {
                                        if (v) {
                                            return v / 100;
                                        }
                                    }
                                }, {
                                    title: 'VAC3EPS(V)',
                                    dataIndex: 'VAC3EPS',
                                    width: 90,
                                    render: (v, r, index) => {
                                        if (v) {
                                            return v / 10;
                                        }
                                    }
                                }, {
                                    title: 'IAC3EPS(A)',
                                    dataIndex: 'IAC3EPS',
                                    width: 90,
                                    render: (v, r, index) => {
                                        if (v) {
                                            return v / 100;
                                        }
                                    }
                                }, {
                                    title: 'EDayEPS(kWh)',
                                    dataIndex: 'EDayEPS',
                                    width: 90,
                                }, {
                                    title: 'EMonEPS(kWh)',
                                    dataIndex: 'EMonEPS',
                                    width: 90,
                                }, {
                                    title: 'EYearEPS(kWh)',
                                    dataIndex: 'EYearEPS',
                                    width: 90,
                                }, {
                                    title: 'ETotalEPS(kWh)',
                                    dataIndex: 'ETotalEPS',
                                    width: 90,
                                }]
                        },
                        {
                            title: 'Gen',
                            children: [{
                                title: 'PAC1GEN(W)',
                                dataIndex: 'PAC1GEN',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'PAC2GEN(W)',
                                dataIndex: 'PAC2GEN',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'PAC3GEN(W)',
                                dataIndex: 'PAC3GEN',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'FACGEN(Hz)',
                                dataIndex: 'FACGEN',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'VAC1GEN(V)',
                                dataIndex: 'VAC1GEN',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IAC1GEN(A)',
                                dataIndex: 'IAC1GEN',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'VAC2GEN(V)',
                                dataIndex: 'VAC2GEN',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IAC2GEN(A)',
                                dataIndex: 'IAC2GEN',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'VAC3GEN(V)',
                                dataIndex: 'VAC3GEN',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IAC3GEN(A)',
                                dataIndex: 'IAC3GEN',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'EDayGEN(kWh)',
                                dataIndex: 'EDayGEN',
                                width: 90,
                            }, {
                                title: 'EMonGEN(kWh)',
                                dataIndex: 'EMonGEN',
                                width: 90,
                            }, {
                                title: 'EYearGEN(kWh)',
                                dataIndex: 'EYearGEN',
                                width: 90,
                            }, {
                                title: 'ETotaGEN(kWh)',
                                dataIndex: 'ETotaGEN',
                                width: 90,
                            }]
                        },
                        {
                            title: 'Load',
                            children: [{
                                title: 'PAC1LOAD(W)',
                                dataIndex: 'PAC1LOAD',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'PAC2LOAD(W)',
                                dataIndex: 'PAC2LOAD',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'PAC3LOAD(W)',
                                dataIndex: 'PAC3LOAD',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'FACLOAD(Hz)',
                                dataIndex: 'FACLOAD',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'VAC1LOAD(V)',
                                dataIndex: 'VAC1LOAD',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IAC1LOAD(A)',
                                dataIndex: 'IAC1LOAD',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'VAC2LOAD(V)',
                                dataIndex: 'VAC2LOAD',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IAC2LOAD(A)',
                                dataIndex: 'IAC2LOAD',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'VAC3LOAD(V)',
                                dataIndex: 'VAC3LOAD',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IAC3LOAD(A)',
                                dataIndex: 'IAC3LOAD',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'EDayLOAD(kWh)',
                                dataIndex: 'EDayLOAD',
                                width: 90,
                            }, {
                                title: 'EMonLOAD(kWh)',
                                dataIndex: 'EMonLOAD',
                                width: 90,
                            }, {
                                title: 'EYearLOAD(kWh)',
                                dataIndex: 'EYearLOAD',
                                width: 90,
                            }, {
                                title: 'ETotalLOAD(kWh)',
                                dataIndex: 'ETotalLOAD',
                                width: 90,
                            }]
                        },
                        {
                            title: 'Grid',
                            children: [{
                                title: 'PAC1GRID(W)',
                                dataIndex: 'PAC1GRID',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'PAC2GRID(W)',
                                dataIndex: 'PAC2GRID',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'PAC3GRID(W)',
                                dataIndex: 'PAC3GRID',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'FACGRID(Hz)',
                                dataIndex: 'FACGRID',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'VAC1GRID(V)',
                                dataIndex: 'VAC1GRID',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IAC1GRID(A)',
                                dataIndex: 'IAC1GRID',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'VAC2GRID(V)',
                                dataIndex: 'VAC2GRID',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IAC2GRID(A)',
                                dataIndex: 'IAC2GRID',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'VAC3GRID(V)',
                                dataIndex: 'VAC3GRID',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 10;
                                    }
                                }
                            }, {
                                title: 'IAC3GRID(A)',
                                dataIndex: 'IAC3GRID',
                                width: 90,
                                render: (v, r, index) => {
                                    if (v) {
                                        return v / 100;
                                    }
                                }
                            }, {
                                title: 'EDayToGrid(kWh)',
                                dataIndex: 'EDayToGrid',
                                width: 90,
                            }, {
                                title: 'EMonToGrid(kWh)',
                                dataIndex: 'EMonToGrid',
                                width: 90,
                            }, {
                                title: 'EYearToGrid(kWh)',
                                dataIndex: 'EYearToGrid',
                                width: 90,
                            }, {
                                title: 'ETotalToGrid(kWh)',
                                dataIndex: 'ETotalToGrid',
                                width: 90,
                            }, {
                                title: 'EDayFromGrid(kWh)',
                                dataIndex: 'EDayFromGrid',
                                width: 90,
                            }, {
                                title: 'EMonFromGrid(kWh)',
                                dataIndex: 'EMonFromGrid',
                                width: 90,
                            }, {
                                title: 'EYearFromGrid(kWh)',
                                dataIndex: 'EYearFromGrid',
                                width: 90,
                            }, {
                                title: 'ETotalFromGrid(kWh)',
                                dataIndex: 'ETotalFromGrid',
                                width: 90,
                            }]
                        },
                        {
                            title: 'Error',
                            width: 200,
                            render: (v, r, idx) => {
                                const lowBits = r['ErrorCode1'].toString(2);
                                const highBits = r['ErrorCode2'].toString(2);
                                var arr = [];
                                for (let i = 0, len = lowBits.length; i < len; i++) {  //处理低32位
                                    if (lowBits[i] == 1) {
                                        arr.push(errors[len - 1 - i]);
                                    }
                                }
                                for (let i = 0, len = highBits.length; i < len; i++) {  //处理高32位
                                    if (highBits[i] == 1) {
                                        arr.push(errors[32 + len - 1 - i]);
                                    }
                                }
                                return arr.length > 0 ? arr.join('; ') : '-';
                            }
                        },
                        {
                            title: 'Create Time',
                            dataIndex: 'CreateTime',
                            width: 120,
                        },
                    ]}
                    pagination={{
                        pageSizeOptions: [25, 50, 100],
                        current: pageNo + 1,
                        pageSize: pageSize,
                        total: rowTotal,
                        showSizeChanger: true,
                        showTotal: (total) => { return `Total ${total}` },
                        onShowSizeChange: (current, size) => {
                            setPageSize(size);
                        },
                        onChange: (page, pageSize) => {
                            setPageNo(page - 1);
                        }
                    }}
                    tableLayout="fixed"
                    bordered={true}
                    size="small"
                    loading={loading}
                    rowKey="ID"
                    dataSource={rows}
                    scroll={{ x: 'max-content', y: scrollY }}
                >
                </Table>
            </div>

            <a ref={refDownloader1} style={{ display: "none" }} href={config.apiUrl + "data-export/inverter/history/do?Token=" + http.token + "&SerialNumber=" + SN + "&STime=" + dataHistoryDate + "&ETime=" + moment(dataHistoryDate).add(1, 'days').format('YYYY-MM-DD')}></a>
            <a ref={refDownloader2} style={{ display: "none" }} href={config.apiUrl + "data-export/inverter/history/do?Token=" + http.token + "&SerialNumber=" + SN + "&STime=" + aWeekAgoDate + "&ETime=" + dataHistoryDate}></a>
        </div>
    )
}

export default DataHistory;