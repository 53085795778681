import React, { useEffect, useState,  useContext } from 'react';
import { Form, Select, DatePicker, Input, Button, Table } from 'antd';
import moment from 'moment';
import http from '../../Http';
import utils from '../../utils';

function getTableScroll() {
    var th = document.getElementsByClassName("ant-table-thead")[0];
    let tHeaderBottom = 0
    if (th) {
        tHeaderBottom = th.getBoundingClientRect().bottom
    }
    let height = `calc(100vh - ${tHeaderBottom + 74}px)`
    return height
}

function Outline(props) {
    const setTip = useContext(utils.tipContext)

    const [searchInvSN, setSearchInvSN] = useState('')

    const [pageNo, setPageNo] = useState(0)
    const [pageSize, setPageSize] = useState(25)
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState([])
    const [rowTotal, setRowTotal] = useState(0)

    const [scrollY, setScrollY] = useState("")

    useEffect(() => {
        setScrollY(getTableScroll())
      }, [])

    //获取告警记录列表
    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true)
            let resp = await http.post("integrated-inverter/list2", {
                PageNo: pageNo,
                PageSize: pageSize, 
                SerialNumber: searchInvSN,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (!active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load inverter list error"});
                setRowTotal(0);
                setRows([]);
                return;
            }

            setRowTotal(resp.data.length)
            setRows(resp.data)
            setLoading(false)
        })();

        return () => {
            active = false;
        };

    }, [pageNo, pageSize, searchInvSN]);

    

    return (
        <div className="inv-outline">
            <div className="inv-outline-header">
                {/* <Select 
                    showSearch
                    placeholder="Select Status"  
                    showArrow={true}
                    filterOption={true} 
                    onSelect={(value) => setSearchStatus(value)}
                    value={searchStatus} 
                    style={{ width: '200px', marginRight: '20px'}}
                >
                    <Select.Option key="0" value="all">All Status</Select.Option>
                    <Select.Option key="1" value="online">Online</Select.Option>
                    <Select.Option key="2" value="offline">Offline</Select.Option>
                    <Select.Option key="3" value="alarm">Alarm</Select.Option>
                    <Select.Option key="4" value="error">Error</Select.Option>
                </Select> */}
                <Input.Search
                    placeholder="Enter Inverter SN"
                    allowClear
                    enterButton="Search"
                    onSearch={(value) => setSearchInvSN(value)}
                    style={{width: 250}}
                />
            </div>
            <div className="inv-outline-main">
                <Table
                    columns={[
                        {
                            title: "",
                            width: 40,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        },
                        {
                            title: 'SN',
                            dataIndex: "SerialName",
                            width: 120,
                            fixed: 'left',
                        },
                        {
                            title: 'Status',
                            width: 80,
                            render: (r) => {
                                return r["ErrorCode1"]&&r['ErrorCode2'] ? 
                                    <div><img src="images/inverter/status_warning.png" width="16" height="16" style={{marginRight: '5px'}} />Alarm</div> : 
                                    r["IsOnline"]==1 ? 
                                    <div><img src="images/inverter/status_online.png" width="16" height="16" style={{marginRight: '5px'}} />Online</div> :
                                    r["IsOnline"]==0 ? <div><img src="images/inverter/status_offline.png" width="16" height="16" style={{marginRight: '5px'}} />Offline</div> : ''
                            },
                        },
                        {
                            title: 'SolarPower',
                            dataIndex: 'Pdc',
                            width: 60,
                            render: r => r ? r + ' W' : ''
                        },
                        {
                            title: 'ChargePower',
                            dataIndex: 'PBat',
                            width: 60,
                            render: r => r ? r + ' W' : ''
                        },
                        {
                            title: 'DischargePower',
                            dataIndex: 'PBat',
                            width: 60,
                            render: r => r ? r + ' W' : ''
                        },
                        {
                            title: 'Load',
                            dataIndex: 'PDCToLoad',
                            width: 60,
                            render: r => r ? r + ' W' : ''
                        },
                        {
                            title: 'SolarYielding',
                            dataIndex: 'ETotal',
                            width: 80,
                            render: r => r ? r + ' kWh' : ''
                        },
                        {
                            title: 'BatteryDischarge',
                            dataIndex: 'ETotalBatDischrg',
                            width: 80,
                            render: r => r ? r + ' kWh' : ''
                        },
                        {
                            title: 'FeedEnergy',
                            dataIndex: 'ETotalToGRID',
                            width: 80,
                            render: r => r ? r + ' kWh' : ''
                        },
                        {
                            title: 'ConsumptionEnergy',
                            dataIndex: 'ETotalLOAD',
                            width: 80,
                            render: r => r ? r + ' kWh' : ''
                        },
                    ]}
                    pagination={{
                        pageSizeOptions: [25,50,100],
                        current: pageNo+1,
                        pageSize: pageSize,
                        total: rowTotal,
                        showSizeChanger: true,
                        showTotal: (total) => {return `Total ${total}`},
                        onShowSizeChange: (current, size) => {
                            setPageSize(size);
                        },
                        onChange: (page, pageSize) => {
                            setPageNo(page-1);                                
                        }
                    }}
                    tableLayout="fixed"
                    bordered={true}
                    size="small"
                    loading={loading}
                    rowKey="ID"
                    dataSource={rows}
                    scroll={{x: "max-content", y:scrollY}}
                >
                </Table>
            </div>
        </div>
    )
}

export default Outline;