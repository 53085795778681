import { createContext } from 'react';
import config from './Config'



async function post (path, params) {
    let fd = [];
    
    Object.keys(params).forEach((key)=>{
        fd.push(`${key}=${params[key]}`)
    })
    let resp = await fetch (`${config.apiUrl}${path}`, {
        method: "POST",
        mode: "cors",
        cache: 'no-cache',
        redirect: 'follow',
        credentials: 'include',
        headers:new Headers({
            'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8',
            'Token': obj.token?obj.token:"",
        }),
        body: fd.join("&")
    });
    // resp.headers.forEach((v, k)=>{
    //     console.log(k,'=>', v);
    // })
    if (resp && resp.headers.get("token")) {
        obj.token = resp.headers.get("token");
    }

    return resp;
}

async function postJSON (path, params) {
    // let fd = [];
    
    // Object.keys(params).forEach((key)=>{
    //     fd.push(`${key}=${params[key]}`)
    // })
    let resp = await fetch (`${config.apiUrl}${path}`, {
        method: "POST",
        mode: "cors",
        cache: 'no-cache',
        redirect: 'follow',
        credentials: 'include',
        headers:new Headers({
            'Content-Type':'application/json;charset=UTF-8',
            'Token': obj.token?obj.token:"",
        }),
        body: JSON.stringify(params)
    });
    // resp.headers.forEach((v, k)=>{
    //     console.log(k,'=>', v);
    // })
    if (resp && resp.headers.get("token")) {
        obj.token = resp.headers.get("token");
    }

    return resp;
}

export const ThemeContext = createContext(null);

let obj = {
    post: post,
    postJSON: postJSON,
};

export default obj;