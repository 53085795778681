import http from '../Http';
import { useContext, useState, useRef } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Select, Switch } from 'antd';
import { PlusOutlined, MinusCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import timezone from '../utils/timezone'
import GPSGetter from "../DistributorManagement/GPSGetter"


function EndUserAdder(props) {
    const setTip = useContext(utils.tipContext);

    const { show, onClose } = props;

    const [form] = Form.useForm();

    const [deviceSelectOptions, setDeviceSelectOptions] = useState([]);
    const [showGPSGetter, setShowGPSGetter] = useState(false);
    const [lng, setLng] = useState("");
    const [lat, setLat] = useState("");

    const refTimerDeviceSearcher = useRef(null);
    const refDeviceSearchValue = useRef(null);

    const [timezoneSelectOptions, setTimezoneSelectOptions] = useState([]);
    const refTimerTimezoneSearcher = useRef(null);
    const refTimezoneSearchValue = useRef(null);

    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => { return null });

        if (ps === null) {
            return;
        }

        ps.Devices = ps.Devices && ps.Devices.length > 0 ? ps.Devices.map((v) => {
            return `${v.ID}`;
        }).join(",") : "";
        ps.Enabled = ps.Enabled ? 1 : 0;
        ps.Name = ps.Name || "";
        ps.Phone = ps.Phone || "";
        ps.EMail = ps.EMail || "";
        ps.Address = ps.Address || "";
        ps.Latitude *= 1000000;
        ps.Longitude *= 1000000;


        const resp = await http.post("user/management/end-user/add", ps).then(async (r) => {
            return await r.json();
        }).catch((e) => {
            console.log("[UserAdd]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "add error" });
                return
            }

            setTip({ open: true, severity: 'success', msg: 'add success' });

            ps.CreateTime = resp.data.CreateTime;
            ps.DistributorName = (resp.data.DistributorName || "");
            ps.Role = "EndUser";
            onClose(ps);
        } catch (e) {
            console.log("[UserAdd]", e);

            setTip({ open: true, severity: "error", msg: "add error" });
        }
    }

    const handleSearchDevice = (v, index) => {
        if (refTimerDeviceSearcher.current != null) {
            clearTimeout(refTimerDeviceSearcher.current);
        }
        refDeviceSearchValue.current = v;

        const ids = form.getFieldValue("Devices");
        let id = "";
        ids.forEach((v, idx) => {
            if (idx === index) {
                return;
            }

            if (v && v.ID && v.ID.length >= 19) {
                id = v.ID;
            }
        })

        refTimerDeviceSearcher.current = setTimeout(async () => {
            const resp = await http.post("device/id-name/list", {
                DistributorIsTheSameAsTheDeviceID: id,
                SN: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[UserAdd]", e);
                return null;
            });

            if (refDeviceSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load device list error" });
                    return;
                }

                const ls = resp.data.map(({ SN, ID, UserID }) => {
                    var rBy = "";
                    if (UserID != null) {
                        rBy = UserID;
                        if (rBy.length > 3) {
                            rBy = rBy.substring(0, 3).padEnd(rBy.length, "*");
                        }
                        rBy = "(RegisteredBy: " + rBy + ")";
                    }
                    return (
                        <Select.Option key={ID} value={ID} disabled={UserID != null}>{SN + rBy}</Select.Option>
                    );
                });
                setDeviceSelectOptions(ls);
            } catch (e) {
                console.log("[UserAdd]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    const handleGetGPS = () => {
        setShowGPSGetter(true);
    }

    const handleGPSGetterClose = (location) => {
        setShowGPSGetter(false);

        if (location) {
            const lat = parseFloat(location.Latitude.toFixed(6));
            const lng = parseFloat(location.Longitude.toFixed(6));
            setLat(lat);
            setLng(lng);

            form.setFieldsValue({ Latitude: lat, Longitude: lng });
        }
    }

    const handleSearchTimezone = (v) => {
        if (refTimerTimezoneSearcher.current != null) {
            clearTimeout(refTimerTimezoneSearcher.current);
        }
        refTimezoneSearchValue.current = v;


        refTimerTimezoneSearcher.current = setTimeout(async () => {
            const resp = await http.post("timezone/list", {
                Timezone: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refTimezoneSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load timezone list error" });
                    return;
                }

                const ls = resp.data.map(({ ZoneName, GmtOffset }) => {
                    var n = parseInt(GmtOffset);
                    var tz = "UTC+";
                    if (n < 0) {
                        tz = "UTC-";
                        n = Math.abs(n);
                    }
                    tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                    return (
                        <Select.Option key={ZoneName} value={ZoneName}>{ZoneName}({tz})</Select.Option>
                    );
                });
                setTimezoneSelectOptions(ls);
            } catch (e) {
                console.log("[Device-Adder]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    return (<Modal
        title="Add End User"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button
                key="Clear"
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            form={form}
            initialValues={{
                Enabled: true,
                Latitude: lat,
                Longitude: lng
            }}
        >
            <Form.Item label="Devices" rules={[{ required: true }]}>
                <Form.List name="Devices" label="Devices">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
                                <div key={key} style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'ID']}
                                        fieldKey={[fieldKey, 'ID']}
                                        rules={[{ required: true, message: 'Missing SN' }]}
                                        style={{
                                            flex: "1 1 150px",
                                        }}
                                    >
                                        <Select
                                            // style={{minWidth: 150}}
                                            showSearch
                                            placeholder="Device SN"
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={(value) => {
                                                handleSearchDevice(value, idx);
                                            }}
                                        >{deviceSelectOptions}</Select>
                                    </Form.Item>
                                    <div style={{ marginBottom: "24px" }}>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </div>
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Register Device
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            <Form.Item
                name="Name"
                label="Title"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="ID"
                label="UserID"
                rules={[{ required: true, min: 3 }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="Password"
                label="Password"
                rules={[{ required: true, min: 6 }]}
            >
                <Input placeholder="Login Password" />
            </Form.Item>
            <Form.Item
                name="Phone"
                label="Phone"
            >
                <Input placeholder="Phone Number" />
            </Form.Item>
            <Form.Item
                name="EMail"
                label="E-Mail"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="Address"
                label="Address"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="Timezone"
                label="Timezone"
                rules={[{ required: true, type: 'string' }]}
            >
                <Select
                    showSearch
                    placeholder="Timezone"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchTimezone}
                >{timezoneSelectOptions}</Select>
            </Form.Item>
            <Form.Item
                name="Enabled"
                label="Enabled"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label="GPS"
            >
                <Form.Item
                    name="Longitude"
                    rules={[{ required: true, type: 'number' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)' }}
                >
                    <Input placeholder="Logitude, e.g.: 118.123456" />
                </Form.Item>
                <Form.Item
                    name="Latitude"
                    rules={[{ required: true, type: 'number' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)' }}
                >
                    <Input placeholder="Latitude, e.g.: 31.123456" />
                </Form.Item>
                <Button onClick={handleGetGPS} icon={<EnvironmentOutlined />} />
            </Form.Item>
        </Form>
        <GPSGetter show={showGPSGetter} onClose={handleGPSGetterClose} />
    </Modal>);
}

export default EndUserAdder;