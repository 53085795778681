import React, { useEffect, useState, useRef, useContext } from 'react';
import { Form, Select, DatePicker, Radio, Button } from 'antd';
import moment from 'moment';
import http from '../../../Http';
import utils from '../../../utils';
import ReactECharts from 'echarts-for-react';

const pvOption = {
    tooltip: {
        trigger: 'axis'
    },
    xAxis: {
        type: 'category',
        axisLabel: {
            formatter: (value) => {
                return moment(value).format('HH:mm')
            }
        }
    },
    yAxis: {
        type: 'value',
    },
    color: 'rgb(98, 158, 14)',
    series: [
        {
            name: '', 
            type: 'line', 
            symbol: 'none',
            lineStyle: {width: 2}, 
            smooth: true, 
            data: [], 
            markLine: {
                symbol: 'none',
                data: [{type: 'average', name: 'Avg'}],
                lineStyle: {color: 'blue', type: 'dashed'},
                label: {formatter: 'AVG: {c}', position: 'insideStartTop', color: 'rgb(98, 158, 14)', fontWeight: 'bold'}
            }
        }
    ]
}

const batOption = {
    tooltip: {
        trigger: 'axis'
    },
    xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
            formatter: (value) => {
                return moment(value).format('HH:mm')
            }
        }
    },
    yAxis: {
        type: 'value',
    },
    color: 'rgb(98, 158, 14)',
    series: [
        {
            name: '', 
            type: 'line', 
            symbol: 'none',
            lineStyle: {width: 2}, 
            smooth: true, 
            data: [], 
            markLine: {
                symbol: 'none',
                data: [{type: 'average', name: 'Avg'}],
                lineStyle: {color: 'blue', type: 'dashed'},
                label: {formatter: 'AVG: {c}', position: 'insideStartTop', color: 'rgb(98, 158, 14)', fontWeight: 'bold'}
            }
        }
    ]
}

const acOption = {
    tooltip: {
        trigger: 'axis'
    },
    xAxis: {
        type: 'category',
        axisLabel: {
            formatter: (value) => {
                return moment(value).format('HH:mm')
            }
        }
    },
    yAxis: {
        type: 'value',
    },
    color: 'rgb(98, 158, 14)',
    series: [
        {
            name: '', 
            type: 'line', 
            symbol: 'none',
            lineStyle: {width: 2}, 
            smooth: true, 
            data: [], 
            markLine: {
                symbol: 'none',
                data: [{type: 'average', name: 'Avg'}],
                lineStyle: {color: 'blue', type: 'dashed'},
                label: {formatter: 'AVG: {c}', position: 'insideStartTop', color: 'rgb(98, 158, 14)', fontWeight: 'bold'}
            }
        }
    ]
}

const epsOption = {
    tooltip: {
        trigger: 'axis'
    },
    xAxis: {
        type: 'category',
        axisLabel: {
            formatter: (value) => {
                return moment(value).format('HH:mm')
            }
        }
    },
    yAxis: {
        type: 'value',
    },
    color: 'rgb(98, 158, 14)',
    series: [
        {
            name: '', 
            type: 'line', 
            symbol: 'none',
            lineStyle: {width: 2}, 
            smooth: true, 
            data: [], 
            markLine: {
                symbol: 'none',
                data: [{type: 'average', name: 'Avg'}],
                lineStyle: {color: 'blue', type: 'dashed'},
                label: {formatter: 'AVG: {c}', position: 'insideStartTop', color: 'rgb(98, 158, 14)', fontWeight: 'bold'}
            }
        }
    ]
}

function RuntimeParameter(props) {
    const {inverterSelectOptions, SN, handleSelectSN} = props
    const [invData, setInvData] = useState(null)  //逆变器数据
    const setTip = useContext(utils.tipContext)

    const [parameterDate, setParameterDate] = useState(moment().format('YYYY-MM-DD')) //时间

    const [activePV, setActivePV] = useState(0)
    const [activeBat, setActiveBat] = useState(0)
    const [activeAC, setActiveAC] = useState(0)
    const [activeEps, setActiveEps] = useState(0)

    const pvChart = useRef(null)
    const batChart = useRef(null)
    const acChart = useRef(null)
    const epsChart = useRef(null)

    //获取日数据
    useEffect(() => {
        if(!SN) return;
        let active = true;
        (async () => {
            let resp = await http.post("integrated-inverter/report/daily/report", {
                SerialNumber: SN,
                Date: parameterDate, 
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                return null;
            });

            if (!active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {  //请求日数据失败
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load inverter record error"});
                return;
            } else { 
                setInvData(resp.data)
            }
        })();

        return () => {
            active = false;
        };

    }, [SN, parameterDate]);

    //draw PV Chart
    useEffect(() => {
        if(!invData) {
            pvOption.xAxis.data = []
            pvOption.series[0].data = []
        } else {
            const xData = []
            const yData = []
            //遍历结果，给坐标和数据赋值
            invData.forEach(v => {
                xData.push(moment(v['YMDH']).format('YYYY-MM-DD HH:mm'))
                yData.push(activePV==0 ? v.VMPPT1/10 : 
                        activePV==1 ? v.VMPPT2/10 :
                        activePV==2 ? v.VMPPT3/10 :
                        activePV==3 ? v.VMPPT4/10 :
                        activePV==4 ? Math.round(v.VMPPT1*v.IMPPT1/10)/100 :
                        activePV==5 ? Math.round(v.VMPPT2*v.IMPPT2/10)/100 :
                        activePV==6 ? Math.round(v.VMPPT3*v.IMPPT3/10)/100 :
                        activePV==7 ? Math.round(v.VMPPT4*v.IMPPT4/10)/100 : null)
            })
            //x轴坐标
            pvOption.xAxis.data = xData;
            //数据
            pvOption.series[0] =
                activePV==0 ? {...pvOption.series[0], name: 'Vpv1', data: yData} : 
                activePV==1 ? {...pvOption.series[0], name: 'Vpv2', data: yData} :
                activePV==2 ? {...pvOption.series[0], name: 'Vpv3', data: yData} :
                activePV==3 ? {...pvOption.series[0], name: 'Vpv4', data: yData} :
                activePV==4 ? {...pvOption.series[0], name: 'Ppv1', data: yData} : 
                activePV==5 ? {...pvOption.series[0], name: 'Ppv2', data: yData} :
                activePV==6 ? {...pvOption.series[0], name: 'Ppv3', data: yData} :
                activePV==7 ? {...pvOption.series[0], name: 'Ppv4', data: yData} : 
                null
        }
        const instance = pvChart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(pvOption, true);
        }
    }, [invData, activePV])

    //draw Battery Chart
    useEffect(() => {
        if(!invData) {
            batOption.xAxis.data = []
            batOption.series[0].data = []
        } else {
            const xData = []
            const yData = []
            //遍历结果，给坐标和数据赋值
            invData.forEach(v => {
                xData.push(moment(v['YMDH']).format('YYYY-MM-DD HH:mm'))
                yData.push(activeBat==0 ? v.SOC : 
                        activeBat==1 ? v.VBat/10 :
                        activeBat==2 ? v.EDayBatChrg :
                        activeBat==3 ? v.EDayBatDischrg : null)
            })
            //x轴坐标
            batOption.xAxis.data = xData;
            //数据
            batOption.series[0] =
                activeBat==0 ? {...batOption.series[0], name: 'SOC', data: yData} : 
                activeBat==1 ? {...batOption.series[0], name: 'vBat', data: yData} :
                activeBat==2 ? {...batOption.series[0], name: 'Charge Power', data: yData} :
                activeBat==3 ? {...batOption.series[0], name: 'Discharge Power', data: yData} : null
        }

        const instance = batChart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(batOption, true);
        }
    }, [invData, activeBat])

    //draw AC Chart
    useEffect(() => {
        if(!invData) {
            acOption.xAxis.data = []
            acOption.series[0].data = []
        } else {
            const xData = []
            const yData = []
            //遍历结果，给坐标和数据赋值
            invData.forEach(v => {
                xData.push(moment(v['YMDH']).format('YYYY-MM-DD HH:mm'))
                yData.push(activeAC==0 ? v.VAC1GRID/10 : 
                        activeAC==1 ? v.EDayToGRID :
                        activeAC==2 ? v.PAC1GRID/10 : null)
            })
            //x轴坐标
            acOption.xAxis.data = xData
            //数据
            acOption.series[0] = 
                activeAC==0 ? {...acOption.series[0], name: 'Vacr', data: yData} :
                activeAC==1 ? {...acOption.series[0], name: 'Qac', data: yData} :
                activeAC==2 ? {...acOption.series[0], name: 'PToGrid', data: yData} : null
        }

        const instance = acChart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(acOption, true);
        }
    }, [invData, activeAC])

    //draw Eps Chart
    useEffect(() => {
        if(!invData) {
            epsOption.xAxis.data = []
            epsOption.series[0].data = []
        } else {
            const xData = []
            const yData = []
            //遍历结果，给坐标和数据赋值
            invData.forEach(v => {
                xData.push(moment(v['YMDH']).format('YYYY-MM-DD HH:mm'))
                yData.push(activeEps==0 ? v.VAC1EPS/10 : 
                        activeEps==1 ? v.FACEPS/100 :
                        activeEps==2 ? v.PAC1EPS/10 :
                        activeEps==3 ? Math.round(v.VAC1EPS*v.IAC1EPS/10)/100 : null)
            })
            //x轴坐标
            epsOption.xAxis.data = xData;
            //数据
            epsOption.series[0] = 
                activeEps==0 ? {...epsOption.series[0], name: 'Vepsr', data: yData} : 
                activeEps==1 ? {...epsOption.series[0], name: 'Feps', data: yData} : 
                activeEps==2 ? {...epsOption.series[0], name: 'Peps', data: yData} : 
                activeEps==3 ? {...epsOption.series[0], name: 'Seps', data: yData} : null
        }

        const instance = epsChart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(epsOption, true);
        }
    }, [invData, activeEps])

    return (
        <div className="runtime-parameter">
            <div className="runtime-parameter-header">
                <span>Select Inverter</span>
                <Select
                    showSearch
                    placeholder="Enter Inverter Name"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={true}
                    onSelect={(value) => handleSelectSN(value)}
                    value={SN}
                    style={{ width: '200px', marginRight: '20px' }}
                >{inverterSelectOptions}</Select>
                <DatePicker
                    defaultValue={moment()}
                    value={moment(parameterDate)}
                    onChange={(_, ds) => setParameterDate(ds)}
                    allowClear={false}
                /> 
            </div>
            <div className="runtime-parameter-main">
                <div className="side side-left">
                    <div className="title">PV Side</div>
                    <div className="item">
                        <ul>
                            <li className={activePV==0 ? 'active' : null} onClick={() => setActivePV(0)}>Vpv1(V)</li>
                            <li className={activePV==1 ? 'active' : null} style={{display: invData&&invData[0]&&('VMPPT2' in invData[0]) ? 'block' : 'none'}}  onClick={() => setActivePV(1)}>Vpv2(V)</li>
                            <li className={activePV==2 ? 'active' : null} style={{display: invData&&invData[0]&&('VMPPT3' in invData[0]) ? 'block' : 'none'}}  onClick={() => setActivePV(2)}>Vpv3(V)</li>
                            <li className={activePV==3 ? 'active' : null} style={{display: invData&&invData[0]&&('VMPPT4' in invData[0]) ? 'block' : 'none'}}  onClick={() => setActivePV(3)}>Vpv4(V)</li>
                            <li className={activePV==4 ? 'active' : null}  onClick={() => setActivePV(4)}>Ppv1(W)</li>
                            <li className={activePV==5 ? 'active' : null} style={{display: invData&&invData[0]&&('VMPPT2' in invData[0]) ? 'block' : 'none'}}  onClick={() => setActivePV(5)}>Ppv2(W)</li>
                            <li className={activePV==6 ? 'active' : null} style={{display: invData&&invData[0]&&('VMPPT3' in invData[0]) ? 'block' : 'none'}}  onClick={() => setActivePV(6)}>Ppv3(W)</li>
                            <li className={activePV==7 ? 'active' : null} style={{display: invData&&invData[0]&&('VMPPT4' in invData[0]) ? 'block' : 'none'}}  onClick={() => setActivePV(7)}>Ppv4(W)</li>
                        </ul>
                    </div>
                </div>
                <div className="box box-left">
                    <div className="title">
                        {'Vpv1(V)'} - ({SN}, {parameterDate})
                    </div>
                    <div className="chart">
                        <ReactECharts ref={pvChart} option={pvOption} style={{height: 280}} />
                    </div>
                </div>
                <div className="side side-right">
                    <div className="title">Battery</div>
                    <div className="item">
                        <ul>
                        <li className={activeBat==0 ? 'active' : null} onClick={() => setActiveBat(0)}>SOC(%)</li>
                        <li className={activeBat==1 ? 'active' : null} onClick={() => setActiveBat(1)}>vBat(V)</li>
                        <li className={activeBat==2 ? 'active' : null} onClick={() => setActiveBat(2)}>Charge Power(W)</li>
                        <li className={activeBat==3 ? 'active' : null} onClick={() => setActiveBat(3)}>DisCharge Power(W)</li>
                        </ul>
                    </div>
                </div>
                <div className="box">
                    <div className="title">
                        {'SOC(%)'} - ({SN}, {parameterDate})
                    </div>
                    <div className="chart">
                        <ReactECharts ref={batChart} option={batOption} style={{height: 280}} />
                    </div>
                </div>
                <div style={{clear: 'both'}}></div>
                <div className="side side-left">
                    <div className="title">AC Side</div>
                    <div className="item">
                        <ul>
                        <li className={activeAC==0 ? 'active' : null} onClick={() => setActiveAC(0)}>Vacr(V)</li>
                        <li className={activeAC==1 ? 'active' : null} onClick={() => setActiveAC(1)}>Qac(Var)</li>
                        <li className={activeAC==2 ? 'active' : null} onClick={() => setActiveAC(2)}>pToGrid(W)</li>
                        </ul>
                    </div>
                </div>
                <div className="box box-left">
                    <div className="title">
                        {'Vacr(V)'} - ({SN}, {parameterDate})
                    </div>
                    <div className="chart">
                        <ReactECharts ref={acChart} option={acOption} style={{height: 280}} />
                    </div>
                </div>
                <div className="side side-right">
                    <div className="title">Eps</div>
                    <div className="item">
                        <ul>
                        <li className={activeEps==0 ? 'active' : null} onClick={() => setActiveEps(0)}>Vepsr(V)</li>
                        <li className={activeEps==1 ? 'active' : null} onClick={() => setActiveEps(1)}>Feps(Hz)</li>
                        <li className={activeEps==2 ? 'active' : null} onClick={() => setActiveEps(2)}>Peps(W)</li>
                        <li className={activeEps==3 ? 'active' : null} onClick={() => setActiveEps(3)}>Seps(VA)</li>
                        </ul>
                    </div>
                </div>
                <div className="box">
                    <div className="title">
                        {'Peps(W)'} - ({SN}, {parameterDate})
                    </div>
                    <div className="chart">
                        <ReactECharts ref={epsChart} option={epsOption} style={{height: 280}} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RuntimeParameter;