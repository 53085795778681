const Errors = {
    0: 'Grid over voltage',
    1: 'Grid under voltage',
    2: 'Grid absent',
    3: 'Grid over frequency',
    4: 'Grid under frequency',
    5: 'PV over voltage',
    6: 'PV insulation abnormal',
    7: 'Leakage current abnormal',
    8: 'Inverter in power limit state',
    9: 'Internal power supply abnormal',
    10: 'PV string abnormal',
    11: 'PV under voltage',
    12: 'PV irradiation weak',
    13: 'Grid abnormal',
    14: 'Arc fault detection',
    15: 'Grid over mean voltage',
    16: 'Inverter over dc-bias current',
    17: 'Inverter relay abnormal',
    18: 'Remote off',
    19: 'Inverter over temperature',
    20: 'GFCI abnormal',
    21: 'PV string reverse',
    22: 'System type error',
    23: 'Fan abnormal',
    24: 'Unbalance Dc-link voltage',
    25: 'Dc-link over voltage',
    26: 'Internal communication error',
    27: 'Software incompatibility',
    28: 'Internal storage error',
    29: 'Data inconsistency',
    30: 'Inverter abnormal',
    31: 'Boost abnormal',
    //'',
    33: 'Logger/E-Display EEPROM fail',
    34: 'Arc fault detection',
    35: 'Single tracker detect warning',
    36: 'Inverter output power too low warning',
    37: 'Data logger lost',
    38: 'Meter lost',
    39: 'Inverter lost',
    //'',
    //'',
    //'',
    //'',
    //'',
    //'',
    //'',
    //'',
    48: 'Battery absent',
    49: 'Battery over voltage',
    50: 'Battery under voltage',
    51: 'Battery discharger over current',
    52: 'Battery over tempreture',
    53: 'Battery under tempreture',
    54: 'Neutral live wire reversed',
    55: 'EPS output voltage abnormal',
    56: 'Communication error(Inverter-BMS)',
    57: 'Internal communication loss(E-M)',
    58: 'Internal communication loss(M-D)',
    59: 'Dcdc abnormal',
    60: 'EPS over dc-bias voltage',
    61: 'EPS short circuit',
    62: 'EPS over load',
};

export default Errors;