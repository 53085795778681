import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../utils';
import { Table, Input, Button, Modal, Form, Row, Col, Checkbox, Select, Popover, Menu, Dropdown, Tabs } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, CheckOutlined, CloseOutlined, UpOutlined, DownOutlined, UserAddOutlined  } from '@ant-design/icons';
import EndUserAdder from './EndUserAdder';
import DistributorUserAdder from './DistributorUserAdder';
import EndUserModifier from './EndUserModifier';
import DistributorUserModifier from './DistributorUserModifier';

function UserManagement(props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        Roles: "",
        UserID: "",
        UerName: "",
        DistributorName: "",
        Enabled: ""
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [modifyRowIndex, setModifyRowIndex] = useState(null);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [expand, setExpand] = useState(false);
    const [showEndUserAdder, setShowEndUserAdder] = useState(false);
    const [showDistributorUserAdder, setShowDistributorUserAdder] = useState(false);
    const [showEndUserModifier, setShowEndUserModifier] = useState(false);
    const [showDistributorUserModifier, setShowDistributorUserModifier] = useState(false);

    const refTableContainer = useRef();
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    const [form] = Form.useForm();

    useEffect(() => {
        let active = true;
        console.log("UserManagement Effect");

        (async () => {
            setLoading(true);
            
            let resp = await http.post("user/management/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[UserManagement]", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setRowTotal(0)
                setRows([]);
        
                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("UserManagement Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }
        
        let obj = document.createElement('object');
        el.objResizer = obj; 
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (! el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const calcTableBodyHeight = () => {
        if (! refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return  h>=54 ? h - 54 : h /2;
    }
    
    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async(r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[UserManagement]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load distributor list error"});
                    return;
                }

                const ls = resp.data.map(({Name, ID}) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch(e) {
                console.log("[UserManagement]", e);

                setTip({open:true, severity:"error", msg: "load distributor list error"});
            }
        }, 300);
    }

    const renderAction = (v, r, idx) => {
        return (
            <>
                <Button onClick={()=>{handleModify(idx)}} type="link" icon={<EditOutlined  size="small"/>} />
                <Button disabled={r.IsDistributorMainAccount === "1" ? true : false} onClick={()=>{handleDelete(r.ID, r.ID, idx)}} type="link" icon={<DeleteOutlined  size="small"/>} />
            </>
        )
    }

    const handleSearch = () => {
        setSelectedKeys([]);
        setPageNo(0);

        const ps = form.getFieldsValue();
        ps.Roles = (ps.Roles || []).join(",");
        ps.UserID = ps.UserID || "";
        ps.UserName = ps.UserName || "";
        ps.DistributorName = ps.DistributorName || "";
        ps.Enabled = ps.Enabled ? (ps.Enabled.indexOf("Enabled")>-1 && ps.Enabled.indexOf("Disabled")> -1 ? "" : ps.Enabled.indexOf("Enabled")>-1 ? 1 : 0) : "";
        setParams(ps);
    }

    const handleSelect = (selectedRowKeys, selectedRows) => {
        setSelectedKeys([...selectedRowKeys]);
    }

    const handleAddEndUser = () => {
        setShowEndUserAdder(true);
    }

    const handleAddDistributorUser = () => {
        setShowDistributorUserAdder(true);
    }

    const handleEndUserAdderClose = (row) => {
        setShowEndUserAdder(false);

        if (! row) {
            return;
        }

        document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        setSelectedKeys([row.ID]);
        setRows([row, ...rows]);
        setRowTotal(rowTotal+1);
    }

    const handleModify = (rowIndex) => {
        setModifyRowIndex(rowIndex);
        //setShowModify(true);
        const row = rows[rowIndex];
        if (row && row.Role === "EndUser") {
            setShowEndUserModifier(true)
        } else if (row && row.Role === "DistributorUser") {
            setShowDistributorUserModifier(true)
        }
    }

    const handleDistributorUserAdderClose = (row) => {
        setShowDistributorUserAdder(false);

        if (! row) {
            return;
        }

        document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        setSelectedKeys([row.ID]);
        setRows([row, ...rows]);
        setRowTotal(rowTotal+1);
    }

    const handleModifyingClose = (row) => {
        setShowEndUserModifier(false);
        setShowDistributorUserModifier(false);
        setModifyRowIndex(null);

        if (! row) {
            return;
        }

        setSelectedKeys([row.ID]);
        const rw = {...rows[modifyRowIndex], ...row}
        setRows([...rows.slice(0, modifyRowIndex), rw, ...rows.slice(modifyRowIndex+1)]);
    }

    const handleDelete = async (ID, Name, idx) => {
        Modal.confirm({
            title: 'Do you Want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: Name,
            onOk: async () => {
                try {
                    const resp = await http.post("user/management/delete", {UserID: ID}).then(async(r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[UserManagement]", e);
                        return null;
                    });
        
                    if (resp.code !== 200) {
                        setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "delete error"});
                        return
                    }
                    setTip({open: true, severity: 'success', msg: 'delete success'});
        
                    setRows([...rows.slice(0,idx), ...rows.slice(idx+1)]);
                    setRowTotal(rowTotal-1);
                    selectedKeys.every((v,i) => {
                        if (v === ID) {
                            setSelectedKeys([...selectedKeys.slice(0,i), ...selectedKeys.slice(i+1)]);
                            return false;
                        }
        
                        return true;
                    })
                } catch(e) {
                    console.log("[UserManagement]", e);
        
                    setTip({open:true, severity:"error", msg: "delete error"});
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleDeleteAllOfSelected = async () => {
        if (selectedKeys.length < 1) {
            return;
        }
        let ns = selectedKeys.map((v) => {
            return (<label>{v}</label>); 
        });
        if (ns.length > 10) {
            ns = ns.slice(0, 10);
            ns.push(<lable>...</lable>);
        }

        Modal.confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: (<div style={{display:'flex', flexDirection:'column'}}>{ns}</div>),
            onOk: async () => {
                try {
                    const resp = await http.post("user/management/multi/delete", {UserIDs: selectedKeys.join(",")}).then(async(r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[UserManagement]", e);
                        return null;
                    });
        
                    if (resp.code !== 200) {
                        setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "delete error"});
                        return
                    }
                    setTip({open: true, severity: 'success', msg: 'delete success'});
        
                    let rs = [];
                    rows.forEach((v, i) => {
                        const ID = v.ID;
                        let ok = false;
                        selectedKeys.every((ID2) => {
                            if (ID === ID2) {
                                ok = true;
                                return false;
                            }
        
                            return true;
                        });
                        if (!ok) {
                            rs.push(v);
                        } 
                    });
                    setRows(rs);
                    setRowTotal(rowTotal - selectedKeys.length);
                    setSelectedKeys([]);
                } catch(e) {
                    console.log("[UserManagement]", e);
        
                    setTip({open:true, severity:"error", msg: "delete error"});
                }
            },
            onCancel() {
                //
            },
        });
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => { 
        if(e.code == "Enter") {
            handleSearch()
        }
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form 
                    form={form}
                    initialValues={{
                        Roles: ["AdminUser", 'DistributorUser', "EndUser"],  
                        Enabled: ["Enabled", 'Disabled'],
                    }}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={8} key="Roles">
                            <Form.Item 
                                name="Roles"
                                label=""
                            >
                                <Checkbox.Group options={["AdminUser", 'DistributorUser', "EndUser"]} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="UserName" >
                            <Form.Item 
                                name="UserName"
                                label="UserName"
                            >
                                <Input placeholder="Enter User ID" onPressEnter={handleSearch}/>
                            </Form.Item>
                        </Col>
                        <Col span={8} key="UserID" >
                            <Form.Item 
                                name="UserID"
                                label="UserID"
                            >
                                <Input placeholder="Enter User ID" onPressEnter={handleSearch}/>
                            </Form.Item>
                        </Col>
                        <Col span={8} key="Distributor" style={{display:expand?"block":"none"}}>
                            <Form.Item 
                                name="DistributorName"
                                label="Distributor"
                            >
                                <Select 
                                    showSearch
                                    placeholder="Enter Distributor Name"
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={handleSearchDistributor}
                                    onInputKeyDown={searchByEnter}
                                >{distributorSelectOptions}</Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} key="Enabled" style={{display:expand?"block":"none"}}>
                            <Form.Item 
                                name="Enabled"
                                label=""
                            >
                                <Checkbox.Group options={["Enabled", 'Disabled']} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={handleSearch}>
                                Search
                            </Button>
                            <Button
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                            <a
                                style={{ fontSize: 12 }}
                                onClick={() => {
                                    setExpand(!expand);
                                }}
                            >
                                {expand ? <UpOutlined /> : <DownOutlined />} {expand ? 'Collapse' : 'Expand'}
                            </a>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
                paddingBottom: 8,
            }}>
                <Button onClick={handleAddEndUser} type="primary"  icon={<UserAddOutlined /> } size="middle" >Add End User</Button>
                <div style={{flex: "0 0 10px"}}></div>
                <Button onClick={handleAddDistributorUser} type="primary" icon={<UserAddOutlined/>} size="middle" >Add Distributor User</Button>
                <div style={{flex: "0 0 10px"}}></div>
                <Button onClick={handleDeleteAllOfSelected} disabled={selectedKeys.length===0} type="primary"  icon={<DeleteOutlined />} size="middle" style={{marginLeft: 5}}/>
            </div>
            <div style={{
                flex:"1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{position: "absolute", height: '100%', width: "100%", overflow: "hidden"}}>
                    <Table 
                        columns={[{
                            title: "",
                            width: 70,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo*pageSize+idx+1);
                            }
                        },{
                            title: "UserID",
                            dataIndex: "ID",
                            width: 150,
                            fixed: 'left',
                        },{
                            title: "UserName",
                            dataIndex: 'Name',
                            width: 150,
                        },{
                            title: "Role",
                            dataIndex: 'Role',
                            width: 160,
                            render: (v, r, idx) => {
                                if (r.IsDistributorMainAccount === "1") {
                                    return v+"[Main]";
                                }

                                return v;
                            }
                        },{
                            title: "Distributor",
                            dataIndex: "DistributorName",
                            width: 200,
                        },{
                            title: "Address",
                            dataIndex: "Address",
                            width: 200,
                        },{
                            title: "Phone",
                            dataIndex: "Phone",
                            width: 150,
                        },{
                            title: "EMail",
                            dataIndex: "EMail",
                            width: 150,
                        },{
                            title: "Enabled",
                            dataIndex: "Enabled",
                            width: 80,
                            render: (v, r, idx) => {
                                return v === 1 || v === "1" ? (<CheckOutlined style={{color: "blue"}}/>) 
                                    : (<CloseOutlined style={{color: "#ad2102"}} />)
                            }
                        },{
                            title: "GPS",
                            width: 200,
                            render: (v, r, idx) => {
                                if (! r["Longitude"] && ! r["Latitude"]) {
                                    return ""
                                }

                                return `(${r["Longitude"]!==null?(r["Longitude"]/1000000).toFixed(6):"-"}, ${r["Latitude"]!==null?(r["Latitude"]/1000000).toFixed(6):"-"})`
                            }
                        },{
                            title: "Timezone",
                            dataIndex: "Timezone",
                            width: 250,
                            render: (v, r, idx) => {
                                var n = parseInt(r["GmtOffset"]);
                                var tz = "UTC+";
                                if (n < 0) {
                                    tz = "UTC-";
                                    n = Math.abs(n);
                                }
                                tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                                tz +="("+v+")"
                                return tz;
                            }
                        },{
                            title: "Create Time(UTC+0)",
                            dataIndex: 'CreateTime',
                            width: 150,
                        },{
                            title: 'Action',
                            key: 'Action',
                            width: 90,
                            render: renderAction,
                        }]}
                        pagination={{
                            pageSizeOptions: [25,50,100],
                            current: pageNo+1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => {return `Total ${total}`},
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page-1);                                
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                        rowSelection={{
                            selectedRowKeys: selectedKeys,
                            onChange: handleSelect,
                            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                        }}
                    />
                </div>
            </div>
            <EndUserAdder show={showEndUserAdder} onClose={handleEndUserAdderClose}/>
            <DistributorUserAdder show={showDistributorUserAdder} onClose={handleDistributorUserAdderClose}/>
            {showEndUserModifier===true&&<EndUserModifier show={showEndUserModifier} row={rows[modifyRowIndex]} onClose={handleModifyingClose}/>}
            {showDistributorUserModifier===true&&<DistributorUserModifier show={showDistributorUserModifier} row={rows[modifyRowIndex]} onClose={handleModifyingClose}/>}
        </div>
    );
}

export default UserManagement;