
function checkRangeOK(rule, value) {
    return "";
}

function checkRangeByte(rule, value) {
    return new Promise((resolve, reject) => {
        if (isNaN(value)) {
            reject();
        } else {
            const s = value.toString().trim();
            if (s.indexOf(".") > -1 && s.indexOf(".") < s.length -1) {
                reject();

                return;
            }

            const f = parseInt(s)
            if (f >= 0 && f <= 0x7f) {
                resolve();
            } else {
                reject();
            }
        }
    });
}

function checkRangeUint16(rule, value) {
    return new Promise((resolve, reject) => {
        if (isNaN(value)) {
            reject();
        } else {
            const s = value.toString().trim();
            if (s.indexOf(".") > -1 && s.indexOf(".") < s.length -1) {
                reject();

                return;
            }

            const f = parseInt(s)
            if (f >= 0 && f <= 0x7fff) {
                resolve();
            } else {
                reject();
            }
        }
    });
}

function getRuleType (field) {
    if (field.DataType === "byte" || field.DataType === "uint16"|| field.DataType === "uint32") {
        return "integer";
    } else if (field.DataType === "[]byte") {
        return "integer";
    } else {
        return "string";
    }
}

function getRuleTransform (field) {
    if (field.DataType === "byte" || field.DataType === "uint16"|| field.DataType === "uint32") {
        return (value) => {
            if (isNaN(value)) {
                return value;
            }

            return parseFloat(value);
        };
    } else {
        return (value) => {
            return value;
        };
    }
}

function getRuleMin(field) {
    if (field.ValueRange.length === 2 && ! isNaN(field.ValueRange[0]) && ! isNaN(field.ValueRange[1])) {
        return field.ValueRange[0];
    } else if (field.ValueRange.length > 0) {
        return null;
    }

    if (field.DataType === "byte" ||  field.DataType === "uint16" ||  field.DataType === "uint32") {
        return 0;
    } else {
        return null;
    }
}

function getRuleMax(field) {
    if (field.ValueRange.length === 2 && ! isNaN(field.ValueRange[0]) && ! isNaN(field.ValueRange[1])) {
        return field.ValueRange[1];
    } else if (field.ValueRange.length > 0) {
        return null;
    }

    if (field.DataType === "byte") {
        return 0xff;
    } else if (field.DataType === "uint16") {
        return 0xffff;
    } else if (field.DataType === "uint32") {
        return 0xffffffff;
    } else {
        return null;
    }
}

function getRuleEnum(field) {

}

function getinitialValues(setting) {
    const vs = {};
    for (let f of setting.Fields) {
        if (f.ValueRange && f.ValueRange.length === 1) {
            vs[f.Name] = f.ValueRange[0] instanceof Object ? f.ValueRange[0].Value : f.ValueRange[0];
        }
    }

    return vs;
}

const all = {
    checkRangeOK,
    checkRangeByte,
    checkRangeUint16,
    getRuleType,
    getRuleTransform,
    getRuleMin,
    getRuleMax,
    getinitialValues,

}

export default all;